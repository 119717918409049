/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import { ReactComponent as RatingIcon } from "../../../assets/ico_rating.svg";
import { ReactComponent as RatingEmptyIcon } from "../../../assets/ico_rating_empty.svg";
import { ReactComponent as Email } from "../../../assets/ico_email.svg";
import { ReactComponent as Location } from "../../../assets/ico_location.svg";
import { ReactComponent as Phone } from "../../../assets/ico_phone.svg";
import { ReactComponent as EditPicture } from "../../../assets/ico_edit_picture.svg";
import WorkPlaceSchedule from "../../../components/WorkPlaceSchedule";
import { updateProfilePic } from "../../../redux/actions/UserActions";
import { SERVER_URL, USER_ROLE_CLIENT } from "utils/constants";
import { getMyStudiosForArtistOnDay } from "../../../redux/actions/StudioAction";
import CheckBox from "components/UI/CheckBox";
import {
  PORTFOLIO_STATUS,
  studioTypes,
  getstudioTypesById,
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
} from "../../../layouts/ArtistConstants";
import {
  blockArtistAction,
  confirmTechniqueforArtist,
  deleteArtistAction,
  deleteArtistStudioWorkDay,
  unblockArtistAction
} from "redux/actions/AdminArtistAction";
import { ReactComponent as CheckDone } from "../../../assets/ic_check_gray.svg";
import { ReactComponent as Dot } from "../../../assets/ic_dot.svg";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import localStorageService from "services/localStorageService";
import { Alert } from "@mui/material";
import { styled } from '@mui/material/styles';
import TextField from "components/CustomInput";


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});


export default function ArtistSideNavBar(props) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [openStudio, setOpenStudio] = useState(false);
  const [workPlacesData, setWorkPlacesData] = useState(null);
  const [pSelected, setPSelected] = useState(false);
  const [sSelected, setSSelected] = useState(false);
  const [psSelected, setPSSelected] = useState(false);
  const [studioTypeId, setStudioTypeId] = useState(studioTypes["My Studio"]);
  const [hideStudioDropDown, setHideStudioDropDown] = useState(false);
  const [drpDwnDisabled, setDrpDwnDisabled] = useState(false);
  const [techniques, setTechniques] = useState();
  const [deleteError, setDeleteError] = useState(null);
  const [blockedError, setBlockedError] = useState(null);
  const [hideBlockText, setHideBlockText] = useState();
  const [hideUnBlockText, setHideUnBlockText] = useState(" hidden ");

  const studiosObj = useSelector(
    (state) => state.studio.getStudioByArtistAndDay
  );
  const dispatch = useDispatch();
  let history = useHistory();

  const styleShowCount = 5;

  let avg = 0;
  if (props?.avgRating && props?.avgRating.length > 0) {
    avg = Math.round(props?.avgRating[0].avgRating);
  }

  useEffect(() => {
    let workPlaces = null;
    if (
      props?.loginData?.artist_work_places &&
      props?.loginData?.artist_work_places?.length > 0
    ) {
      workPlaces = (
        <ul className="flex flex-row flex-1 w-full">
          {props?.loginData?.artist_work_places?.map((artistWorkPlace) =>
            artistWorkPlace?.artist_work_days?.map((artistWorkDay) =>
              artistWorkDay.day == selectedTab ? (
                <>
                  <img
                    className="h-12 w-12 rounded-md mt-3 object-cover"
                    alt="Studio"
                    src={
                      artistWorkPlace.studio.imageUrl
                        ? SERVER_URL + artistWorkPlace.studio.imageUrl
                        : SERVER_URL +
                          "uploads/default-images/DefaultStudio.svg"
                    }
                  />
                  <li key="work_places" className="w-full">
                    <p className="text-base font-semibold my-2">
                      {artistWorkPlace.work_place.placeName}
                    </p>
                    <p className="text-sm font-normal mb-1">
                      ({artistWorkPlace.work_place.phoneCode}){" "}
                      {artistWorkPlace.work_place.phoneNumber}
                    </p>
                    <p className="text-sm font-normal">
                      {artistWorkPlace.work_place.street +
                        ", " +
                        artistWorkPlace.work_place.zipcode +
                        ", " +
                        artistWorkPlace.work_place.city}
                    </p>
                  </li>
                </>
              ) : (
                ""
              )
            )
          )}
        </ul>
      );
      setPSelected(true);
      setWorkPlacesData(workPlaces);
      setOpenStudio(false);
    } else if (
      props?.loginData?.studio_artists &&
      props?.loginData?.studio_artists?.length > 0
    ) {
      let iniStudioTypeId = "";
      setHideStudioDropDown(false);
      props?.loginData?.studio_artists?.map((studioArtists) => {
        iniStudioTypeId = studioArtists.studio.studioTypeId;
        studioArtists?.artist_studio_work_days?.map((artistWorkDay) => {
          if (
            artistWorkDay.day == selectedTab &&
            studioTypeId == studioArtists.studio.studioTypeId
          ) {
            return setHideStudioDropDown(true);
          }
        });
      });

      if (getstudioTypesById(iniStudioTypeId) === "My Studio") {
        setSSelected(true);
      } else {
        setPSSelected(true);
      }

      workPlaces = (
        <ul className="flex flex-row flex-1 w-full gap-4">
          {props?.loginData?.studio_artists?.map((studioArtists) =>
            studioArtists?.artist_studio_work_days?.map(
              (artistWorkDay, index) =>
                artistWorkDay.day == selectedTab &&
                studioTypeId == studioArtists.studio.studioTypeId ? (
                  <div key={index}>
                    <img
                      className="h-12 w-12 rounded-md mt-3 object-cover"
                      alt="Studio"
                      src={
                        studioArtists.studio.imageUrl
                          ? SERVER_URL + studioArtists.studio.imageUrl
                          : SERVER_URL +
                            "uploads/default-images/DefaultStudio.svg"
                      }
                    />
                    <li key="work_places" className="w-full">
                      <div className="flex flex-row">
                        <p className="text-base font-semibold my-2 flex flex-1">
                          {studioArtists.studio.placeName}
                        </p>
                        {props?.role &&
                          (props.role === USER_ROLE_BOSS ||
                            props.role === USER_ROLE_ADMIN ||
                            props.role === USER_ROLE_MANAGER) && (
                            <span
                              className="text-red w-10 text-2xl p-0 text-center"
                              onClick={() =>
                                deleteArtistStudio(
                                  selectedTab,
                                  artistWorkDay.studioArtistId
                                )
                              }
                            >
                              x
                            </span>
                          )}
                      </div>
                      <p className="text-sm font-normal mb-1">
                        {studioArtists.studio.phoneNumber}
                      </p>
                      <p className="text-sm font-normal">
                        {studioArtists.studio.street +
                          ", " +
                          studioArtists.studio.zipcode +
                          ", " +
                          studioArtists.studio.city}
                      </p>
                    </li>
                  </div>
                ) : (
                  ""
                )
            )
          )}
        </ul>
      );

      setOpenStudio(true);
      setWorkPlacesData(workPlaces);
    }

    if (
      props?.loginData?.studio_artists &&
      props?.loginData?.studio_artists?.length > 0
    ) {
      setDrpDwnDisabled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.loginData, selectedTab]);

  useEffect(() => {
    if (
      props.role === USER_ROLE_BOSS ||
      props.role === USER_ROLE_ADMIN ||
      props.role === USER_ROLE_MANAGER
    ) {
      dispatch(
        getMyStudiosForArtistOnDay(
          props.loginData.id,
          selectedTab,
          studioTypeId
        )
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.loginData, selectedTab, studioTypeId]);

  const handleProfilePicEdit = () => {
    const input = document.getElementById("profile-pic-upload");
    if (input) {
      input.click();
    }
  };

  const handleProfilePicUpload = (values) => {
    const obj = new FormData();
    obj.id = props.loginData.id;
    obj.append("profilePic", values);

    dispatch(updateProfilePic(obj)).then((res) => {
      if (res.type === "UPDATE_PROFILE_PIC_SUCCESS") {
        localStorageService.setItem("auth_user", res.payload);
        window.location.reload();
      }
    });
  };

  let studioOptionItems;
  if (
    props?.role &&
    (props.role === USER_ROLE_BOSS ||
      props.role === USER_ROLE_ADMIN ||
      props.role === USER_ROLE_MANAGER)
  ) {
    let studios = studiosObj;
    if (studios && studios.length > 0) {
      studioOptionItems = studios.map((studio) => (
        <option
          className="text-primary shadow-none border-0 focus:border-0"
          key={studio.id}
          value={studio.id}
        >
          {studio.placeName}
        </option>
      ));
    }
  }

  const goToCalender = (val) => {
    setOpenStudio(false);
    setSelectedTab(1);
    if (val === "My Studio" || val === "Partner Studio") {
      setOpenStudio(true);
      setStudioTypeId(studioTypes[val]);
    } else {
      setOpenStudio(false);
      setWorkPlacesData(null);
    }
  };

  useEffect(() => {
    setTechniques(props?.loginData?.artist_techniques);
    setHideBlockText((props?.loginData?.isBlocked) ? " hidden " : "");
    setHideUnBlockText((props?.loginData?.isBlocked) ? "" : " hidden ");
  }, [props?.loginData]);

  const showAlert = (text) => {
    return Swal.fire({
      icon: "question",
      title: "",
      text: text,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
  };

  const confirmTechnique = async (e, techId) => {
    let isChecked = e.target.checked;
    let ques = isChecked
      ? "Are you sure, you want to confirm the selected technique?"
      : "Are you sure, you want to remove confirmation from selected technique?";

    showAlert(ques).then((value) => {
      if (value.isConfirmed) {
        let obj = {
          statusId: isChecked,
          techniqueId: techId,
          updatedBy: props.actualLoginData.id,
        };
        dispatch(confirmTechniqueforArtist(props.loginData.id, obj)).then(
          (item) => {
            if (!item.payload.error) {
              var tempTech = [];
              tempTech.current = Object.assign(tempTech, techniques);

              tempTech.current.map((item) => {
                if (item.techniqueId == techId) {
                  item.statusId = isChecked ? 0 : 1;
                }
              });
              setTechniques(tempTech);
              props.refreshArtist();
            }
          }
        );
      }
    });
  };

  const deleteArtistStudio = async (day, artistStudioId) => {
    showAlert("Are you sure you want to remove this artist studio").then(
      (value) => {
        if (value.isConfirmed) {
          let obj = {
            day: day,
            artistStudioId: artistStudioId,
          };
          setDeleteError(null);
          dispatch(deleteArtistStudioWorkDay(obj)).then((item) => {
            if (
              item &&
              item.payload &&
              item?.payload?.code == 400 &&
              item?.type === "DELETE_ARTIST_STUDIO_WORK_DAYS_ERROR"
            ) {
              setDeleteError(
                <Alert className="m-4 " severity="error">
                  {item?.payload?.message?.error?.message}
                </Alert>
              );
            }
            props.refreshArtist();
          });
        }
      }
    );
  };

  const blockArtist = async () => {
    setHideBlockText();
    showAlert("Are you sure you want to block this artist").then((value) => {
      setBlockedError(null);      
      if (value.isConfirmed) {
        dispatch(blockArtistAction(props.loginData.id)).then((item) => {
          if (
            item &&
            item.payload &&
            item?.payload?.code == 400 &&
            item?.type === "BLOCK_ARTIST_ERROR"
          ) {
            setBlockedError(
              <Alert className="m-4 " severity="error">
                {item?.payload?.message?.error?.message}
              </Alert>
            );
          } else {
            setHideBlockText(" hidden ");
          }
          props.refreshArtist();
        });
      }
    });
  };

  const deleteArtist = async () => {
    showAlert("Are you sure you want to delete this artist").then((value) => {
      setBlockedError(null);
      if (value.isConfirmed) {
        dispatch(deleteArtistAction(props.loginData.id)).then((item) => {
          if (
            item &&
            item.payload &&
            item?.payload?.code == 400 &&
            item?.type === "DELETE_ARTIST_ERROR"
          ) {
            setBlockedError(
              <Alert className="m-4 " severity="error">
                {item?.payload?.message?.error?.message}
              </Alert>
            );
          } else {
            history.goBack();
          }          
        });
      }
    });
  };

  const unBlockArtist = async () => {
    setHideBlockText(" hidden ");
    showAlert("Are you sure you want to unblock this artist").then((value) => {
      setBlockedError(null);      
      if (value.isConfirmed) {
        dispatch(unblockArtistAction(props.loginData.id)).then((item) => {
          if (
            item &&
            item.payload &&
            item?.payload?.code == 400 &&
            item?.type === "UNBLOCK_ARTIST_ERROR"
          ) {
            setBlockedError(
              <Alert className="m-4 " severity="error">
                {item?.payload?.message?.error?.message}
              </Alert>
            );
          } else {
            setHideBlockText(" ");
          }
          props.refreshArtist();
        });
      }
    });
  };

  return (
    <>
      {/* Gallery */}
      <section
        className="w-full h-full pb-12"
        aria-labelledby="gallery-heading"
      >
        <div className="w-full flex items-center justify-center p-6 space-x-6">
          <div className="relative">
            <img
              className="h-28 w-28 bg-gray-300 rounded-full flex-shrink-0 border-primary border-2 object-cover object-center"
              src={
                props?.loginData?.photoUrl
                  ? SERVER_URL + props.loginData.photoUrl
                  : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
              }
              alt=""
            />
            {props.role !== USER_ROLE_CLIENT &&
              props.role !== USER_ROLE_BOSS && (
                <button
                  className="absolute top-20 right-0"
                  onClick={handleProfilePicEdit}
                >
                  <EditPicture />
                </button>
              )}
            <input
              type="file"
              id="profile-pic-upload"
              style={{ display: "none" }}
              onChange={(event) => {
                handleProfilePicUpload(event.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="">
          <p className="text-black text-base font-sans font-semibold flex justify-center">
            {props?.loginData?.firstName} {props?.loginData?.lastName}{" "}
            <span className="text-red">
              {props?.loginData?.isBlocked ? " (Blocked)" : ""}
            </span>
          </p>
          <div
            className="flex justify-center mt-2"
            onClick={() => props.handleShowRating(true)}
          >
            <StyledRating
              size="large"
              name="simple-controlled"
              getLabelText={(value) =>
                `${value} Heart${value !== 1 ? "s" : ""}`
              }
              value={avg}
              icon={<RatingIcon />}
              emptyIcon={<RatingEmptyIcon />}
            />
          </div>
        </div>

        <div className="px-4 pt-4">
          <div className="py-5">
            {/* <p className="text-primary text-sm font-semibold">Bio:</p>
            <p className="text-black text-base font-sans font-normal">
              {props?.loginData?.bio}
            </p> */}
            <TextField
              type="text"
              name="bio"
              id="bio"
              value={props?.loginData?.bio ?? ""}
              label="Bio"
              multiline={true}
              minRows={3}
              // contentEditable= {false}
              disabled = {true}
            />
          </div>

          <div className="py-5">
            <div className="text-primary text-sm font-semibold mb-1">
              Styles:
            </div>
            <div className="flex flex-row flex-wrap">
              {props?.loginData?.artist_portfolios?.map((item, index) =>
                index < styleShowCount ? (
                  <div key={index}>
                    {item.portfolioStatusId === PORTFOLIO_STATUS.COMPLETE && (
                      <span
                        key={index}
                        className="bg-primary rounded-md text-white text-base font-normal font-sans justify-center items-center py-1 px-2 mr-1 flex mb-2 whitespace-nowrap"
                      >
                        {item.style.styleName}
                      </span>
                    )}
                  </div>
                ) : index == styleShowCount && !showMore ? (
                  <span
                    key={index}
                    onClick={() => setShowMore(true)}
                    className="bg-primary rounded-md text-white text-base font-normal font-sans justify-center items-center py-1 px-2 mr-1 flex mb-2 whitespace-nowrap"
                  >
                    +{props.loginData.artist_portfolios.length - styleShowCount}
                  </span>
                ) : showMore ? (
                  <div>
                    {item.portfolioStatusId === PORTFOLIO_STATUS.COMPLETE && (
                      <span
                        key={index}
                        className="bg-primary rounded-md text-white text-base font-normal font-sans justify-center items-center py-1 px-2 mr-1 flex mb-2 whitespace-nowrap"
                      >
                        {item.style.styleName}
                      </span>
                    )}
                  </div>
                ) : null
              )}
            </div>
          </div>

          <div className="py-5">
            <p className="text-primary text-sm font-semibold mb-1">
              Techniques:
            </p>
            <div className="flex flex-row flex-wrap gap-2">
              {techniques?.map((item, index) => (
                <div className="" key={index}>
                  {props.role === USER_ROLE_BOSS ||
                  props.role === USER_ROLE_ADMIN ||
                  props.role === USER_ROLE_MANAGER ? (
                    <CheckBox
                      id={index}
                      name={index}
                      type="checkbox"
                      label={item.technique.techniqueName}
                      onChange={(e) => confirmTechnique(e, item.techniqueId)}
                      width={"5"}
                      textborder={
                        item.statusId == 1 ? "selected" : "unselected"
                      }
                      checked={item.statusId == 1}
                    />
                  ) : props.role === USER_ROLE_CLIENT ? (
                    item.statusId == 1 && (
                      <div className="border border-primary rounded-md p-1 flex flex-row items-center text-primary">
                        {item.technique.techniqueName}
                      </div>
                    )
                  ) : item.statusId == 1 ? (
                    <div className="border border-primary rounded-md p-1 flex flex-row items-center text-primary">
                      <CheckDone className="mr-2" fill="#C7A33F" />
                      {item.technique.techniqueName}
                    </div>
                  ) : (
                    <div className="border border-tertiary rounded-md p-1 flex flex-row items-center text-tertiary">
                      {item.technique.techniqueName}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {props.role !== USER_ROLE_CLIENT && (
            <div className="pt-5">
              <p className="text-primary text-sm font-semibold ">Info:</p>
              <p className="text-black text-base font-sans font-normal flex flex-row mt-3">
                <Email className="mr-1" />
                {props?.loginData?.email}
              </p>
              <p className="text-black text-base font-sans font-normal flex flex-row mt-3">
                <Location className="mr-1" />
                {props?.loginData?.city}{" "}
                {props?.loginData?.country && props?.loginData?.city ? "," : ""}{" "}
                {props?.loginData?.country}
              </p>
              <p className="text-black text-base font-sans font-normal flex flex-row mt-3">
                <Phone className="mr-1" />({props?.loginData?.phoneCode}){" "}
                {props?.loginData?.phone}
              </p>
            </div>
          )}

          {props.role !== USER_ROLE_CLIENT && (
            <div className="mt-10">
              <div className="text-primary text-sm font-semibold ">
                Current Place:
                {props?.role &&
                  (props.role === USER_ROLE_BOSS ||
                    props.role === USER_ROLE_ADMIN ||
                    props.role === USER_ROLE_MANAGER) && (
                    <div className="border border-tertiary mt-3 mb-5 rounded-md overflow-hidden px-2 flex flex-row items-center">
                      <Dot className="mr-2" />
                      <select
                        onChange={(e) => goToCalender(e.target.value)}
                        id="cPlace"
                        name="cPlace"
                        disabled={drpDwnDisabled}
                        className="block w-full flex py-2 text-base  focus:outline-none focus:ring-primary text-black"
                        // appearance-none
                      >
                        <option
                          label="Public"
                          value="Public"
                          className="text-base font-semibold"
                          selected={pSelected}
                        >
                          {/*  */}
                          Public
                        </option>
                        <option
                          label="My Studio"
                          value="My Studio"
                          className="text-base font-semibold"
                          selected={sSelected}
                        >
                          {/*  */}
                          My Studio
                        </option>
                        <option
                          label="Partner Studio"
                          value="Partner Studio"
                          className="text-base font-semibold"
                          selected={psSelected}
                        >
                          {/*  */}
                          Partner Studio
                        </option>
                      </select>
                    </div>
                  )}
              </div>
              <WorkPlaceSchedule
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                selectedTabData={workPlacesData}
                openStudio={openStudio}
                studioOptionItems={studioOptionItems}
                onSaveStudio={props.onSaveStudio}
                role={props?.role}
                sShow={hideStudioDropDown}
              />
              {deleteError}

              {props?.role &&
                (props.role === USER_ROLE_BOSS ||
                  props.role === USER_ROLE_ADMIN ||
                  props.role === USER_ROLE_MANAGER) && (
                  <div>
                    <div className="flex w-full border-b-2 border-separator mt-12 mb-5"></div>
                    <div className="flex flex-row mt-4 gap-4">
                      <div
                        className={`text-tertiary ${hideBlockText}`}
                        onClick={() => blockArtist()}
                      >
                        Block
                      </div>
                      <div
                        className={`text-tertiary ${hideUnBlockText}`}
                        onClick={() => unBlockArtist()}
                      >
                        UnBlock
                      </div>
                      <div className="text-tertiary">-</div>
                      <div
                        className="text-delete"
                        onClick={() => deleteArtist()}
                      >
                        Delete
                      </div>
                    </div>
                    {blockedError}
                  </div>
                )}
            </div>
          )}
        </div>
      </section>
    </>
  );
}
