/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "redux/actions/CountryAction";
import {
  getSupportTicketSubjects,
  createSupportTicket,
} from "redux/actions/SupportAction";
import { createSubscriber } from "redux/actions/SubscriberAction";
import { getUserTypes } from "redux/actions/UserTypeAction";
import SupportView from "./SupportView";
import { USER_TYPES } from "utils/constants";

const SupportContainer = () => {
  const [firstTime, setFirstTime] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const userType = useSelector((state) => state.userType.data);
  const countries = useSelector((state) => state.country.data);
  const subjects = useSelector((state) => state.support.dataSubjects);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!firstTime) {
      dispatch(getUserTypes());
      dispatch(getCountries());
      dispatch(getSupportTicketSubjects());
      setFirstTime(true);
    }
  }, []);

  let userTypeIds = [
    USER_TYPES.CLIENT.id,
    USER_TYPES.ARTIST.id,
    USER_TYPES.GENERAL.id,
  ];
  let userTypes = userType
    ? userType.filter(function (item) {
        return userTypeIds.indexOf(item.id) > -1;
      })
    : [];

  const onSubmit = (obj) => {
    dispatch(createSupportTicket(obj)).then((res) => {
      setShowAlert(true);
      setAlertMessage("Support Ticket Created");
    });
  };

  const subscriptionResponse = useSelector((state) => state.subscriber);

  const subscribe = (obj) => {
    dispatch(createSubscriber(obj));
  };

  return (
    <SupportView
      userTypes={userTypes}
      countries={countries}
      subjects={subjects}
      onSubmit={onSubmit}
      subscribe={subscribe}
      setShowAlert={setShowAlert}
      showAlert={showAlert}
      alertMessage={alertMessage}
      subscriptionResponse={subscriptionResponse}
    />
  );
};

export default SupportContainer;
