/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SaveStudioView from "./SaveStudioView";
import { getStudio, createStudio, updateStudio } from "../../redux/actions/StudioAction";
import { getMarketPlaceDecisions } from "../../redux/actions/MarketPlaceDecisionAction";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "redux/actions/CountryAction";

export default function SaveStudioContainer(props) {
    const selectedTab = props.match.params.selectedTab;
    const selectedStudioId = props.match.params.id;

    const studio = useSelector((state) => state.studio);
    const marketPlaceDecision = useSelector((state) => state.marketPlaceDecision.data);
    const dispatch = useDispatch();

    const countries = useSelector((state) => state.country.data);

    useEffect(() => {
        if (selectedStudioId) {
            dispatch(getStudio(selectedStudioId));
        }
        if (selectedTab === '4') { //Partner Studio Tab.
            dispatch(getMarketPlaceDecisions());
        }
        dispatch(getCountries());
    }, [selectedStudioId]);

    const onSubmit = (obj) => {
        if (selectedStudioId) {
            dispatch(updateStudio(selectedStudioId, obj)).then((res)=> 
                {
                    if (res && res?.type === "STUDIO_ERROR") {
                        window.location.reload();    
                    } else {
                        props.history.replace('/admin/manager');
                    }       
                }
            );           
        } else {
            dispatch(createStudio(obj)).then(()=> props.history.replace('/admin/manager'));
        }
    }

    return (
      <div>
        <SaveStudioView
          onSubmit={onSubmit}
          studio={studio.getStudio}
          marketPlaceDecision={marketPlaceDecision}
          error={studio.error}
          selectedTab={selectedTab}
          selectedStudioId={selectedStudioId}
          title="Save Studio View"
          loginData={props.loginData}
          countries={countries}
        />
      </div>
    );
}