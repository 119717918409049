/* eslint-disable eqeqeq */
import Footer from "components/Footers/Footer";
import Header from "components/Navbars/AuthNavbar";
import React, { useState } from "react";
import Faq from "views/hichem/faq/components/Faq";

const FaqView = ({
  faqState,
  selectedOption,
  setSelectedOption,
  options,
  subscribe,
  subscriptionResponse,
}) => {
  const [lang, setSelectedLang] = useState("1");

  return (
    <>
      <div className="bg-white">
        {/* Header section */}
        <header>
          <Header className="" />
        </header>
        <div className="h-full flex">
          {/* Content area */}
          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1">
              <div className="w-full md:mx-0.1 py-2 md:py-5 px-6 lg-gradient-to-r from-bgg via-white to-bgg">
                <div className="mx-1 lg:mx-32 xl:mx-52">
                  <div className="text-2xl font-normal font-sans text-primary mb-6">
                    FAQ
                  </div>
                  <div className="flex flex-row gap-10">
                    <div
                      className={`${
                        lang === "1" ? "text-primary" : "text-gray"
                      } font-black text-base font-sans`}
                      onClick={() => setSelectedLang("1")}
                    >
                      English
                    </div>
                    <div
                      className={`${
                        lang === "2" ? "text-primary" : "text-gray"
                      } font-black text-base font-sans`}
                      onClick={() => setSelectedLang("2")}
                    >
                      German
                    </div>
                  </div>
                  <select
                    className="my-10 border-rounder border border-link rounded-md pr-20 py-2 font-sans text-base pl-2"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    {options.map((item) => (
                      <option key={item.id} value={item.id} className="">
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <>
                    {faqState?.getAll?.map((item) =>
                      item.categoryId == selectedOption ? (
                        <Faq
                          key={item.id}
                          data={item}
                          lang={lang}
                          fromHome={true}
                        />
                      ) : null
                    )}
                  </>
                </div>
              </div>
            </div>
            <Footer
              subscribe={subscribe}
              subscriptionResponse={subscriptionResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqView;
