/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Formik } from "formik";
import {
  getProjectStatusByName,
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  USER_ROLE_CLIENT,
} from "../../CONSTANTS";
import { SERVER_URL, getDaysByIntShort } from "utils/constants";
import TabNavBar from "components/Navbars/TabNavBar";
import { useHistory } from "react-router-dom";

const statusNameArray = [
  "Artist Confrimed",
  "Appointment Date Confirmation",
  "Appointment Date Confirmed",
  "Refunded"
];

const Accordion = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [calendarData, setCalendarData] = useState(null);
  let history = useHistory();

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    let commonDays = [];
    if (
      props?.user?.user?.studio_artists &&
      props?.user?.user?.studio_artists.length > 0
    ) {
      props?.user?.user?.studio_artists?.map((studioArtists) =>
        studioArtists?.artist_studio_work_days?.map((artistWorkDay) => {
          let a = props.clientProposedDates.filter((item) => item.day === artistWorkDay.day);    
          if (a && a.length > 0) {
            commonDays.push({
                  id: artistWorkDay.day,
                  name: getDaysByIntShort(artistWorkDay.day),
                  current: selectedTab == artistWorkDay.day,
                });
          } 
        })
      );
    }
    setTabs(commonDays);
  }, [props?.user?.user, selectedTab, props.clientProposedDates])

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  useEffect(() => {
    if (statusNameArray.includes(props?.projectStatus)) {
      setIsExpanded(true);
      setSelectedTab(props?.user?.day);
      let updatedTabs = tabs.map((tab) =>
        tab.id === props?.user?.day
          ? { ...tab, current: true }
          : { ...tab, current: false }
      );
      setTabs(updatedTabs);
    }
  }, []);

  let iniStudioId = null;
  useEffect(() => {
    let studioData = null;
    if (
      props?.user?.user?.studio_artists &&
      props?.user?.user?.studio_artists.length > 0
    ) {
      studioData = (
        <ul className="flex flex-col flex-1 w-full gap-4">
          <ul className="flex flex-row flex-1 w-full gap-4">
            {props?.user?.user?.studio_artists?.map((studioArtists) =>
              studioArtists?.artist_studio_work_days?.map(
                (artistWorkDay, index) =>
                  artistWorkDay.day == selectedTab ? (
                    <div className="flex flex-col w-full" key={index}>
                      <div className="flex flex-row">
                        <img
                          className="h-12 w-12 rounded-md mt-3 object-cover mr-5"
                          src={
                            studioArtists.studio.imageUrl
                              ? SERVER_URL + studioArtists.studio.imageUrl
                              : SERVER_URL +
                                "uploads/default-images/DefaultStudio.svg"
                          }
                          alt="studio"
                        />
                        <li key="work_places" className="w-full">
                          <p className="text-base font-semibold my-2 mb-3">
                            {studioArtists.studio.placeName}
                          </p>
                          <p className="text-sm font-normal flex mb-3 text-tertiary">
                            {(studioArtists?.studio?.phoneCode) ? "(" + (studioArtists?.studio?.phoneCode) + ")" : "" }{" "}
                            {studioArtists.studio.phoneNumber}
                          </p>
                          <p className="text-sm font-normal text-tertiary mb-3">
                            {studioArtists.studio.street +
                              ", " +
                              studioArtists.studio.zipcode +
                              ", " +
                              studioArtists.studio.city}
                          </p>
                        </li>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
              )
            )}
          </ul>
          {!statusNameArray.includes(props?.projectStatus) && (
            <Formik
              initialValues={{ artistId: props.user.userId }}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mx-4 pb-6">
                    <input
                      type="hidden"
                      name="artistId"
                      id="artistId"
                      value={values.artistId}
                    />
                    <button
                      type="submit"
                      className="rounded-3xl bg-primary text-white text-base font-semibold py-2 w-full mt-2"
                    >
                      Confirm Artist Offer
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </ul>
      );
      setCalendarData(studioData);
      props?.user?.user?.studio_artists?.map((studioArtists) => {
        studioArtists?.artist_studio_work_days?.map((artistWorkDay) => {
          if (artistWorkDay.day === selectedTab) {
            iniStudioId = studioArtists.studioId;
          }
        });
      });
      //setStudioId(studioId => (iniStudioId));
    }
  }, [props?.user?.user, selectedTab]);

  let project_Id = props?.projectId;
  const handleFormSubmit = (values) => {
    let projectStatusId = getProjectStatusByName.Artist_Confrimed;
    let obj = {
      status: {
        statusId: projectStatusId,
        loggedInUserId: props.loggedInUserId,
      },
      confirmedArtists: values.artistId,
      confirmedStudio: iniStudioId,
      confirmedDay: selectedTab,
    };
    props.onTempSubmit(obj);
    if (!props?.isPaymentDone && props?.role === USER_ROLE_CLIENT) {
      if (props?.role === USER_ROLE_CLIENT) {
        history.push("/client/payment/" + project_Id);
      }
    }
  };

  const showArtistProfile = (user) => {
    if (props?.role === USER_ROLE_CLIENT) {
      history.push("/client/artist-profile/" + user.studio_artists[0].userId);
    }
    if (props?.role === USER_ROLE_PROJECT_ADMIN) {
      history.push("/admin/artist/" + user.studio_artists[0].userId);
    }
  };

  return (
    <div
      className={`text-sm m-2 ${
        isExpanded ? "rounded-md shadow-sm bg-white " : "bg-white"
      }`}
    >
      <div className="flex flex-row justify-between items-center h-full">
        <div
          className="flex flex-row gap-4 items-center"
          onClick={() => showArtistProfile(props.user.user)}
        >
          <div className="w-12 h-12 rounded-full overflow-hidden">
            <img
              className="h-12 w-12 rounded-full mt-0 mr-4"
              src={
                props.user.user.photoUrl
                  ? SERVER_URL + props.user.user.photoUrl
                  : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
              }
              alt="Artist Profile"
            />
          </div>
          <div className="text-base font-semibold">
            {props.user.user.firstName + " " + props.user.user.lastName}
          </div>
        </div>
        <div
          className="flex flex-1 justify-end h-full"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded && props?.role === USER_ROLE_CLIENT ? (
            <div className="text-primary">
              <KeyboardArrowUpIcon />
            </div>
          ) : props?.role === USER_ROLE_CLIENT ? (
            <KeyboardArrowDownIcon />
          ) : (
            ""
          )}
        </div>
      </div>
      {isExpanded &&
        (props?.role === USER_ROLE_CLIENT ||
          ((props?.role === USER_ROLE_PROJECT_ADMIN ||
            props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST ||
            props?.role === "Admin") &&
            statusNameArray.includes(props?.projectStatus))) && (
          <div className="mt-2 w-full">
            <div className="flex flex-row overflow-hidden">
              <TabNavBar
                tabs={tabs}
                handleChange={setTab}
                tabSpace={1}
                tabFont={"text-sm"}
                py={1}
                px={0}
                mx={0}
                borderHeight={2}
                hideCount={true}
                clientProposedDates ={props.clientProposedDates}
                showInMobileMode = {true}
              />
            </div>
            {props?.role !== "Admin" && <div>{calendarData}</div>}
          </div>
        )}
    </div>
  );
};

export default Accordion;
