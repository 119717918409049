import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { signIn } from "../../../../redux/actions/LoginActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createProject } from "redux/actions/ProjectAction";
import { getUserType, SCERETPASS } from "utils/constants";
import { sections, projectRequestKeys } from "views/project/CONSTANTS";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const loginValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const LoginPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    open: false,
  });

  const handleFormSubmit = (values) => {
    props.setShouldBlockNavigation(false);
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));

    var encrypted = CryptoJS.AES.encrypt(values.password, SCERETPASS).toString();
 

    let obj = {
      email: values.email,
      password: encrypted,
    };

    let errorArr = [];
    let projectObj = new FormData();
    if (props.storedProjectState) {
      projectObj.append("statusId", 1);

      Object.keys(props.storedProjectState).map((key) => {
        for (var pair of props.storedProjectState[key].entries()) {
          if (
            pair[0] !== "modelPictureWithPoints" &&
            pair[0] !== "modelPictureOriginal"  &&
            pair[0] !== "dimensions"
          ) {
            projectObj.append(pair[0], pair[1]);
            errorArr.push(pair[0]);
          }
        }
        return 0;
      });
    }

    const showAlert = (text) => {
      return Swal.fire({
        icon: "warning",
        title: "",
        text: "All fields are mandatory.",
        confirmButtonText: "OK",
        showCancelButton: false,
        cancelButtonText: "No",
      });
    };

    if (errorArr.length < projectRequestKeys.length) {
      showAlert();
      props.setSelectedSection(sections.UploadPictures);
      return;
    }

    dispatch(signIn(obj)).then((res) => {
      if (
        props.storedProjectState && res?.payload?.user && 
        getUserType(res.payload.user.userTypeId) === "Client"
      ) {
        projectObj.append("clientId", res.payload.user.id);
        dispatch(createProject(projectObj));
      }
    });
  };

  const resetPwdClick = () => {
    props.setSelectedSection(sections.ForgotPassword);
  };

  const autoHideMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 10000);
  };

  const autoHideSuccessMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
      history.replace({
        pathname: "/",
      });
    }, 1000);
  };

  let error = null;
  if (
    state.open &&
    login.success === false &&
    login.loginData === null &&
    login.error !== null
  ) {
    autoHideMessage();
    error = (
      <Alert className="m-4" severity="error">
        {login.error}
      </Alert>
    );
  }
  if (
    state.open &&
    login.success === true &&
    login.loginData !== null &&
    login.error === null && login.loginData?.id
  ) {
    autoHideSuccessMessage();
    error = (
      <p className="mt-6 text-green-900 text-center">{"Login Successful!"}</p>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-start m-0">
          <div className="max-w-lg justify-start">
            <div className="mt-0">
              <div className="mt-0">
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={loginValidationSchema}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="overflow-hidden">
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          label={t("login.email")}
                          autoComplete="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <p className="text-red font-sans">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>

                      <div className="space-y-2">
                        <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          label={t("login.password")}
                          autoComplete="current-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          showeye={+true}
                          onShowPassword={() => setShowPassword(!showPassword)}
                          showpassword={+showPassword}
                        />
                      </div>
                      <p className="text-red font-sans">
                        {errors.password && touched.password && errors.password}
                      </p>

                      <div className="flex items-center mt-6 space-x-2">
                        <Button
                          label="Back"
                          textsize="base"
                          onClick={() => props.onBack(sections.Submit)}
                          background="white"
                          bordercolor="primary"
                          color="primary"
                        />
                        <Button
                          type="submit"
                          width="full"
                          textsize="base"
                          label={t("login.login")}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
                <div className="grid grid-cols-2 md:gap-y-12 sm:grid-cols-2 sm:gap-x-8 mt-4">
                  {/* <CheckBox
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          label={t("login.rememberMe")}
                        /> */}

                  <div className="my-0 col-start-2 col-span-1">
                    <Button
                      onClick={() => resetPwdClick()}
                      label="Forgot Password?"
                      textsize="base"
                      border="none"
                      background="white"
                      color="primary"
                      shadow="shadow-none"
                      hoverbg="white"
                      hovertext="primary"
                      font="normal"
                      px="0"
                    />
                  </div>
                </div>
              </div>
              {error}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginPage;
