/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from "redux/actions/LoginActions";

export function SignoutContainer(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    history.replace("/", "urlhistory");
    dispatch(signOut());
  }, [dispatch]);

  return <div></div>;
}
