/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import Button from "components/UI/Button";
import Dropzone from "react-dropzone";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import CustomThumbnail from "components/CustomThumbnail";

// import { ReactComponent as TextIcon } from "../../../../assets/ic_text.svg";
import { ReactComponent as AttachFileIcon } from "../../../../assets/ic_attach.svg";
// import { ReactComponent as InsertLinkIcon } from "../../../../assets/ic_link.svg";
// import { ReactComponent as ImageIcon } from "../../../../assets/ic_image_a.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/ic_delete.svg";
import { ReactComponent as PencilIcon } from "../../../../assets/ico_pencil.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/ic_close_primary.svg";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  DAYS,
  getDaysByInt,
  ORDINAL_NUMBERS,
} from "../../../../utils/constants";
import moment from "moment";

const CreateNewsletter = ({
  newsletters,
  onSubmit,
  deleteNewsletter,
  editNewsletter,
  editedData,
  resetForm,
  setResetForm,
}) => {
  const [newEditedData, setNewEditedData] = useState(editedData);


  let formValues = {
    subject: newEditedData ? newEditedData.subject : "",
    content: newEditedData ? newEditedData.content : "",
    sendToClients: newEditedData ? newEditedData.sendToClients : false,
    sendToArtists: newEditedData ? newEditedData.sendToArtists : false,
    isRepeatNever: newEditedData ? newEditedData.isRepeatNever : false,
    isRepeatWeekly: newEditedData ? newEditedData.isRepeatWeekly : false,
    dayOfTheWeek: newEditedData ? newEditedData.dayOfTheWeek : DAYS.SUNDAY.id,
    isRepeatMonthly: newEditedData ? newEditedData.isRepeatMonthly : false,
    dayOfTheMonth: newEditedData
      ? newEditedData.dayOfTheMonth
      : ORDINAL_NUMBERS.FIRST.id,
    recipients: newEditedData && newEditedData.newsletter_recipients && newEditedData.newsletter_recipients.length > 0 ?  newEditedData.newsletter_recipients.map((item) => item.email) : [],
    //images: newEditedData ? newEditedData.newsletter_images : [],
    attachments: newEditedData ? newEditedData.newsletter_attachments : [],
  };

  let clearFormValues = {
    subject:  "",
    content: "",
    sendToClients:  false,
    sendToArtists:  false,
    isRepeatNever: false,
    isRepeatWeekly:  false,
    dayOfTheWeek:  DAYS.SUNDAY.id,
    isRepeatMonthly:  false,
    dayOfTheMonth:  ORDINAL_NUMBERS.FIRST.id,
    recipients:  [],
    //images: [],
    attachments: []
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
  ];

  useEffect(() => {
    setNewEditedData(editedData);
  }, [editedData]);

  const handleFormSubmit = (values) => {
    const obj = new FormData();
    if (newEditedData) {
      obj.append("isCheckClient", values.sendToClients);
      obj.append("isArtistClient", values.sendToArtists);
      obj.append("recipients", JSON.stringify(values.recipients));
      obj.append("subject", values.subject);
      obj.append("repeat", values.repeat);
      obj.append("weeklyValue", values.dayOfTheWeek);
      obj.append("monthlyValue", values.dayOfTheMonth);
      obj.append("content", values.content);
      //values?.images?.map((file) => obj.append("newsletterImages", file));
      values?.attachments?.map((file) => obj.append("newsletterAttachments", file));      
    } else {
      obj.append("subject", values.subject);
      obj.append("content", values.content);
      obj.append("sendToClients", values.sendToClients);
      obj.append("sendToArtists", values.sendToArtists);
      obj.append("isRepeatNever", values.isRepeatNever);
      obj.append("isRepeatWeekly", values.isRepeatWeekly);
      obj.append("dayOfTheWeek", values.dayOfTheWeek);
      obj.append("isRepeatMonthly", values.isRepeatMonthly);
      obj.append("dayOfTheMonth", values.dayOfTheMonth);
      obj.append("recipients", JSON.stringify(values.recipients));
      //values?.images?.map((file) => obj.append("newsletterImages", file));
      values?.attachments?.map((file) => obj.append("newsletterAttachments", file));
    }
    onSubmit(obj, newEditedData ? newEditedData.id : undefined);
  };

  function nth(n) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validateOnMountc: true,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  useEffect(() => {
    if (resetForm === true) {
      formik.resetForm();
      setResetForm(false);
    }
  }, [resetForm]);

  const clearForm = () => {
      formik.setValues(clearFormValues);
  };

  return (
    <div className="flex flex-row gap-44 text-lg">
      <div className="mt-6 w-7/12">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div className="text-gray border-b border-gray pb-4 text-base">
              Create New Promotion / Newsletter
            </div>
            <div className="flex flex-col py-4 gap-4 border-b border-gray">
              <div className="text-gray text-base">Recipients</div>
              <div className="flex flex-row gap-4 mb-2">
                <div
                  className={`${
                    formik.values.sendToClients
                      ? "bg-primary"
                      : "border border-primary"
                  } px-5 py-1 rounded-lg cursor-pointer`}
                >
                  <input
                    className="form-checkbox text-white focus:ring-0 focus:ring-white h-5 w-5 border border-gray rounded-full bg-white mt-1 align-top float-left mr-2 cursor-pointer"
                    type="checkbox"
                    name="sendToClients"
                    id="sendToClients"
                    checked={formik.values.sendToClients}
                    onChange={formik.handleChange}
                  />
                  <label
                    className={`inline-block ${
                      formik.values.sendToClients
                        ? "text-white"
                        : "text-primary"
                    } cursor-pointer`}
                    htmlFor="sendToClients"
                  >
                    Clients
                  </label>
                </div>

                <div
                  className={`${
                    formik.values.sendToArtists
                      ? "bg-primary"
                      : "border border-primary"
                  } px-5 py-1 rounded-lg cursor-pointer`}
                >
                  <input
                    className="form-checkbox text-white focus:ring-0 focus:ring-white h-5 w-5 border border-gray rounded-full bg-white mt-1 align-top float-left mr-2 cursor-pointer"
                    type="checkbox"
                    name="sendToArtists"
                    id="sendToArtists"
                    checked={formik.values.sendToArtists}
                    onChange={formik.handleChange}
                  />
                  <label
                    className={`inline-block ${
                      formik.values.sendToArtists
                        ? "text-white"
                        : "text-primary"
                    } cursor-pointer`}
                    htmlFor="sendToArtists"
                  >
                    Artists
                  </label>
                </div>

                <div className="h-8 my-auto border-l border-gray"></div>
                <div className="my-auto focus:outline-none">
                  <ReactMultiEmail
                    emails={formik.values.recipients}
                    onChange={v => formik.setFieldValue('recipients', v)} 
                    validateEmail={(email) => {
                      return isEmail(email);
                    }}
                    className={
                      "outline-none border-transparent focus:outline-none focus:border-transparent text-black"
                    }
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div
                          data-tag
                          key={index}
                          className="flex flex-row focus:outline-none focus:ring-transparent"
                        >
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                            className="my-auto ml-2 focus:outline-none focus:ring-transparent"
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="py-4 border-b border-gray">
              <div>
                <input
                  className="w-full outline-none focus:outline-none text-black text-base"
                  type="text"
                  name="subject"
                  id="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Subject"
                />
              </div>
            </div>

            <div className="py-6 border-b border-gray h-80">
              {/* <div className="w-full break-all" style={{
                    fontSize: `${fontSize}px`
                  }}> */}
                {/* <textarea
                  className="w-full focus:outline-none break-all text-black"
                  id="content"
                  value={formik.values.content}
                  rows="6"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}                  
                /> */}
                <ReactQuill theme="snow" 
                className="w-full focus:outline-none break-all text-black"
                id="content"
                //value={value} 
                value={formik.values.content}
                //onChange={setValue} 
                onChange={v => formik.setFieldValue('content', v)} 
                modules={modules}
                    formats={formats}/>
              {/* </div> */}
              {/* <div 
                className={`${
                  formik.values?.images?.length ? "h-20" : "h-0"
                } w-full`}
              >
                <ul className="flex flex-row">
                  {" "}
                  {formik.values?.images?.map((file, i) => (
                    <div className={`flex m-2 border-primary`} key={i}>
                      <CustomThumbnail file={file} height={90} width={90} />
                    </div>
                  ))}
                </ul>
              </div> */}
            </div>

            <div className="flex flex-row py-4 border-b border-gray gap-14">
              <div className="text-gray text-base">Repeat</div>
              <div className="flex flex-row gap-10">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name="section"
                    className={`"form-radio text-primary rounded-full h-5 w-5 border border-black bg-white checked:primary focus:ring-primary mt-1 align-top float-left mr-2 cursor-pointer"`}
                    id="section"
                    checked={formik.values.isRepeatNever}
                    onChange={() => {
                      formik.setFieldValue("isRepeatNever", true);
                      formik.setFieldValue("isRepeatWeekly", false);
                      formik.setFieldValue("isRepeatMonthly", false);
                    }}
                  />
                  <label
                    className="form-check-label inline-block text-black text-base"
                    htmlFor="repeat"
                  >
                    Never
                  </label>
                </div>

                <div className="form-check form-check-inline flex flex-row gap-4">
                  <div>
                    <input
                      type="radio"
                      name="section"
                      className={`"form-radio text-primary rounded-full h-5 w-5 border border-black bg-white checked:primary focus:ring-primary mt-1 align-top float-left mr-2 cursor-pointer"`}
                      id="section"
                      checked={formik.values.isRepeatWeekly}
                      onChange={() => {
                        formik.setFieldValue("isRepeatNever", false);
                        formik.setFieldValue("isRepeatWeekly", true);
                        formik.setFieldValue("isRepeatMonthly", false);
                      }}
                    />
                    <label
                      className="form-check-label inline-block text-black text-base"
                      htmlFor="repeat"
                    >
                      Weekly
                    </label>
                  </div>

                  <div>
                    {formik.values.isRepeatWeekly === true && (
                      <select
                        className="w-full px-3 py-0.5 text-black transition ease-in-out bg-white outline-none focus:outline-none"
                        id="dayOfTheWeek"
                        onChange={formik.handleChange}
                        aria-label="Default select example"
                        value={formik.values.dayOfTheWeek}
                      >
                        <option selected value={DAYS.SUNDAY.id}>
                          {DAYS.SUNDAY.name}
                        </option>
                        <option value={DAYS.MONDAY.id}>
                          {DAYS.MONDAY.name}
                        </option>
                        <option value={DAYS.TUESDAY.id}>
                          {DAYS.TUESDAY.name}
                        </option>
                        <option value={DAYS.WEDNESDAY.id}>
                          {DAYS.WEDNESDAY.name}
                        </option>
                        <option value={DAYS.THURSDAY.id}>
                          {DAYS.THURSDAY.name}
                        </option>
                        <option value={DAYS.FRIDAY.id}>
                          {DAYS.FRIDAY.name}
                        </option>
                        <option value={DAYS.SATURDAY.id}>
                          {DAYS.SATURDAY.name}
                        </option>
                      </select>
                    )}
                  </div>
                </div>

                <div className="form-check form-check-inline flex flex-row gap-4">
                  <div>
                    <input
                      type="radio"
                      name="section"
                      className={`"form-radio text-primary rounded-full h-5 w-5 border border-black bg-white checked:primary focus:ring-primary mt-1 align-top float-left mr-2 cursor-pointer"`}
                      id="section"
                      checked={formik.values.isRepeatMonthly}
                      onChange={() => {
                        formik.setFieldValue("isRepeatNever", false);
                        formik.setFieldValue("isRepeatWeekly", false);
                        formik.setFieldValue("isRepeatMonthly", true);
                      }}
                    />
                    <label
                      className="inline-block text-black text-base"
                      htmlFor="repeat"
                    >
                      Monthly
                    </label>
                  </div>

                  <div>
                    {formik.values.isRepeatMonthly === true && (
                      <select
                        className="w-full px-3 py-0.5 text-black transition ease-in-out bg-white outline-none focus:outline-none"
                        id="dayOfTheMonth"
                        onChange={formik.handleChange}
                        aria-label="Default select example"
                        value={formik.values.dayOfTheMonth}
                      >
                        <option selected value={ORDINAL_NUMBERS.FIRST.id}>
                          {ORDINAL_NUMBERS.FIRST.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.SECOND.id}>
                          {ORDINAL_NUMBERS.SECOND.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.THIRD.id}>
                          {ORDINAL_NUMBERS.THIRD.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.FOURTH.id}>
                          {ORDINAL_NUMBERS.FOURTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.FIFTH.id}>
                          {ORDINAL_NUMBERS.FIFTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.SIXTH.id}>
                          {ORDINAL_NUMBERS.SIXTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.SEVENTH.id}>
                          {ORDINAL_NUMBERS.SEVENTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.EIGHTH.id}>
                          {ORDINAL_NUMBERS.EIGHTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.NINTH.id}>
                          {ORDINAL_NUMBERS.NINTH.abbreviation}
                        </option>
                        <option value={ORDINAL_NUMBERS.TENTH.id}>
                          {ORDINAL_NUMBERS.TENTH.abbreviation}
                        </option>
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
                className={`${
                  formik.values?.attachments?.length ? "h-20" : "h-0"
                } w-full`}
              >
                <ul className="flex flex-row">
                  {" "}
                  {formik.values?.attachments?.map((file, i) => (
                    <div className={`flex m-2 border-primary`} key={i}>
                      {(file.fileUrl && file.fileUrl.includes(".pdf")) && (<div><img
                      src="/pdf_image.jpeg"
                      width="30"
                      height="30"
                      alt="PDF"
                    />
                    {file.fileUrl.substring(
                      file.fileUrl.lastIndexOf("/") + 1,
                      file.fileUrl.length
                    )}</div>) }
                    {(file.fileUrl && (file.fileUrl.includes(".doc")|| file.fileUrl.includes(".docx"))) && (<div><img
                      src="/doc_image.jpeg"
                      width="30"
                      height="30"
                      alt="PDF"
                    />
                    {file.fileUrl.substring(
                      file.fileUrl.lastIndexOf("/") + 1,
                      file.fileUrl.length
                    )}</div>) }
                    {(file.fileUrl && file.fileUrl.includes(".csv")) && (<div><img
                      src="/pdf_image.jpeg"
                      width="30"
                      height="30"
                      alt="csv"
                    />
                    {file.fileUrl.substring(
                      file.fileUrl.lastIndexOf("/") + 1,
                      file.fileUrl.length
                    )}</div>) }  
                    {!file.fileUrl && <CustomThumbnail file={file} height={90} width={90} />}                      
                    </div>
                  ))}
                </ul>
             </div>
            <div className="pt-6">
              <div className="flex flex-row gap-4">
                <div className="w-56">
                  <Button type="submit" label="Send" textsize="base" />
                </div>
                <div className="flex flex-row gap-4 text gray my-auto mr-28">
                  {/* <TextIcon className="h-6 w-6" onClick={() => setFontSize(fontSize + 1)} /> */}
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      formik.setFieldValue(
                        "attachments",
                        formik.values.attachments.concat(acceptedFiles)
                      );
                    }}
                    accept=".doc,.docx,.pdf,.csv"
                    minSize={1024}
                    maxSize={3072000}
                  >
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <AttachFileIcon className="h-6 w-6" />
                        </div>
                      );
                    }}
                  </Dropzone>
                  {/* <InsertLinkIcon className="h-6 w-6" /> */}
                  {/* <Dropzone
                    onDrop={(acceptedFiles) => {
                      formik.setFieldValue(
                        "images",
                        formik.values.images.concat(acceptedFiles)
                      );
                    }}
                    accept="image/*"
                    minSize={1024}
                    maxSize={3072000}
                  >
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <ImageIcon className="h-6 w-6" />
                        </div>
                      );
                    }}
                  </Dropzone>                   */}
                </div>
                <div className="ml-56 my-auto">
                  <DeleteIcon
                    className="h-6 w-6"
                    onClick={() => clearForm()}
                    // onClick={() => deleteNewsletter()}
                  />
                </div>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>

      <div className="flex flex-col w-3/12">
        <div className="text-gray text-base">
          List of Promotion / Newsletter
        </div>
        <div>
          <section
            className="lg:mt-8 lg:pb-12"
            aria-labelledby="gallery-heading"
          >
            <ul className="grid grid-cols-1 gap-6 w-full text-base">
              {newsletters?.map((newsletter, index) => (
                <li key={index} className="col-span-1 rounded-3xl h-auto">
                  <div className="rounded-3xl overflow-hidden h-full w-full justify-center place-items-center shadow-lg p-6">
                    <div className=" flex flex-col gap-4">
                      <div className="flex flex-row gap-60">
                        <div className="text-black">{newsletter.subject}</div>
                        <div className="text-gray">
                          <PencilIcon
                            className="h-6 w-6"
                            onClick={() => editNewsletter(newsletter.id)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row gap-16">
                        <div className="text-gray text-base">To:</div>
                        <div className="flex flex-col text-black">
                          <div>
                            {newsletter.sendToClients ? "Clients, " : ""}
                            {newsletter.sendToArtists ? " Artist, " : ""}
                            {newsletter.to}
                          </div>
                          <div>{newsletter.email}</div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-7">
                        <div className="text-gray">Repeat:</div>
                        <div className="text-black">
                          {newsletter.isRepeatWeekly
                            ? "Every " + getDaysByInt(newsletter.dayOfTheWeek)
                            : ""}
                          {newsletter.isRepeatMonthly
                            ? "Every " +
                              newsletter.dayOfTheMonth +
                              nth(newsletter.dayOfTheMonth) +
                              " of Month"
                            : ""}
                          {newsletter.isRepeatNever ? "Never " : ""}
                        </div>
                      </div>
                      <div className="flex flex-row text-gray text-sm">
                        <div className="mr-2">Added</div>
                        <div>
                          {moment(newsletter.createdAt).format("DD MMMM yyyy")}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CreateNewsletter;
