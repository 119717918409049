/* eslint-disable eqeqeq */
import React, { useState } from "react";
import FaqForm from "./components/FaqForm";
import Faq from "./components/Faq";
import { navigations, userNavigations, USER_ROLE_MANGER_ID } from "../../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";

const FaqView = ({
  faqState,
  onSubmit,
  showForm,
  setForm,
  loginData,
  selectedOption,
  setSelectedOption,
  options,
  deleteFaq,
  editFaq,
  editedData,
}) => {
  const [lang, setSelectedLang] = useState("1");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />

        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex items-stretch overflow-hidden">
            {/* Main content */}
            <div className="flex flex-1 bg-white">
              <div className="w-full md:mx-0.1 py-2 md:py-5 md:px-4">
                <div className="mx-4 md:mx-72">
                  <div className="text-2xl font-normal font-sans text-primary mb-6">
                    FAQ
                  </div>
                  <div className="flex flex-row gap-10">
                    <div
                      className={`${
                        lang === "1" ? "text-primary" : "text-gray"
                      } font-black text-base font-sans`}
                      onClick={() => setSelectedLang("1")}
                    >
                      English
                    </div>
                    <div
                      className={`${
                        lang === "2" ? "text-primary" : "text-gray"
                      } font-black text-base font-sans`}
                      onClick={() => setSelectedLang("2")}
                    >
                      German
                    </div>
                  </div>
                  <select
                    className="my-10 border-rounder border border-link rounded-md pr-20 py-2 font-sans text-base pl-2"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    {options.map((item) => (
                      <option key={item.id} value={item.id} className="">
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {!showForm && (
                    <>
                      {faqState?.getAll?.map((item) =>
                        item.categoryId == selectedOption ? (
                          <Faq
                            key={item.id}
                            data={item}
                            lang={lang}
                            deleteFaq={deleteFaq}
                            editFaq={editFaq}
                          />
                        ) : null
                      )}
                      <div className="text-primary" onClick={setForm}>
                        Add Question & Answer
                      </div>
                    </>
                  )}
                  {showForm && (
                    <FaqForm
                      onSubmit={onSubmit}
                      setForm={setForm}
                      editedData={editedData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqView;
