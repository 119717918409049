import {
  DEFAULT_BUTTON_WIDTH,
  DEFAULT_BUTTON_TEXT_SIZE,
} from "utils/constants";

const Button = (props) => {
  const width = props.width ? props.width : DEFAULT_BUTTON_WIDTH;
  const textsize = props.textsize ? props.textsize : DEFAULT_BUTTON_TEXT_SIZE;
  const background = props.background ? props.background : "primary";
  const color = props.color ? props.color : "white";
  const bordercolor = props.bordercolor ? props.bordercolor : "transparent";
  const rounded = props.rounded ? props.rounded : "rounded-full";
  const font = props.font === "normal" ? "" : props.font ? props.font : "font-bold";
  const shadow = props.shadow ? props.shadow : "shadow-sm";
  const hoverbg = props.hoverbg ? props.hoverbg : "primary";
  const hovertext = props.hovertext ? props.hovertext : "white";
  const border = props.border === "none" ? "" : "border";
  const paddingv = props.paddingv ? props.paddingv : 3;
  const place = props.place ? props.place : "justify-center"
  const px = props.px ? props.px : "4"

  return (
    <>
      <button
        {...props}
        className={`font-sans w-${width} flex ${place} py-${paddingv} px-${px} ${border} border-${bordercolor} ${rounded} ${shadow} text-${textsize} ${font} text-${color} bg-${background} hover:bg-${hoverbg} hover:text-${hovertext} focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent disabled:opacity-50 ...`}
      >
        {props.label}
      </button>
    </>
  );
};

export default Button;
