/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetArtistPortfolio,
  getArtistPortfoliosByArtistId,
} from "../../../redux/actions/ArtistPortfolioAction";
import ArtistPortFolioListView from "./ArtistPortFolioListView";
import { VIEWS } from "../../../layouts/ArtistConstants";

export default function ArtistPortfolioListContainer(props) {
  const selectedArtistId = props.loginData.id;

  const artistPortfolioList = useSelector(
    (state) => state.artistPortfolio.data
  );
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (selectedArtistId) {
      dispatch(resetArtistPortfolio());
      dispatch(getArtistPortfoliosByArtistId(selectedArtistId));
    }
  }, [selectedArtistId]);

  const addPortfolio = () => {
    dispatch(resetArtistPortfolio());
    history.push(`/artist/artistportfolio/${VIEWS.ARTIST_PORTFOLIO_LIST.id}`);
  };

  const showArtistHomeView = () => {
    // history.push(`/artist/home/${selectedArtistId}`);
    history.replace(`/`);

  };

  return (
    <div>
      <ArtistPortFolioListView
        loginData={props.loginData}
        artistPortfoliosList={artistPortfolioList}
        addPortfolio={addPortfolio}
        showArtistHomeView={showArtistHomeView}
        title="Portfolio"
      />
    </div>
  );
}
