/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as ArrowRight } from "assets/ico_arrow_right.svg";
import { ReactComponent as ArrowLeft } from "assets/ico_arrow_left.svg";
import { SERVER_URL } from "utils/constants";

const zoomedImageContainer = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  "background-color": "rgba(0, 0, 0, 0.9)",
  display: "flex",
  "justify-content": "center",
  "z-index": 1000,
};

export default function ImageCarouselSlider({
  images,
  setImageComment,
  setTatooImageId,
  setCommentId,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomedImage, setZoomedImage] = useState(null);

  useEffect(() => {
    setImageComment(images[0]?.project_comment?.comment ?? "");
    setTatooImageId(images[0]?.id);
    setCommentId(images[0]?.projectCommentId ?? "");
  }, []);

  const next = () => {
    let index = currentSlide + 1;
    setCurrentSlide(index);

    if (index < images.length) {
      setImageComment(images[index]?.project_comment?.comment ?? "");
      setTatooImageId(images[index]?.id);
      setCommentId(images[index]?.projectCommentId ?? "");
    }
  };

  const prev = () => {
    let index = currentSlide - 1;
    setCurrentSlide(currentSlide - 1);

    if (index >= 0) {
      setImageComment(images[index]?.project_comment?.comment ?? "");
      setTatooImageId(images[index]?.id);
      setCommentId(images[index]?.projectCommentId ?? "");
    }
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
      setImageComment(images[index]?.project_comment?.comment ?? "");
      setTatooImageId(images[index]?.id);
      setCommentId(images[index]?.projectCommentId ?? "");
    }
  };

  const openZoomedImage = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  return (
    <div className="grid grid-cols-8 items-end justify-center h-full w-full flex gap-4">
      <div className="w-full justify-center flex">
        <div className="flex w-20 h-20 mb-8 items-center justify-center">
          <div
            className="flex bg-primary rounded-md w-8 h-8 justify-center items-center"
            onClick={() => prev()}
          >
            <ArrowLeft />
          </div>
        </div>
      </div>
      <div className="col-start-2 col-span-6 h-full w-full">
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={currentSlide}
          onChange={(e) => updateCurrentSlide(e)}
          className="justify-center h-full w-full"
          dynamicHeight
          renderThumbs={(children) =>
            children.map((item) => {
              return (
                <img
                  key={item.key}
                  src={item.props.children.props.src}
                  className="flex h-20 w-20 object-cover object-center"
                  alt="Carousel"
                />
              );
            })
          }
        >
          {images.length !== 0 &&
            images.map((image) => (
              <div 
                key={image.id} 
                className="" 
                onClick={() => openZoomedImage(`${SERVER_URL}` + image.tatooImageUrl)}
              >
                <img
                  src={`${SERVER_URL}` + image.tatooImageUrl}
                  className="flex rounded max-h-96 object-cover object-center"
                  alt="Carousel"
                />
              </div>
            ))}
        </Carousel>
        {zoomedImage && (
          <div style={zoomedImageContainer} onClick={closeZoomedImage}>
            <img src={zoomedImage} alt="zoomed"
            className="object-cover object-center h-full w-full" />
          </div>
        )}
      </div>
      <div className="w-full justify-center flex">
        <div className="flex col-start-8 w-20 h-20 mb-8 items-center justify-center">
          <div
            className="flex bg-primary rounded-md w-8 h-8 justify-center items-center"
            onClick={() => next()}
          >
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}
