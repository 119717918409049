/* eslint-disable eqeqeq */
import { useState } from "react";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import ManagerGrid from "../../components/ManagerGrid";
import StudioGrid from "./components/StudioGrid";
import { navigations, userNavigations, USER_ROLE_MANGER_ID } from "layouts/AdminConstants";
// import SessionExpiry from "components/SessionExpiry";
import localStorageService from "../../services/localStorageService";
import { HIDE_DISABLE_FLAG } from "utils/constants";

export default function Home({
  selectedTab,
  setSelectedTab,
  loginData,
  managerError,
  managers,
  studioError,
  studios
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [tabs, setTabs] = useState([
    { id: "1", name: "My Managers", href: "#", current: selectedTab == '1' },
    { id: "2", name: "My Studios", href: "#", current: selectedTab == '2' },
    { id: "3", name: "Partner Studios Managers", href: "#", current: selectedTab == '3', isDisabled: HIDE_DISABLE_FLAG },
    { id: "4", name: "Partner Studios", href: "#", current: selectedTab == '4', isDisabled: HIDE_DISABLE_FLAG },
  ]);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    localStorageService.setItem("admin_home_tab",tabToSet.id )
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  let grid;
  if (selectedTab === "1" || selectedTab === "3") {
    //My Managers & Partner Studios Managers Tab
    grid = (
      <ManagerGrid managers={managers} selectedTab={selectedTab} loginData={loginData} roleId = {loginData.userRoleId} />
    );
  } else if (selectedTab === "2" || selectedTab === "4") {
    //My Studios & Partner Studios Tab
    grid = (
      <StudioGrid studios={studios} selectedTab={selectedTab} />
    );
  }

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full bg-bgg">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={loginData}
          />

          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            <SideNavBar
              navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
              mobileMenuOpen={mobileMenuOpen}
              handleMenu={handleMenuOpen}
              loginID={loginData.id}
            />
            {/* Main content */}
            <div className="flex-1 flex items-stretch overflow-hidden h-screen">
              <main className="flex-1 overflow-y-auto">
                <div className="pt-4 max-w-7xl px-2 md:px-8">
                  <TabNavBar tabs={tabs} handleChange={setTab} selectedTab={selectedTab}/>
                  {grid}
                </div>
              </main>
              {/* <SessionExpiry sessionError = {managerError || studioError}/> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
