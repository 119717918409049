/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
  USER_TYPE_ARTIST,
  USER_TYPE_CLIENT,
  USER_TYPE_STUDIO,
} from "../../layouts/AdminConstants";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import TabNavBar from "components/Navbars/TabNavBar";
import EditProfile from "components/EditProfile";
import ChangePassword from "components/ChangePassword";
import ArtistSideNavBar from "views/artist/components/ArtistSideNavBar";
import { getUserRole } from "layouts/ArtistConstants";

export default function ProfileView({
  onSubmit,
  error,
  userDataObj,
  selectedTab,
  setSelectedTab,
  loginData,
  artistReviewsAvg,
  showAlert,
  setShowAlert,
  alertMessage,
  countries,
  techniques,
  alertMessageIcon
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [tabs, setTabs] = useState([
    { id: "1", name: "Edit Profile", href: "#", current: true },
    { id: "2", name: "Change Password", href: "#", current: false },
  ]);

  let view;
  // let editview = <EditProfile userDataObj={userDataObj} />;
  // let changepwdview = <ChangePassword/>;
  //const [view, setView] = useState(editview);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  if (selectedTab === "1") {
    view = (
      <EditProfile
        userDataObj={userDataObj}
        onSubmit={onSubmit}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
        countries={countries}
        techniques={techniques}
      />
    );
  } else if (selectedTab === "2") {
    view = (
      <ChangePassword
        userDataObj={userDataObj}
        onSubmit={onSubmit}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
        alertMessageIcon={alertMessageIcon}
      />
    );
  }

  let role = getUserRole(loginData?.userRoleId);
  let clientview = false;
  if (role === "Client") {
    userNavigations[0].href = "/client/profile";
    userNavigations[1].href = "/client/signout";
    clientview = true;
  } else if (role === "Admin") {
    userNavigations[0].href = "/admin/profile";
    userNavigations[1].href = "/admin/signout";
  } else if (role === "Artist") {
    userNavigations[0].href = "/artist/profile";
    userNavigations[1].href = "/artist/signout";
  }

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={loginData}
          />

          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {loginData.userTypeId != USER_TYPE_CLIENT &&
              loginData.userTypeId != USER_TYPE_ARTIST &&
              loginData.userTypeId != USER_TYPE_STUDIO && (
                <SideNavBar
                  navigations={navigations(loginData.userRoleId !== USER_ROLE_MANGER_ID)}
                  mobileMenuOpen={mobileMenuOpen}
                  handleMenu={handleMenuOpen}
                  loginID={loginData.id}
                />
              )}

            {loginData.userTypeId == USER_TYPE_ARTIST && (
              <div className="w-full md:w-1/4 md:h-full bg-white">
                <ArtistSideNavBar
                  //loginData={loginData} // DONOT DELETE THIS
                  loginData={userDataObj}
                  avgRating={artistReviewsAvg}
                />
              </div>
            )}
            {/* Main content */}
            <div className="flex-1 flex items-stretch overflow-hidden">
              <main className="flex-1 overflow-y-auto">
                <div className="md:pt-8 max-w-7xl px-5 md:px-12 mt-4">
                  <TabNavBar tabs={tabs} handleChange={setTab} showInMobileMode = {true} tabSpace={2}/>
                  {view}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
