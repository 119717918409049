/* eslint-disable eqeqeq */
import React from "react";
import moment from "moment";
import { ReactComponent as MaleIcon } from "assets/ic_male.svg";
import { ReactComponent as FemaleIcon } from "assets/ic_female.svg";
import { ReactComponent as GenderIcon } from "assets/ic_gender.svg";
import { ReactComponent as MoneyIcon } from "assets/ic_money.svg";
import { ReactComponent as ChatIcon } from "assets/ic_chat.svg";
import Rating from "@mui/material/Rating";
import {
  TABS,
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
  // BOSS_TABS,
} from "../../../layouts/ArtistConstants";
import { SERVER_URL } from "utils/constants";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#C7A33F",
  },
  "& .MuiRating-iconHover": {
    color: "#AFAFAF",
  },
  "& .MuiRating-iconEmpty": {
    color: "#AFAFAF",
  },
});

const ArtistProjects = (props) => {
  let history = useHistory();

  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  const projectOnClick = (id) => {
    if (
      props.role === USER_ROLE_BOSS ||
      props.role === USER_ROLE_ADMIN ||
      props.role === USER_ROLE_MANAGER
    ) {
      history.push("/admin/project-details/" + id);
    } else {
      history.push("/artist/project-details/" + id);
    }
  };

  const projectCard = (items) => {
    return (
      <div
        key={items.id}
        className="flex grid grid-col-8 xl:grid-col-6 gap-1 rounded-2xl shadow-md py-4 auto-cols-fr border border-cardDropShadowColor bg-bcard mt-1"
        onClick={() => projectOnClick(items.id)}
      >
        <div className="flex flex-1 col-start-1 col-span-2 xl:col-span-1 h-full items-start mr-1 flex-row ">
          <div
            className={`w-2 bg-${getProjectStatusColor(
              items.projectStatusId
            )} h-1/3 mr-1`}
            hidden={props.selectedTab == 1}
          />
          <div className="flex flex-1 h-full items-start justify-start mr-0 flex-col">
            <img
              className="h-12 w-12 bg-gray-300 rounded-full object-cover object-center border border-tertiary"
              src={
                items?.user?.photoUrl
                  ? SERVER_URL + items?.user?.photoUrl
                  : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
              }
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 col-start-3 col-span-6 xl:col-start-2 xl:col-span-4 h-full flex-col">
          <div className="box-border flex flex-row">
            <span className="text-tertiary text-base font-sans">
              #{items.id}
            </span>
            <div className="flex flex-1"></div>
            <div className="flex flex-row items-start mr-0 ml-2 mb-1">
              {items.projectStatusId == 1 ? (
                <span className="px-3 py-1 bg-primary rounded-lg text-white text-sm font-bold">
                  {items.project_status.statusName}
                </span>
              ) : (
                <div className="flex items-center mr-4">
                  <div className="mr-2 font-bold text-base">
                    {items?.comment_chats ? items?.comment_chats?.length : 0}
                  </div>
                  <ChatIcon />
                </div>
              )}
            </div>
          </div>
          <span className="">
            <div className="flex flex-row items-center">
              <span className="text-secondary text-base font-sans font-normal mr-2">
                {items?.user?.firstName} {items?.user?.lastName}
              </span>
              {items?.gender === "female" ? (
                <FemaleIcon />
              ) : items?.gender === "male" ? (
                <MaleIcon />
              ) : (
                <GenderIcon />
              )}

              {items?.skin_tone?.imageUrl ? (
                <img
                  src={`${SERVER_URL}` + items?.skin_tone?.imageUrl}
                  height="20"
                  width="20"
                  alt="Skin Tone"
                />
              ) : (
                ""
              )}
            </div>
          </span>
          <span className="text-secondary text-sm font-sans font-semibold mr-2">
            {items?.user?.birthYear}
          </span>
          <div className="text-sm mt-2 mb-3 text-tertiary">
            {moment(items.createdAt).format("D MMM, HH:mm")}
          </div>
          <div className="flex flex-row items-start mb-5">
            <div
              hidden={props.selectedTab == TABS.PROJECTHISTORY.id}
              className={`border-3 rounded-full w-4 h-4 mt-1.5 border-${getProjectStatusColor(
                items.projectStatusId
              )} mr-2`}
            />
            <div
              hidden={props.selectedTab == TABS.CURRENTPROJECTS.id}
              className="font-bold text-secondary font-sans text-base flex-1 whitespace-pre-line"
            >
              {items.project_status?.artistStatusName
                .replace("-", "\n")
                .replace("for", " ")
                .replace("Date", " ")}
            </div>
          </div>
          <div className="text-base font-sans flex flex-row items-center md:mr-0">
            <MoneyIcon className="mr-1" />
            <div className="font-bold ml-1">
              {" "}
              {items?.offerPrice
                ? "Price: " + items?.offerPrice
                : items?.counter_offers && items?.counter_offers[0]?.offerPrice
                ? "Price: " + items?.counter_offers[0]?.offerPrice
                : `Budget: ${items?.budgetPriceFrom} - ${items?.budgetPriceTo}`}
            </div>
            <div className="flex flex-1"></div>
            <div className="flex items-center mr-2">
              {items.projectStatusId == 5 &&
                (items?.artist_ratings && items?.artist_ratings?.length > 0 ? (
                  <StyledRating
                    size="small"
                    name="simple-controlled"
                    value={items?.artist_ratings[0]?.rating}
                    color="primary"
                  />
                ) : (
                  <div className="text-tertiary">
                    {"  "}
                    {"Unrated"}
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* <div className="flex flex-1 col-start-5 h-full">
          <div className="flex flex-row items-start">
            {items.projectStatusId == 1 ? (
              <span className="px-3 py-1 bg-primary rounded-lg text-white text-sm font-bold">
                {items.project_status.statusName}
              </span>
            ) : (
              <div className="flex items-center mr-4">
                <div className="mr-2 font-bold text-base">
                  {items?.comment_chats ? items?.comment_chats?.length : 0}
                </div>
                <ChatIcon />
              </div>
            )}
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-8">
      <div className="flex flex-col bg-white w-full px-0">
        <section className="mt-8 pb-12" aria-labelledby="gallery-heading">
          <ul className="grid grid-cols-1 md:gap-6 lg:gap-3 3xl:gap-3 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-2 4xl:grid-cols-3 5xl:grid-cols-4">
            {props?.data?.map((items) => projectCard(items))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ArtistProjects;
