/* eslint-disable react-hooks/exhaustive-deps */
import React,  { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByVerifyString, updateVerifyString } from "../../../redux/actions/UserActions";
import VerifyEmailView from "./VerifyEmailView";
import  { useHistory } from 'react-router-dom';

const VerifyEmailContainer = (props) => {
  const verifyString = props.match.params.id; 
  const error = useSelector((state) => state.user.error);
  const data = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  let history = useHistory();

  let firstTime = true;
  useEffect(() => {
    if (firstTime) {
      dispatch(getUserByVerifyString(verifyString));
      firstTime = false;
    }    
  }, []);

  const onSubmit = () => {
    let obj = {
      id: data.id
    };
    dispatch(updateVerifyString(obj));    
    history.push("/auth/login");
  }    
  
  return (
      <div>
        <VerifyEmailView onSubmit={onSubmit} error={error} data={data}  />
      </div>
  );
}

 export default VerifyEmailContainer