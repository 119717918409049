/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CustomInput from "components/CustomInput";
import CustomSlider from "components/UI/Slider";
import Button from "components/UI/Button";
import { Formik } from "formik";

const marks = [
  {
    value: 1250,
    label: "1250",
  },
  {
    value: 1500,
    label: "1500",
  },
];

const OfferPriceComponent = (props) => {
  const handleFormSubmit = (values) => {
    let obj = {
      offerPrice: {
        price: values.price,
        loggedInUserId: props.loggedInUserId,
      },
      AdminOfferPrice: {
        price: values.price,
      },
    };
    props.onSubmit(obj);
  };

  const [min, setMin] = useState(
    props.budget
      ? props.offerPriceVal - 5000 < 0
        ? 0
        : props.offerPriceVal - 5000
      : 0
  );
  const [max, setMax] = useState(
    props.offerPriceVal ? props.offerPriceVal + 5000 : 5000
  );
  const [defaultValue, setDefaultValue] = useState(
    props.offerPriceVal ? props.offerPriceVal : 0
  );

  marks[0].value = min;
  marks[0].label = min.toString();

  marks[1].value = max;
  marks[1].label = max.toString();

  return (
    <Transition.Root show={props.open} appear={true}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto px-2"
        onClose={() => {}}
      >
        <div className="flex items-start justify-center max-h-screen text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-top sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-top bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all 
                xl:max-w-6xl lg:max-w-4xl md:max-h-4/5 max-w-sm my-4 pb-8"
            >
              <Formik
                initialValues={{ comment: "", price: defaultValue }}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex grid grid-cols-8 m-4">
                      <Dialog.Title className="text-2xl text-primary leading-7 font-semibold col-start-2 col-span-6">
                        {props.title}
                      </Dialog.Title>
                      {props?.page !== "editArtists" && (
                        <div className="col-start-2 col-span-6 mt-4 flex grid grid-cols-6">
                          <div className="text-tertiary text-base">Price:</div>
                          <div className="col-start-5 col-span-2 md:col-start-5 md:col-span-2">
                            <CustomInput
                              label="Price"
                              type="number"
                              name="price"
                              id="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                              inputProps={{
                                maxLength: 8    
                              }} 
                            />
                          </div>
                          <div className="col-start-1 col-span-6 mt-8">
                            <CustomSlider
                              value={values.price}
                              setValue={(newValue) => {
                                setFieldValue("price", newValue);
                              }}
                              min={
                                props.title === "Update Price"
                                  ? min
                                  : values.price
                              }
                              max={max}
                              // disabled={props.title === "Accept Project"}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-row mt-4 mb-4 col-start-2 col-span-6 xl:col-start-2 xl:col-span-4">
                        <div className="sm:col-span-1 sm:flex-col sm:justify-start pr-4 lg:pr-0 lg:mb-5 md:mt-0 flex flex-1">
                          <Button
                            type="submit"
                            label="Accept"
                            textsize="base"
                          />
                        </div>
                        <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 lg:mb-5 md:mt-0 flex flex-1">
                          <Button
                            type="reset"
                            label="Cancel"
                            textsize="base"
                            onClick={() => {
                              setTimeout(() => {
                                props.setOpen(false);
                              }, 0);
                            }}
                            background={"none"}
                            color={"black"}
                            bordercolor={"primary"}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default OfferPriceComponent;
