/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { tabs, graphOptions, defaultGraphCongig } from "./CONSTANTS";

const Graph = ({ paymentMethods, graphsState }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[2]?.value);
  const [graphData, setData] = useState({});

  const graphIniData = [
    {
      months_data: [
        { name: "JAN", value: 0 },
        { name: "FEB", value: 0 },
        { name: "MAR", value: 0 },
        { name: "APR", value: 0 },
        { name: "MAY", value: 0 },
        { name: "JUN", value: 0 },
        { name: "JUL", value: 0 },
        { name: "AUG", value: 0 },
        { name: "SEP", value: 0 },
        { name: "OCT", value: 0 },
        { name: "NOV", value: 0 },
        { name: "DEC", value: 0 },
      ],
      weeks_data: [
        { name: "MON", value: 0 },
        { name: "TUE", value: 0 },
        { name: "WED", value: 0 },
        { name: "THU", value: 0 },
        { name: "FRI", value: 0 },
        { name: "SAT", value: 0 },
        { name: "SUN", value: 0 },
      ],
      years_data: [
        { name: "2020", value: 0 },
        { name: "2021", value: 0 },
        { name: "2022", value: 0 },
      ],
    },
  ];

  useEffect(() => {
    if (graphIniData) {
      const data = graphIniData[0];
      let weeksDataObj = {},
        monthsDataObj = {},
        daysDataObj = {},
        yearsDataObj = {},
        invoiceDataObj = {};
      if (selectedTab === "week") {
        const weeksData = data.weeks_data;
        weeksData.map((item) => {
          let filteredData = graphsState?.graphs?.accounts?.weekWise?.filter(
            (rec) => {
              return rec.dayname === item.name;
            }
          );
          if (filteredData && filteredData.length > 0) {
            item.value = filteredData[0].sum;
          }
        });
        weeksDataObj = {
          labels: weeksData.map((item) => item.name),
          datasets: [
            {
              ...defaultGraphCongig,
              data: weeksData.map((item) => item.value),
            },
          ],
        };
        setData(weeksDataObj);
      } else if (selectedTab === "day") {
        const invoiceData = graphsState?.graphs?.accounts?.invoiceForDayWise;
        invoiceDataObj = {
          labels: invoiceData.map((item) => item.time),
          datasets: [
            {
              ...defaultGraphCongig,
              data: invoiceData.map((item) => item.amount),
            },
          ],
        };
        setData(invoiceDataObj);
      } else if (selectedTab === "year") {
        const monthsData = data.months_data;
        monthsData.map((item) => {
          let filteredData = graphsState?.graphs?.accounts?.monthWise?.filter(
            (rec) => {
              return rec.mon === item.name;
            }
          );
          if (filteredData && filteredData.length > 0) {
            item.value = filteredData[0].sum;
          }
        });
        monthsDataObj = {
          labels: monthsData.map((item) => item.name),
          datasets: [
            {
              ...defaultGraphCongig,
              data: monthsData.map((item) => item.value),
            },
          ],
        };
        setData(monthsDataObj);
      } else if (selectedTab === "month") {
        const daysData = graphsState?.graphs?.accounts?.dayWise;
        daysDataObj = {
          labels: daysData?.map((item) => item.day),
          datasets: [
            { ...defaultGraphCongig, data: daysData?.map((item) => item.sum) },
          ],
        };
        setData(daysDataObj);
      } else if (selectedTab === "all") {
        const yearsData = data.years_data;
        yearsData.map((item) => {
          let filteredData = graphsState?.graphs?.accounts?.yearWise?.filter(
            (rec) => {
              return rec.nyear === item.name;
            }
          );
          if (filteredData && filteredData.length > 0) {
            item.value = filteredData[0].sum;
          }
        });
        yearsDataObj = {
          labels: yearsData.map((item) => item.name),
          datasets: [
            {
              ...defaultGraphCongig,
              data: yearsData.map((item) => item.value),
            },
          ],
        };
        setData(yearsDataObj);
      }
    }
  }, [selectedTab, graphsState]);

  return (
    <div className="w-full flex-col flex items-start gap-6 md:grid md:grid-cols-12">
      <div className="col-start-1 col-span-8 ">
        <div className="flex gap-4 text-gray text-sm">
          {tabs.map((item) => (
            <div
              className={`${selectedTab === item.value && "text-primary"} my-4`}
              onClick={() => setSelectedTab(item.value)}
              key={item.value}
            >
              {item.name}
            </div>
          ))}
        </div>
        {Object.keys(graphData).length !== 0 && (
          <Line options={graphOptions} data={graphData} />
        )}
      </div>
      {/* <div className="col-start-9 col-span-4 w-full mb-4 md:mb-0">
        <PaymentMethod data={paymentMethods} />
      </div> */}
    </div>
  );
};

export default Graph;
