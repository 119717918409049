import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { signIn } from "../../redux/actions/LoginActions";
import { useDispatch, useSelector } from "react-redux";

import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../../utils/constants";

const loginValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    open: false,
  });

  const handleFormSubmit = (values) => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));

    var encrypted = CryptoJS.AES.encrypt(
      values.password,
      SCERETPASS
    ).toString();

    let obj = {
      email: values.email,
      password: encrypted,
    };
    dispatch(signIn(obj));
  };

  const resetPwdClick = () => {
    props.history.push({
      pathname: "/auth/resetPassword",
    });
  };

  const signupClick = () => {
    props.history.push({
      pathname: "/auth/register",
    });
  };

  const autoHideMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 10000);
  };

  const autoHideSuccessMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
      props.history.replace({
        pathname: "/",
      });
    }, 1000);
  };

  let error = null;
  if (
    state.open &&
    login.success === false &&
    login.loginData === null &&
    login.error !== null
  ) {
    autoHideMessage();
    error = (
      <Alert className="m-4" severity="error">
        {login.error}
      </Alert>
    );
  }
  if (
    state.open &&
    login.success === true &&
    login.loginData !== null &&
    login.error === null &&
    login.loginData?.id
  ) {
    autoHideSuccessMessage();
    error = (
      <p className="mt-6 text-green-900 text-center">{"Login Successful!"}</p>
    );
  }
  return (
    <div className="flex flex-1 flex-col min-h-screen items-center">
      <div className="flex flex-1 flex-col h-full max-w-sm items-center justify-between">
        <div
          onClick={() =>
            props.history.replace({
              pathname: "/",
            })
          }
          className="mt-4"
        >
          <object
            type="image/svg+xml"
            data="/2ndSkin.svg"
            className="h-20 w-auto content-center mx-auto object-cover pointer-events-none "
          >
            2ndSkin
          </object>
        </div>

        <div>
          <object
            type="image/svg+xml"
            data="/ic_login_user.svg"
            className="h-10 w-8 content-center mx-auto object-cover pointer-events-none"
          >
            2ndSkin
          </object>

          <h2 className="mt-4 text-2xl font-bold text-primary font-sans text-center">
            {t("login.welcomeBack")}
          </h2>

          <div className="mt-0">
            <div className="mt-4">
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginValidationSchema}
                validateOnMount={true}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className="overflow-hidden">
                      <div className="text-sm">{t("login.email")}</div>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={"Enter your email"}
                        autoComplete="username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        rounded={"full"}
                        textsize="sm"
                      />
                      <p className="text-red font-sans">
                        {errors.email && touched.email && errors.email}
                      </p>
                    </div>

                    <div className="space-y-1 mt-4">
                      <div className="text-sm">{t("login.password")}</div>
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        // label={t("login.password")}
                        placeholder={"Password"}
                        autoComplete="current-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        showeye={+true}
                        onShowPassword={() => setShowPassword(!showPassword)}
                        showpassword={+showPassword}
                        rounded={"full"}
                        textsize="sm"
                      />
                    </div>
                    <p className="text-red font-sans">
                      {errors.password && touched.password && errors.password}
                    </p>

                    <div className="grid grid-cols-2 md:gap-y-12 sm:grid-cols-2 sm:gap-x-8 mt-0">
                      <div className="my-0 col-start-2 col-span-1">
                        {/* <Button
                          onClick={() => resetPwdClick()}
                          label="Forgot Password?"
                          textsize="sm"
                          border="none"
                          background="white"
                          color="hometertiary"
                          shadow="shadow-none"
                          hoverbg="white"
                          hovertext="primary"
                          font="normal"
                          px="0"
                        /> */}
                        <div
                          className="text-hometertiary flex flex-row mt-2 justify-center text-center w-full text-sm"
                          onClick={() => resetPwdClick()}
                        >
                          Forgot Password?
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center mt-2">
                      <Button
                        type="submit"
                        width="full"
                        textsize="base"
                        label={t("login.login")}
                        background="homeaccent"
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {error}
          </div>

          <div className="text-hometertiary flex flex-row mt-2 justify-center text-center w-full text-sm">
            Don't have an account?{" "}
            <div className="text-primary ml-2" onClick={() => signupClick()}>
              Sign up
            </div>
          </div>
        </div>

        <p className="text-tertiary text-sm font-sans font-normal text-center mb-4">
          &copy; 2024 Ink Foundation, All rights reserved.
        </p>
      </div>
    </div>
  );
};
export default Login;
