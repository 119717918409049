/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { getDaysByInt } from "utils/constants";
import EditProposedDatePage from "./EditProposedDatePage";
import {
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  USER_ROLE_CLIENT,
} from "../../CONSTANTS";

const RegularDates = (props) => {

  // order by day and time
  props?.data?.sort((a, b) => {
    return ((a.day + a.fromTime.slice(0, 2)) - (b.day + b.fromTime.slice(0, 2)));
  });

  let show = false,
    aShow = false;
  if (
    props?.role === USER_ROLE_CLIENT &&
    props?.projectStatus !== "Project Done" &&
    props?.projectStatus !== "Declined Project" &&
    props?.projectStatus !== "Withdrawn Project" 
  ) {
    show = true;
  }

  if (
    props?.role === USER_ROLE_PROJECT_ADMIN ||
    props?.role === "Admin" ||
    props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST
  ) {
    aShow = true;
  }

  if (props?.projectStatus === "Refunded") {
    aShow = true;
    show = false;
  }

  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);

 

  let divs = [];
  if (props.data) {
    var daysData = [];
    props.data.forEach(function (value, i) {
      var index =
        daysData.length > 0
          ? daysData.map((i) => i.day).indexOf(value.day)
          : -1;
      if (index !== -1) {
        daysData[index].timeEntries.push({
          fromTime: value.fromTime,
          toTime: value.toTime,
        });
      } else {
        var obj = {
          day: value.day,
          timeEntries: [
            {
              fromTime: value.fromTime,
              toTime: value.toTime,
            },
          ],
        };
        daysData.push(obj);
      }
    });

    daysData.map((dayData, i) => {
      divs.push(
        <div key={i} className="font-sans text-base">
          {getDaysByInt(dayData.day).slice(0, 3) +
            ", " +
            dayData.timeEntries
              .map(
                (timeEntry) =>
                  timeEntry.fromTime.slice(0, 5) +
                  " - " +
                  timeEntry.toTime.slice(0, 5)
              )
              .join("; ")}
        </div>
      );
    });
  }

  return (
    <div>
      <div className="flex flex-row mt-5">
        {(show || aShow) && (
          <div className="text-base text-tertiary mb-2 font-sans">Regular Date:</div>
        )}
        <div className="flex flex-1" />
        {show && (
          <div className="text-sm text-primary" onClick={handleEditOpen}>
            Edit
            {editOpen && (
              <EditProposedDatePage
                open={editOpen}
                setOpen={setEditOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                data={props.data}
              />
            )}
          </div>
        )}
      </div>
      {(show || aShow) && <div>{divs}</div>}
    </div>
  );
};

export default RegularDates;
