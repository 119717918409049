/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CustomInput from "components/CustomInput";
import { getStudiosByStudioType } from "../../../../redux/actions/StudioAction";
import { getMatchingArtistsByProjectAndStudio } from "../../../../redux/actions/AdminArtistAction";
import { Formik } from "formik";
import {
  getProjectStatusByName,
  USER_ROLE_PROJECT_ADMIN,
} from "../../CONSTANTS";
import Button from "components/UI/Button";
import { ReactComponent as DropdownArrow } from "../../../../assets/ico_dropdown_arrow.svg";
import { studioTypes } from "../../../../layouts/AdminConstants";
import { SERVER_URL } from "utils/constants";
import { Alert } from "@mui/material";
import CustomSlider from "components/UI/Slider";
import { useHistory } from "react-router-dom";

const marks = [
  {
    value: 1250,
    label: "1250",
  },
  {
    value: 1500,
    label: "1500",
  },
];

const PriceComponent = (props) => {
  const dispatchIn = useDispatch();
  const allStudioDataObj = useSelector((state) => state.studio.getStudioByType);
  const allArtistDataObj = useSelector((state) => state?.artist?.data?.project);
  const [ids, setIds] = useState([]);
  const [artistData, setArtistData] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState(0);
  const [min, setMin] = useState(
    props.isCounter? props.budget.toPrice : props.budget
      ? props.budget.toPrice - 5000 < 0
        ? 0
        : props.budget.toPrice - 5000
      : 0
  ); // min value = 1000 minus max value or zero
  const [max, setMax] = useState(
    props.budget ? props.title === "Accept Project" ? props.budget.toPrice : props.budget.toPrice + 5000 : 5000
  ); // max value = 5000 plus max value or 5000
  const [defaultValue, setDefaultValue] = useState(
    props.budget ? props.budget.toPrice : 0
  );
  const [errorMsg, setErrorMsg] = useState(null);
  const sortingArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  marks[0].value = min;
  marks[0].label = min.toString();
 
  marks[1].value = max;
  marks[1].label = max.toString();

  useEffect(() => {
    if (props?.role === USER_ROLE_PROJECT_ADMIN) {
      dispatchIn(getStudiosByStudioType(studioTypes["My Studio"]));
    }
    dispatchIn(
      getMatchingArtistsByProjectAndStudio(props.projectId, selectedStudioId)
    );
  }, [selectedStudioId]);

  useEffect(() => {
    let artistDataArr = [];
    let newIds = [];
    if (allArtistDataObj) {
      newIds = [...ids];
      allArtistDataObj.map((artist, idx) => {
        artistDataArr.push({
          id: artist.id,
          name:
            artist.firstName + " " + (artist.lastName ? artist.lastName : ""),
          days: artist.dayname, //dayStr.join(),
          photo: artist.photoUrl,
          studioid: artist.studioId,
        });

        if (props.data && props.data.length > 0) {
          let fnd = props.data.some((user) => user.userId === artist.id);
          if (fnd) {
            newIds.push(artist.id);
          }
        }

        if (props.cOffData && props.cOffData.length > 0) {
          let fnd = props.cOffData.some((user) => user.userId === artist.id);
          if (fnd) {
            newIds.push(artist.id);
          }
        }
      });      
    }
    setIds(newIds);  
    setArtistData(artistDataArr);
  }, [allArtistDataObj]);

  const handleClose = () => {
    props.setOpen(false);
  };

  let optionItems;
  if (allStudioDataObj && allStudioDataObj.length > 0) {
    optionItems = allStudioDataObj.map((studio) => (
      <option
        className="text-primary shadow-none border-0 focus:border-0"
        key={studio.id}
        value={studio.id}
      >
        {studio.placeName}
      </option>
    ));
  }
  const goToPrevPage = () => {
    setTimeout(() => {
      props.setOpen(false);
    }, 0);
  };

  const handleFormSubmit = (values) => {
    if (props.isCounter && values.price < min) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Please select amount greater than offer value."}
        </Alert>
      );
      return;
    }

    if (props.isAccept && values.price > max) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Please select amount less than offer value."}
        </Alert>
      );
      return;
    }

    let artistIds = ids;

    if (artistIds && artistIds.length <= 0) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Please select atleast one artist."}
        </Alert>
      );
      return;
    }

    if (props?.page !== "acceptProject" && props?.page !== "editArtists") {
      if (values.price < defaultValue) {
        setErrorMsg(
          <Alert className="m-4 " severity="error">
            {"Please select amount greater than offer value."}
          </Alert>
        );
        return;
      }
    }

    // let studios = studioIds;
    let projectStatusId = getProjectStatusByName.Counter_Offer_by_Admin;
    if (props?.page === "acceptProject") {
      projectStatusId = getProjectStatusByName.Accepted_Project_by_Admin;
    }
    let obj;
    if (props?.page === "editArtists") {
      obj = {
        EditArtists: {
          artists: {
            artists: artistIds,
            //  studios: studios
          },
          loggedInUserId: props.loggedInUserId,
        },
      };
    } else {
      obj = {
        comments: {
          comment: values.comment,
          loggedInUserId: props.loggedInUserId,
        },
        status: {
          statusId: projectStatusId,
          loggedInUserId: props.loggedInUserId,
        },
        discount: {
          addDiscount: values.discount,
        },
        price: {
          price: values.price,
        },
        artists: {
          artists: artistIds,
          //  studios: studio
        },
      };
    }

    if (props?.page !== "editArtists" && values.comment) {
      const commentChatObj = {
        msgToSocket: props.projectId + "#" + props.pClientId + "#C",
        fromUserId: props.loggedInUserId,
        comment: values.comment,
        msgFromSocket: props.projectId + "#" + props.loggedInUserId + "#C",
        projectId: props.projectId,
      };
      props.onSubmit(obj, commentChatObj);
    } else {
      props.onSubmit(obj);
    }
    props.setOpen(false);
  };

  const selectArtist = (e) => {
    const selectedId = parseInt(e.target.value);
    if (ids.includes(selectedId)) {
      // unchecked
      let artIndex = ids.indexOf(selectedId);
      const newIds = ids.filter((id) => id !== selectedId);
      setIds(newIds);
    } else {
      // checked
      const newIds = [...ids];
      newIds.push(selectedId);
      setIds(newIds);
    }
  };

  const getArtistsForStudio = (val) => {
    setSelectedStudioId(val);
  };

  let history = useHistory();
  const showArtistProfile = (id) => {        
      history.push("/admin/artist/" + id);
  };

  return (
    <div>
      <Transition.Root show={props.open} appear={true}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-start justify-center max-h-screen text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all
                xl:max-w-6xl lg:max-w-4xl md:max-h-4/5 max-w-sm md:m-10 m-2 xs:p-1 sm:p-4 md:p-0"
              >
                <Formik
                  initialValues={{ comment: "", price: defaultValue }}
                  onSubmit={(values) => {
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="flex grid grid-cols-8">
                        <Dialog.Title className="col-start-1 col-span-7 mx-2 md:mx-0 md:col-start-2 md:col-span-6 text-2xl text-primary leading-7 font-semibold font-sans mt-6">
                          {props.title}
                        </Dialog.Title>
                        {props?.page !== "editArtists" && (
                          <div className="col-start-1 col-span-8 md:col-start-2 md:col-span-6 mx-4 md:mx-0 mt-4 flex grid grid-cols-6">
                            <div className="text-tertiary text-base">
                              Price:
                            </div>
                            <div className="col-start-5 col-span-2 md:col-span-2 md:col-start-5">
                              <CustomInput
                                label="Price"
                                type="number"
                                name="price"
                                id="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}   
                                inputProps={{
                                  maxLength: 8    
                                }}                                                     
                              />
                            </div>
                            <div className="col-start-1 col-span-6 mt-8">
                              <CustomSlider
                                value={values.price}
                                setValue={(newValue) => {
                                  setFieldValue("price", newValue);
                                }}
                                min={props.isCounter? min : props.isCounter ? values.price : min}
                                max={max}
                                //disabled={props.title === "Accept Project"}
                              />
                            </div>
                          </div>
                        )}
                        <div className="text-tertiary text-basefont-sans mt-4 mb-4 col-start-1 col-span-7 mx-2 md:mx-0 md:col-start-2 md:col-span-6">
                          Select Artist:
                        </div>
                        <div className="col-start-1 col-span-8 mx-2 md:mx-0 md:col-start-2 md:col-span-6 md:col-start-2 md:col-span-3 ">
                          {props?.role === USER_ROLE_PROJECT_ADMIN && (
                            <div className="border-tertiary border rounded-xl flex items-center px-3 py-3">
                              <select
                                className="font-sans text-base font-normal w-full appearance-none shadow-none border-0 focus:outline-none"
                                name="studioId"
                                id="studioId"
                                onChange={(e) => {
                                  setFieldValue("studioId", e.target.value);
                                  getArtistsForStudio(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.studioId}
                              >
                                <option
                                  className="shadow-none border-0"
                                  value="0"
                                  key="-1"
                                >
                                  All Studios
                                </option>
                                {optionItems}
                              </select>
                              <DropdownArrow />
                            </div>
                          )}
                          <div />
                        </div>
                        <div className="flex flex-col col-start-1 col-span-8 mx-2 md:mx-0 md:col-start-2 md:col-span-6 lg:grid grid-cols-2 gap-4 mt-4">
                          {artistData.map((item, index) => (
                            <div key={index.toString()}>
                              <div className="flex flex-row items-center rounded-xl border border-primary px-4 py-3">
                                <img onClick={() => showArtistProfile(item.id)}
                                  className="flex mr-4 w-12 h-12 bg-gray rounded-full border border-tertiary object-fill"
                                  src={
                                    item.photo
                                      ? SERVER_URL + item.photo
                                      : SERVER_URL +
                                        "uploads/default-images/DefaultProfile.jpeg"
                                  }
                                  alt=""
                                />
                                <div className="flex flex-col flex-1 text-base">
                                  <input id="artistNameLabel" placeholder={item.name} disabled />
                                  <div className="text-tertiary text-xs md:text-sm xl:text-base">
                                    {item?.days?.split(',').sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b)).join(",")}
                                  </div>
                                </div>
                                <div className="text-primary text-xl ml-4">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    onChange={(e) => {
                                      selectArtist(e);
                                    }}
                                    checked={
                                      ids.includes(item.id) ? true : false
                                    }
                                    style={{
                                      accentColor: "#C7A33F",
                                    }}
                                    className="h-5 w-5"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {props?.page === "acceptProject" &&
                          props?.role === USER_ROLE_PROJECT_ADMIN && (
                            <div className="col-start-1 col-span-8 mx-2 md:mx-0 md:col-start-2 md:col-span-6 mt-4 grid grid-cols-6">
                              <div className="flex items-center mb-2 col-span-6">
                                <input
                                  type="checkbox"
                                  id="chkDiscount"
                                  name="chkDiscount"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    accentColor: "#C7A33F",
                                  }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <label htmlFor="chkDiscount">
                                  Add Discount
                                </label>
                              </div>
                              <div className="mt-2 flex col-start-1 col-span-6 md:col-span-5 lg:col-span-3">
                                <div className="flex flex-row border border-primary rounded-lg p-3 flex-1 w-full">
                                  <input
                                    type="text"
                                    name="discount"
                                    id="discount"
                                    className="focus:outline-none flex flex-1"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.discount}
                                    maxLength={2}
                                  />
                                  <div className="flex text-tertiary text-base">
                                    %
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {props?.page !== "editArtists" && (
                          <div className="mt-4 col-start-1 col-span-8 mx-2 md:mx-0 md:col-start-2 md:col-span-6">
                            <CustomInput
                              label="Comment"
                              type="text"
                              name="comment"
                              id="comment"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.comment}
                              multiline
                              rows={2}
                            />
                          </div>
                        )}
                        <div className="flex flex-row gap-0 mt-4 lg:mt-4 col-start-1 col-span-8 mx-2 md:mx-0 md:col-start-2 md:col-span-6 xl:col-start-2 lg:col-start-2 xl:col-span-4 lg:col-span-6 mb-8">
                          <div className="sm:col-span-1 sm:flex-col sm:justify-start pr-4 lg:pr-0 lg:mb-5 md:mt-0 flex flex-1">
                            <Button
                              type="submit"
                              label={
                                props?.page !== "editArtists"
                                  ? "Accept"
                                  : "Update"
                              }
                              textsize="base"
                            />
                          </div>
                          <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 lg:mb-5 md:mt-0 flex flex-1">
                            <Button
                              type="reset"
                              label="Cancel"
                              textsize="base"
                              onClick={goToPrevPage}
                              background={"none"}
                              color={"black"}
                              bordercolor={"primary"}
                            />
                          </div>
                          <div className="lg:flex lg:flex-1" />
                        </div>
                      </div>
                      {errorMsg}
                    </form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
export default PriceComponent;
