/* eslint-disable array-callback-return */
import * as React from "react";
import { Formik } from "formik";
import Button from "components/UI/Button";
import {
  getProjectStatusByName,
  USER_ROLE_PROJECT_ADMIN,
  USER_ROLE_PROJECT_MYSTUDIO_ARTIST,
  USER_ROLE_CLIENT,
} from "../../CONSTANTS";
import moment from "moment";
import { useState } from "react";
import { Alert } from "@mui/material";

const AppointmentDates = (props) => {
  const [errorMessage, setErrorMessage] = useState("");

  // order vy date and time
  props?.userdata?.sort((a, b) => {
    let da = new Date(a.offerDate + ' ' + a.fromTime),
        db = new Date(b.offerDate + ' ' + b.fromTime);
    return da - db;
  });

  const handleFormSubmit = (values) => {
    let projectStatusId = getProjectStatusByName.Appointment_Date_Confirmed;
    // get the selected date
    let aDay = props?.userdata?.filter((rec) => rec.id === values.dayName); 
    
    if (aDay && aDay.length > 0) {      
      let pickedDate = Date.parse(aDay[0].offerDate + ' ' + aDay[0].fromTime);
      let todaysDate = Date.parse(new Date());
      if (pickedDate <= todaysDate) {
        setErrorMessage(<Alert className="m-4 " severity="error">
                        {"Selected Date has already passed. Contact Admin."}
                      </Alert>);
        return;
      }
    }

    let selectedDay = (aDay && aDay.length > 0) ? parseInt(aDay[0].day) : null;

    let studioId = null;
    props?.studioartists?.map((item) => {
      let aStudio = item.artist_studio_work_days?.filter((rec) => rec.day === selectedDay);
      if (aStudio && aStudio.length > 0) {
        studioId = item.studioId;
      }
    });
    
    let obj = {
      AppDateconfirmed: {
        counterOfferDatesId: values.dayName,
        loggedInUserId: props.loggedInUserId,
        counterOfferId: props?.userdata[0].counterOfferId,
        studioId: studioId,
        projectId: props.projectId,
        counterOfferDay:selectedDay
      },
      status: {
        statusId: projectStatusId,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  // useEffect(() => {
  //   try {
  //     console.log(props?.studioartists);
  //     console.log(props?.userdata);
  //     props?.studioartists?.map((item) => {
  //       if (item.artist_studio_work_days[0].day === props?.userdata[0].day) {
  //         setStudioId(item.studioId);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error, "AppointmentDates Error");
  //   }
  // }, [props?.studioartists, props?.userdata]);

  var daysData = [];
  props.userdata.forEach(function (value, i) {
    var index =
      daysData.length > 0
        ? daysData.map((i) => i.offerDate).indexOf(value.offerDate)
        : -1;
    if (index !== -1) {
      daysData[index].timeEntries.push({
        fromTime: value.fromTime,
        toTime: value.toTime,
      });
    } else {
      var obj = {
        day: value.day,
        timeEntries: [
          {
            fromTime: value.fromTime,
            toTime: value.toTime,
          },
        ],
        offerDate: value.offerDate,
      };
      daysData.push(obj);
    }
  });


  let divs = [];
  var sortedDaysData = daysData.sort(
    (a, b) =>
      Date.parse(new Date(a.offerDate)) - Date.parse(new Date(b.offerDate))
  );
  sortedDaysData.map((dayData, i) => {
    divs.push(
      <div key={i} className="font-sans text-base text-black">
        {moment(dayData.offerDate).format("DD-MMM-YYYY") +
          ", " +
          dayData.timeEntries
            .map(
              (timeEntry) =>
                timeEntry.fromTime.slice(0, 5) +
                " - " +
                timeEntry.toTime.slice(0, 5)
            )
            .join("; ")}
      </div>
    );
  });

  return (
    <div className="flex flex-row gap-y-2 gap-x-10 flex-wrap md:ml-4">
      {(props?.role === USER_ROLE_PROJECT_ADMIN ||
        props?.role === USER_ROLE_PROJECT_MYSTUDIO_ARTIST ||
        props?.role === "Admin") && (
        // props?.userdata.map((item, index) => (
        //   <div key={index}>
        //     <div className="my-auto">
        //       <span>{moment(item.offerDate).format("ddd DD-MMM-yyyy")}</span>
        //       <span>
        //         , {item.fromTime.substring(0, item.fromTime.length - 3)} -{" "}
        //       </span>
        //       <span>{item.toTime.substring(0, item.toTime.length - 3)}</span>
        //     </div>
        //   </div>
        // ))}
        <div>{divs}</div>
      )}
      {props?.role === USER_ROLE_CLIENT && (
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="w-full">
              {props?.userdata.map((item, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <div className="my-auto">
                    <label>
                      <input
                        type="radio"
                        name="dayName"
                        value={item.id}
                        checked={values.dayName === item.id}
                        onChange={() => setFieldValue("dayName", item.id)}
                        className="mr-2"
                      />
                      <span>
                        {moment(item.offerDate).format("ddd DD-MMM-yyyy")}
                      </span>
                      <span>
                        , {item.fromTime.substring(0, item.fromTime.length - 3)}{" "}
                        -{" "}
                      </span>
                      <span>
                        {item.toTime.substring(0, item.toTime.length - 3)}
                      </span>
                    </label>
                  </div>
                </div>
              ))}
              <div className="flex flex-row gap-0 mt-4 lg:mt-0">
                <div className="sm:col-span-1 sm:flex-col sm:justify-start md:pr-4 lg:pr-0 lg:mb-5 md:mt-5 md:mt-8 flex flex-1 w-full">
                  <Button type="submit" label={"Send"} textsize="sm" />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
       {errorMessage}
    </div>
  );
};

export default AppointmentDates;
