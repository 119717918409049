/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SupportView from "./SupportView";
import { SUPPORT_TABS } from "../../../layouts/AdminConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupportTicketsByUserTypeId,
  createSupportTicketComment,
} from "redux/actions/SupportAction";
import { getProjectsWithSupportTickets } from "redux/actions/ProjectAction";
import { USER_TYPES } from "../../../utils/constants";

const SupportContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(SUPPORT_TABS.OUR_PROJECTS.id);
  const [generalDataCount, setGeneralDataCount] = useState(0);
  const [clientDataCount, setClientDataCount] = useState(0);
  const [artistDataCount, setArtistDataCount] = useState(0);

  const dispatch = useDispatch();
  const supportTickets = useSelector((state) => state.support.data);
  const projectDataObj = useSelector((state) => state.project);

  useEffect(() => {
    if (selectedTab === SUPPORT_TABS.OUR_PROJECTS.id) {
      dispatch(getProjectsWithSupportTickets(props.loginData.id));
    } else if (selectedTab === SUPPORT_TABS.MARKET_PLACE.id) {
    } else if (selectedTab === SUPPORT_TABS.MARKED_AS_FINISHED_REPORT.id) {
    } else if (selectedTab === SUPPORT_TABS.GENERAL.id) {
     dispatch(getSupportTicketsByUserTypeId(USER_TYPES.GENERAL.id)).then((res) => {
        if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
          setGeneralDataCount(res?.payload?.length);
        }
      });      
    }  else if (selectedTab === SUPPORT_TABS.CLIENT.id) {      
      dispatch(getSupportTicketsByUserTypeId(USER_TYPES.CLIENT.id)).then((res) => {
         if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
           setClientDataCount(res?.payload?.length);
          }
       });      
    }  else if (selectedTab === SUPPORT_TABS.ARTIST.id) {
      dispatch(getSupportTicketsByUserTypeId(USER_TYPES.ARTIST.id)).then((res) => {
         if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
           setArtistDataCount(res?.payload?.length);
          }
       });            
    }
  }, [selectedTab, dispatch]);

  useEffect(() => {
    dispatch(getProjectsWithSupportTickets(props.loginData.id));
    dispatch(getSupportTicketsByUserTypeId(USER_TYPES.GENERAL.id)).then((res) => {
      if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
        setGeneralDataCount(res?.payload?.length);
      }
    });     
    dispatch(getSupportTicketsByUserTypeId(USER_TYPES.CLIENT.id)).then((res) => {
      if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
        setClientDataCount(res?.payload?.length);
       }
    }); 
    dispatch(getSupportTicketsByUserTypeId(USER_TYPES.ARTIST.id)).then((res) => {
      if (res && res.type === "GET_SUPPORTTICKETS_BY_USERTYPEID_SUCCESS") {
        setArtistDataCount(res?.payload?.length);
       }
    });   
  }, []);

  const projectOnClick = (projectId) => {
    props.history.push({
      pathname: "/admin/project-details/" + projectId,
    });
  };

  const onSubmit = (obj) => {
    dispatch(createSupportTicketComment(obj));
  };

  return (
    <SupportView
      loginData={props.loginData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      supportTickets={supportTickets}
      onSubmit={onSubmit}
      projects={projectDataObj?.dataGetSupport}
      projectOnClick={projectOnClick}
      generalDataCount={generalDataCount}
      clientDataCount={clientDataCount}
      artistDataCount={artistDataCount}
    />
  );
};

export default SupportContainer;
