/* eslint-disable eqeqeq */
import React from "react";
import moment from "moment";
import { ReactComponent as MaleIcon } from "assets/ic_male.svg";
import { ReactComponent as FemaleIcon } from "assets/ic_female.svg";
import { ReactComponent as GenderIcon } from "assets/ic_gender.svg";
import { ReactComponent as MoneyIcon } from "assets/ic_money.svg";
import { ReactComponent as ChatIcon } from "assets/ic_chat.svg";
import Rating from "@mui/material/Rating";
import { TABS } from "../../../layouts/ArtistConstants";
import  { useHistory } from 'react-router-dom';
import { SERVER_URL } from "utils/constants";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

const StudioProjects = (props) => {
  let history = useHistory();

  const getProjectStatusColor = (projectStatus) => {
    switch (projectStatus) {
      case 1:
        return "new";
      case 2:
        return "waiting";
      case 3:
        return "withdrawn";
      case 4:
        return "declined";
      case 5:
        return "done";
      case 6:
        return "waiting";
      case 7:
        return "progress";
      case 8:
        return "waiting";  
      case 13:
        return "refunded";
      default:
        return "new";
    }
  };

  
  const projectOnClick = (id) => {
    history.push("/studio/project-details/"+id);
  };

  const projectCard = (items) => {
    return (
      <div
        key={items.id}
        className="flex grid grid-col-6 gap-1 rounded-2xl shadow-lg py-6"
        onClick={() => projectOnClick(items.id)}
      >
        {/* <div className="flex flex-1 col-start-1 bg-red h-full">1</div>
        <div className="flex flex-1 col-start-2 col-span-3 bg-primary h-full">2</div>
        <div className="flex flex-1 col-start-5 bg-projectNew h-full">3</div> */}
        <div className="flex flex-1 col-start-1 h-full items-start mr-2 flex-row">
          <div
            className={`w-3 bg-${getProjectStatusColor(items.projectStatusId)} h-1/3`}
            hidden={props.selectedTab == 1}
          />
          <div className="flex flex-1 col-start-1 h-full items-end mr-2 flex-col">
            <div className="text-tertiary text-base font-sans">
              <br></br>{" "}
            </div>
            <img
              className="h-12 w-12 bg-gray-300 rounded-full"
              src={
                items?.user?.photoUrl
                  ? SERVER_URL + items?.user?.photoUrl
                  : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
              }
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 col-start-2 col-span-3 h-full flex-col">
          <span className="text-tertiary text-base font-sans">#{items.id}</span>
          <span className="">
            <div className="flex flex-row items-center">
              <span className="text-secondary text-base font-sans font-normal mr-2">
                {items?.user?.firstName} {items?.user?.lastName}
              </span>
              {items?.user?.gender === "female" ? (
                <FemaleIcon />
              ) : items?.user?.gender === "male" ? (
                <MaleIcon />
              ) : (
                <GenderIcon />
              )}
            </div>
          </span>
          <span className="text-secondary text-sm font-sans font-semibold mr-2">
            {items.clientBirthYear}
          </span>
          <div className="text-sm mt-2 mb-3 text-tertiary">
            {moment(items.createdAt).format("D MMM, HH:mm")}
          </div>
          <div className="flex flex-row items-center mb-5">
            <div hidden={props.selectedTab == TABS.PROJECTHISTORY.id} 
              className={`border-3 rounded-full w-4 h-4 border-${getProjectStatusColor(
                items.projectStatusId
              )} mr-2`}               
            />
            <div
              hidden={props.selectedTab == TABS.CURRENTPROJECTS.id}
              className="font-bold text-secondary font-sans text-base"
            />
              {items.project_status?.studioStatusName}
          </div>
          <div className="text-base font-sans flex flex-row items-center -mr-10 md:mr-0">
            <MoneyIcon className="mr-2" /> 
            {/* Price:{" "} */}
            <div className="font-bold ml-1">
              {" "}
              {/* {items?.offerPrice ? items?.offerPrice : items?.counter_offers[0]?.offerPrice ? items?.counter_offers[0]?.offerPrice : items?.budgetPriceFrom - items?.budgetPriceTo } */}
              {items?.offerPrice ? "Price: " + items?.offerPrice : items?.counter_offers && items?.counter_offers[0]?.offerPrice ? "Price: " + items?.counter_offers[0]?.offerPrice : `Budget: ${items?.budgetPriceFrom} - ${items?.budgetPriceTo}`  }
            </div>
          </div>
          {items?.finalArtist &&             
          <div className="flex flex-row items-center">
            <img
            className="h-12 w-12 bg-gray-300 rounded-full"
            src={
              items?.finalArtist?.photoUrl
                ? SERVER_URL + items?.finalArtist?.photoUrl
                : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
            }
            alt=""
          />
              <span className="text-secondary text-base font-sans font-normal mr-2">
                {items?.finalArtist?.firstName} {items?.finalArtist?.lastName}
              </span><div className="text-sm mt-2 mb-3 text-tertiary">{"Artist"}</div> 
          </div>
          }
          {items?.counter_offers?.length > 0 && props.selectedTab === TABS.CURRENTPROJECTS.id && !(items?.finalArtist) &&
            items?.counter_offers?.map((rec) => {                  
                return <img className="h-12 w-12 bg-gray-300 rounded-full"
                  src={rec?.user?.photoUrl? SERVER_URL + rec?.user?.photoUrl : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"}
                  alt={items?.finalArtist?.firstName}
                />
            })
          }project_status
          {(props.selectedTab === TABS.PROJECTHISTORY.id) ?     
                (items?.artist_ratings?.length > 0) ? <StyledRating name="simple-controlled" value={items?.artist_ratings[0]?.rating} color="primary"/> : <div className="text-sm mt-2 mb-3 text-tertiary">{"Unrated"}</div>
              : ''}
        </div>
        <div className="flex flex-1 col-start-5 h-full">
          <div className="flex flex-row items-start">
            {items.projectStatusId == 1 ? (
              <span className="px-3 py-1 bg-primary rounded-lg text-white text-sm font-bold">
                {items.project_status.statusName}
              </span>
            ) : (
              <div className="flex items-center">
                <div className="mr-2 font-bold text-base">
                  {items?.project_comments ? items?.project_comments?.length : 0}
                </div>
                <ChatIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-16">
      <div className="flex flex-col bg-white w-full px-0">
        <section className="mt-8 pb-12" aria-labelledby="gallery-heading">
          <ul className="grid grid-cols-1 md:gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            {props?.data?.map((items) => projectCard(items))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default StudioProjects;

