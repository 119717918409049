/* eslint-disable no-unused-vars */
import * as THREE from "three";
import { Pane } from "tweakpane";

export const Body = (body) => {
  document.getElementById("tweakpanecontainer").innerHTML = null;
  
  const pane = new Pane({
    container: document.getElementById("tweakpanecontainer"),
  });
  const Input = (name, index, min, max, inputPane, page) => {
    inputPane.pages[page].addInput(body.skeleton.bones[index].rotation, "x", {
      label: name,
      min: min,
      max: max,
      step: 0.1,
    });
  };
  const NormalInput = (name, index, min, max, inputPane, axis) => {
    inputPane.addInput(body.skeleton.bones[index].rotation, axis, {
      label: name,
      min: min,
      max: max,
      step: 0.1,
    });
  };

  const tab = pane.addTab({
    pages: [
      { title: "Head" },
      { title: "Back" },
      { title: "Leg" },
      { title: "Arm" },
    ],
  });

  const tabItems = document.querySelectorAll(
    ".mobile-customization > div > div > div > div > .tp-tbiv"
  );
  const tabContents = document.querySelectorAll(
    ".mobile-customization > div > div > div > div > .tp-brkv"
  );

  tabItems.forEach((item, index) => {
    item.addEventListener("click", () => {
      // Remove active class from all tab items
      tabItems.forEach((item) => {
        item.classList.remove("active");
      });

      // Remove active class from all tab contents
      tabContents.forEach((content) => {
        content.classList.remove("active");
      });

      // Add active class to the clicked tab item
      item.classList.add("active");

      // Show the corresponding tab content
      tabContents[index].classList.add("active");
    });
  });

  tabContents.forEach((content) => {
    content.classList.add("initial-hide");
  });

  //======================Tab=========================//
  const armgroup = tab.pages[3].addTab({
    pages: [{ title: "Left Arm" }, { title: "Right Arm" }],
  });
  const leggroup = tab.pages[2].addTab({
    pages: [{ title: "Left Leg" }, { title: "Right Leg" }],
  });
  // //======================Left Leg=========================//
  Input("Front-Back", 60, -Math.PI * 0.5, 0, leggroup, 1);
  Input("Knee Bend", 61, -Math.PI * 0.7, 0, leggroup, 1);
  Input("Ankle Bend", 62, 0, Math.PI * 0.4, leggroup, 1);

  // //======================Right Leg=========================//
  Input("Front-Back", 55, -Math.PI * 0.5, 0, leggroup, 0);
  Input("Knee Bend", 56, -Math.PI * 0.7, 0, leggroup, 0);
  Input("Ankle Bend", 57, 0, Math.PI * 0.4, leggroup, 0);
  // //======================Left Arm=========================//
  Input("Front-Back", 31, 0, Math.PI * 0.5, armgroup, 1);
  Input("Sideways", 32, -Math.PI * 0.1, Math.PI * 0.3, armgroup, 1);
  Input("Elbow Bend", 33, 0, Math.PI * 0.5, armgroup, 1);
  Input("Wrist Bend", 34, -Math.PI * 0.2, Math.PI * 0.3, armgroup, 1);
  // //======================Right Arm=========================//
  Input("Front-Back", 7, 0, Math.PI * 0.5, armgroup, 0);
  Input("Sideways", 8, -Math.PI * 0.1, Math.PI * 0.3, armgroup, 0);
  Input("Elbow Bend", 9, 0, Math.PI * 0.5, armgroup, 0);
  Input("Wrist Bend", 10, -Math.PI * 0.2, Math.PI * 0.3, armgroup, 0);
  //======================Head=========================//
  NormalInput("Sideways", 5, -Math.PI * 0.3, Math.PI * 0.3, tab.pages[0], "y");
  NormalInput("Up-Down", 5, -Math.PI * 0.2, Math.PI * 0.2, tab.pages[0], "x");
  // //======================Back=========================//
  NormalInput("Front-Back", 1, 0, Math.PI * 0.5, tab.pages[1], "x");
};
