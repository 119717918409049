import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as ArrowRight } from "../assets/ico_arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../assets/ico_arrow_left.svg";
import { ReactComponent as Add } from "../assets/ic_add_gray.svg";
import { SERVER_URL } from "utils/constants";
import { VIEWS } from "layouts/ArtistConstants";

const zoomedImageContainer = {
  position: "fixed",
  top: "9%",
  left: "5%",
  width: "90%",
  height: "85%",
  "background-color": "rgba(0, 0, 0, 0.9)",
  display: "flex",
  "justify-content": "center",
  "align-items": "center",
  "z-index": 100,
  "border-width": "3px",
  "border-color": "#c7a33f",
  "border-radius": "14px",
  overflow: "hidden",
};

export default function ImageCarouselSlider({
  images,
  setImageComment,
  artistPortfolioId,
  isTmpArtistPortfolioId,
  openLink,
  comments,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomedImage, setZoomedImage] = useState(null);

  useEffect(() => {
    setImageComment(
      images[0].comment
        ? images[0].comment
        : comments?.length > 0
        ? comments[0]
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    let index = currentSlide + 1;
    setCurrentSlide(index);
    if (index < images.length) {
      if (images.length > 0 && images[0].hasOwnProperty("comment")) {
        setImageComment(images[index].comment ? images[index].comment : "");
      } else {
        setImageComment(comments[index]);
      }
    }
  };

  const prev = () => {
    let index = currentSlide - 1;
    setCurrentSlide(currentSlide - 1);

    if (index >= 0) {
      if (images.length > 0 && images[0].hasOwnProperty("comment")) {
        setImageComment(images[index].comment ? images[index].comment : "");
      } else {
        setImageComment(comments[index]);
      }
    }
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
      if (images.length > 0 && images[0].hasOwnProperty("comment")) {
        setImageComment(images[index].comment ? images[index].comment : "");
      } else {
        setImageComment(comments[index]);
      }
    }
  };

  const openZoomedImage = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  return (
    <div className="flex flex-cols grid grid-cols-12 items-end justify-center w-full">
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={currentSlide}
        onChange={(e) => updateCurrentSlide(e)}
        className="col-start-1 col-span-12 justify-center w-full"
        renderThumbs={(children) =>
          children.map((item) => {
            return (
              <img
                key={item.key}
                src={item?.props?.children?.props?.src}
                className="h-8 w-8 md:h-20 md:w-20 object-cover object-center"
                alt="Carousel"
              />
            );
          })
        }
      >
        {images.length !== 0 &&
          images.map((image) => (
            <div
              key={image.id}
              className="border-primary border-3 overflow-hidden rounded-lg"
              onClick={() =>
                openZoomedImage(
                  image.imageUrl
                    ? `${SERVER_URL}` + image.imageUrl
                    : URL.createObjectURL(image)
                )
              }
            >
              <img
                src={
                  image.imageUrl
                    ? `${SERVER_URL}` + image.imageUrl
                    : URL.createObjectURL(image)
                }
                className="flex max-h-96 lg:max-h-100 2xl:max-h-120 object-cover object-center"
                alt="Carousel"
              />
            </div>
          ))}
      </Carousel>
      {zoomedImage && (
        <div style={zoomedImageContainer} onClick={closeZoomedImage}>
          <img
            src={zoomedImage}
            alt="zoomed"
            className="object-contain object-center h-full w-auto"
          />
        </div>
      )}

      <div className="flex flex-row col-start-1 col-span-12 grid grid-cols-8 justify-center items-center">
        <div
          className="flex bg-primary rounded-md w-8 h-8 justify-center items-center col-start-1"
          onClick={() => prev()}
        >
          <ArrowLeft />
        </div>

        <div className="col-start-8 flex justify-end flex-row gap-1">
          {openLink && (
            <div className="col-start-7 flex justify-end">
              <a
                className="border border-tertiary w-8 h-8 border-dashed mb-0 flex items-center justify-center"
                href={`/artist/artistportfolio/${VIEWS.PREVIEW_ARTIST_PORTFOLIO.id}/${artistPortfolioId}/${isTmpArtistPortfolioId}`}
              >
                <Add />
              </a>
            </div>
          )}
          <div className="col-start-8 flex justify-end">
            <div
              className="flex bg-primary rounded-md w-8 h-8 justify-center items-center  md:ml-0"
              onClick={() => next()}
            >
              <ArrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
