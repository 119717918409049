/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useRef } from "react";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import { getProjectStatusByName } from "../../CONSTANTS";
import Button from "components/UI/Button";
import { getDaysByInt } from "utils/constants";
import TimeList from "./../../common/multipleTimeCreate";
import DatePicker from "react-datepicker";
import CheckBox from "components/UI/CheckBox";
import { ReactComponent as Calendar } from "../../../../assets/ic_calendar.svg";
import "../styles/datePicker.css";
import { Alert } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ReactComponent as AddButton } from "../../../../assets/ic_button_add.svg";
import { ReactComponent as RemoveButton } from "../../../../assets/ic_button_remove.svg";

const AdminDateTimeOffer = (props) => {

  const [adminDateOffer, setAdminDateOffer] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const isAppEdit = useRef(false);

  const getDateForAWeekday = (day) => {
    var now = new Date();
    now.setDate(now.getDate() + ((day + (7 - now.getDay())) % 7));
    return now;
  };

  useEffect(() => {
    // New Entries
    let adminPreferedDateTime = [];

    let artistWorkingOn = [];
    if (props?.page !== "editDate") {      
      props?.artistWorkDays?.map((studioArtists) =>
        studioArtists?.artist_studio_work_days?.map((artistWorkDay) => {
          let a = props.clientProposedDates.filter((item) => item.day === artistWorkDay.day);    
          if (a && a.length > 0) {
            artistWorkingOn.push(a[0]);
          } 
          //else {
            // artistWorkingOn.push({day: artistWorkDay.day, fromTime: '00:00:00', toTime: '00:00:00'})
          //}
        }          
        )
      );
    }    

    let proposedDates =
      props?.page === "editDate"
        ? props.dates && props.dates.length > 0
          ? props.dates
          : []
        : artistWorkingOn;

    isAppEdit.current = props?.page === "editDate";
    proposedDates.sort((a, b) => parseInt(a.day) - parseInt(b.day));  
    proposedDates.map((clientProposedDay, index) => {
      // // Check if Client Has proposed the day and if the studio is also open
      // if (
      //   (clientProposedDay.day == 1 && props?.userdata?.isMonday) ||
      //   (clientProposedDay.day == 2 && props?.userdata?.isTuesday) ||
      //   (clientProposedDay.day == 3 && props?.userdata?.isWednesday) ||
      //   (clientProposedDay.day == 4 && props?.userdata?.isThursday) ||
      //   (clientProposedDay.day == 5 && props?.userdata?.isFriday) ||
      //   (clientProposedDay.day == 6 && props?.userdata?.isSaturday) ||
      //   (clientProposedDay.day == 7 && props?.userdata?.isSunday)
      // ) {
        var indexFound = -1;
        var dayIndexFound = -1;

        // Compare dates in Edit Mode Insead of Day
        if (isAppEdit.current) {
          indexFound =
            adminPreferedDateTime.length > 0
              ? adminPreferedDateTime
                  .map((i) => moment(i.date).format("YYYY-MM-DD"))
                  .indexOf(clientProposedDay.offerDate)
              : -1;
            
          dayIndexFound =
            adminPreferedDateTime.length > 0
              ? adminPreferedDateTime
                  .map((i) => i.day)
                  .indexOf(clientProposedDay.day)
              : -1;
        } else {
          indexFound =
            adminPreferedDateTime.length > 0
              ? adminPreferedDateTime
                  .map((i) => i.day)
                  .indexOf(clientProposedDay.day)
              : -1;
        }
        
        // Append time entry to existing record if day entry is present
        if (indexFound !== -1) {
          adminPreferedDateTime[indexFound].timeEntries.push({
            fromTime: clientProposedDay.fromTime,
            toTime: clientProposedDay.toTime,
            id: isAppEdit.current ? clientProposedDay.id : index,
            isDeleted: isAppEdit.current ? clientProposedDay.isDeleted : false,
          });
        } else {
          adminPreferedDateTime.push({
            day: clientProposedDay.day,            
            timeEntries: [
              {
                fromTime: clientProposedDay.fromTime,
                toTime: clientProposedDay.toTime,
                id: isAppEdit.current ? clientProposedDay.id : index,
                isDeleted: isAppEdit.current
                  ? clientProposedDay.isDeleted
                  : false,
              },
            ],
            date: isAppEdit.current
              ? moment(clientProposedDay.offerDate, "YYYY-MM-DD").toDate()
              : getDateForAWeekday(clientProposedDay.day),
            name: getDaysByInt(
              isAppEdit.current
                ? parseInt(clientProposedDay.day)
                : clientProposedDay.day
            ),
            
            selected: props?.page === "editDate" ? true : false,
            hide: ((props?.page === "editDate") && (dayIndexFound !== -1)) ? true : false,
            textHide: false,
            counterOfferId:
              props?.page === "editDate"
                ? clientProposedDay.counterOfferId
                : null,
          });
        }
      // }
    });

    setAdminDateOffer(adminPreferedDateTime);
  }, []);

  const handleFormSubmit = (values) => {  
    let dates = [];
    setErrorMsg('');
    let filteredData = adminDateOffer.filter(
      (rec) => {
        return rec.selected === true;
      }
    );

    // find duplicate dates edit section 
    if (props?.page === "editDate") {
      let arrDuplicate = [];
      let aTimeError = 0;
      let aPassedDateError = 0;
      let todaysDate = Date.parse(new Date());
      filteredData.map((o) => {
        if (o.timeEntries && o.timeEntries.length > 0) {
          let dt = moment(o.date).format("DD-MMM-yyyy");        
          o.timeEntries.map((t) => {
            if (!t.isDeleted) {
              let a = moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") + moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss");
              arrDuplicate[a] = arrDuplicate[a] ? arrDuplicate[a] + 1 : 1;
              if (moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") >= moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss")) {
                aTimeError = aTimeError + 1;
              }
              let pickedDate = Date.parse(dt + ' ' + t.fromTime);
              if (pickedDate <= todaysDate) {
                aPassedDateError = aPassedDateError + 1;
              }
            }          
          });   
        }
      }); 
      for (var x in arrDuplicate) {
        if (arrDuplicate[x] > 1) {
          setErrorMsg(
            <Alert className="m-4 " severity="error">
              {"Duplicate dates found."}
            </Alert>
          );
          return;
        }
      }

      if (aTimeError > 0) {
        setErrorMsg(
          <Alert className="m-4 " severity="error">
            {"To time should be greater than from time."}
          </Alert>
        );
        return;
      }
      if (aPassedDateError > 0) {
        setErrorMsg(<Alert className="m-4 " severity="error">
                        {"Selected Date has already passed. Choose a different date."}
                      </Alert>);
        return;
      }
    }

    if (props?.page === "" && props?.addNewDate) {
      let arrDuplicate = [];
      let aTimeError = 0;
      let aPassedDateError = 0;
      let todaysDate = Date.parse(new Date());
      props?.dates.map((t) => {        
          let a = moment( t.offerDate + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") + moment( t.offerDate + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss");
          arrDuplicate[a] = arrDuplicate[a] ? arrDuplicate[a] + 1 : 1;          
      });  

      filteredData.map((o) => {
        if (o.timeEntries && o.timeEntries.length > 0) {
          let dt = moment(o.date).format("yyyy-MM-DD");        
          o.timeEntries.map((t) => {
            if (!t.isDeleted) {
              let a = moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") + moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss");
              arrDuplicate[a] = arrDuplicate[a] ? arrDuplicate[a] + 1 : 1;
              if (moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") >= moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss")) {
                aTimeError = aTimeError + 1;
              }
              let pickedDate = Date.parse(dt + ' ' + t.fromTime);
              if (pickedDate <= todaysDate) {
                aPassedDateError = aPassedDateError + 1;
              }
            }          
          });   
        }
      });
      for (var x in arrDuplicate) {
        if (arrDuplicate[x] > 1) {
          setErrorMsg(
            <Alert className="m-4 " severity="error">
              {"Duplicate dates found."}
            </Alert>
          );
          return;
        }
      }
      if (aTimeError > 0) {
        setErrorMsg(
          <Alert className="m-4 " severity="error">
            {"To time should be greater than from time."}
          </Alert>
        );
        return;
      }
      if (aPassedDateError > 0) {
        setErrorMsg(<Alert className="m-4 " severity="error">
                        {"Selected Date has already passed. Choose a different date."}
                      </Alert>);
        return;
      }
    }

    if (!props?.page && !props?.addNewDate) {
      let arrDuplicate = [];
      let aTimeError = 0;
      let aPassedDateError = 0;
      let todaysDate = Date.parse(new Date());
      filteredData.map((o) => {
        if (o.timeEntries && o.timeEntries.length > 0) {
          let dt = moment(o.date).format("yyyy-MM-DD");        
          o.timeEntries.map((t) => {
            if (!t.isDeleted) {
              let a = moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") + moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss");
              arrDuplicate[a] = arrDuplicate[a] ? arrDuplicate[a] + 1 : 1;
              if (moment( dt + ' ' + t.fromTime).format("DD-MM-yyyy HH:mm:ss") >= moment( dt + ' ' + t.toTime).format("DD-MM-yyyy HH:mm:ss")) {
                aTimeError = aTimeError + 1;
              }
              let pickedDate = Date.parse(dt + ' ' + t.fromTime);
              if (pickedDate <= todaysDate) {
                aPassedDateError = aPassedDateError + 1;
              }
            }          
          });   
        }
      });
      for (var x in arrDuplicate) {
        if (arrDuplicate[x] > 1) {
          setErrorMsg(
            <Alert className="m-4 " severity="error">
              {"Duplicate dates found."}
            </Alert>
          );
          return;
        }
      }
      if (aTimeError > 0) {
        setErrorMsg(
          <Alert className="m-4 " severity="error">
            {"To time should be greater than from time."}
          </Alert>
        );
        return;
      }
      if (aPassedDateError > 0) {
        setErrorMsg(<Alert className="m-4 " severity="error">
                        {"Selected Date has already passed. Choose a different date."}
                      </Alert>);
        return;
      }
    }
    
     
    const allDates = filteredData.map(obj => obj.date);
    const setOfDates = new Set(allDates);
    if (setOfDates.size < allDates.length) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Duplicate dates found."}
        </Alert>
      );
      return;
    }

    adminDateOffer.map((adminDateOfferEntry) => {
      if (adminDateOfferEntry.selected === true) {
        adminDateOfferEntry.timeEntries.map((timeEntry) => {
          if (!timeEntry.isDeleted || timeEntry.isDeleted === false) {
            let dayObj = {
              day: adminDateOfferEntry.day,
              fromTime: timeEntry.fromTime,
              toTime: timeEntry.toTime,
              offerDate: moment(adminDateOfferEntry.date).format("YYYY-MM-DD"),
              id: timeEntry.id,
              isDeleted: timeEntry.isDeleted ?? false,
            };
            dates.push(dayObj);
          }
        });
      }
    });

    if (dates && dates.length <= 0) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Please select atleast one day."}
        </Alert>
      );
      return;
    }

    let obj;
    if (isAppEdit.current) {
      obj = {
        confirmedDateEdit: {
          confirmedDate: dates,
          counterOfferDtId: props.dates[0].counterOfferId,
          updatedBy: props.loggedInUserId,
        },
      };
    } else {
      let projectStatusId =
        getProjectStatusByName.Appointment_Date_Confirmation;

      obj = {
        counterOfferDatesByAdmin: {
          counterOfferDates: JSON.stringify(dates),
          artistId: props.artistId,
          createdBy: props.loggedInUserId,
          statusId: projectStatusId,
          addNewDate: props.addNewDate,
        },
        status: {
          statusId: projectStatusId,
          loggedInUserId: props.loggedInUserId,
          addNewDate: props.addNewDate,
        },
      };
    }

    props.onSubmit(obj);
    props.setOpen ?? props.setOpen(false);
  };

  const formHandleChange = (e) => {
  };

  const addNewRow = (selectedDay, index) => {
    const cloneAdminDateOffer = Object.assign([], adminDateOffer, {test : true});    
    let res = [];
    cloneAdminDateOffer.map((e, i) => {    
      let result = e.timeEntries.map(({ id }) => id);
      res.push(Math.max(...result));
    });
    let newIndex = Math.max(...res) + 1;

    const nextAdminDateOffer = cloneAdminDateOffer.map((item1, ind) => {
      const cloneTimeEntry = Object.assign([], item1);
      if (ind == index && cloneTimeEntry.date == selectedDay.date && cloneTimeEntry.day == selectedDay.day) {   
        const timeEntry = Object.assign([], cloneTimeEntry.timeEntries);
        timeEntry.push({
          fromTime: "00:00:00",
          toTime: "00:00:00",
          id: newIndex,
          isDeleted: false
        });
        cloneTimeEntry.timeEntries = timeEntry;
        return cloneTimeEntry;
      } else {
        // The rest haven't changed
        return cloneTimeEntry;
      }
    });

    // console.log('nextAdminDateOffer', nextAdminDateOffer);

    setAdminDateOffer(nextAdminDateOffer);
  };

  const addDateNewRow = (selectedDay) => {
    const newSelectedDay = Object.assign({}, selectedDay, { hide: true, textHide: false });
    if (newSelectedDay && newSelectedDay.timeEntries.length > 1) {
      const timeEntry = Object.assign([], newSelectedDay.timeEntries);
      timeEntry.length = 1;
      newSelectedDay.timeEntries = timeEntry;
    }
    adminDateOffer.push(newSelectedDay);
    adminDateOffer.sort((a, b) => parseInt(a.day) - parseInt(b.day));
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const deleteDateRow = (index) => {
    const cloneAdminDateOfferTmp = Object.assign([], adminDateOffer);
    cloneAdminDateOfferTmp.splice(index, 1);
    setAdminDateOffer(cloneAdminDateOfferTmp);
  };

  const clickOnDelete = (selectedDay, timeEntryId) => {
    adminDateOffer.map((item1) => {
      if (item1.date === selectedDay.date) {
        item1.timeEntries.map((r) => {
          if (r.id === timeEntryId) {
            r.isDeleted = true;
          }
        });
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const updateFromTime = (selectedDay, timeEntryId, value) => {
    adminDateOffer.map((item1) => {
      if (item1.date === selectedDay.date) {
        item1.timeEntries.map((r) => {
          if (r.id === timeEntryId) {
            r.fromTime = value;
          }
        });
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const updateToTime = (selectedDay, timeEntryId, value) => {
    adminDateOffer.map((item1) => {
      if (item1.date === selectedDay.date) {
        item1.timeEntries.map((r) => {
          if (r.id === timeEntryId) {
            r.toTime = value;
          }
        });
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const selectDate = (e, item) => {
    let show = false;
    adminDateOffer.map((item1) => {
    if (item1.day === item.day) {
      item1.selected = !item1.selected;
      item1.textHide = show;
      show = !item.selected;
    }
    // check changed on day not on date // 18/01/23
    //   if (item1.date === item.date) {
    //     item1.selected = !item1.selected;
    //   }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const handleDatePickerNew = (value, item, index) => {
    item.date = value;

    // const upd_obj = adminDateOffer.map((item1, ind) => {
    //   console.log(index + '===' + ind)
    //   if (index === ind) {
    //     console.log(adminDateOffer[index]);
    //     item1.date = value;
    //   }
    //     return item1;
      
    // });
    // console.log('upd_obj', upd_obj);
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="w-full py-2 border border-primary rounded-md flex flex-row px-4"
      onClick={onClick}
      ref={ref}
    >
      <button type="button" className="text-base mr-4">
        {value}
      </button>
      <div className="flex flex-1" />
      <Calendar />
    </div>
  ));

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} onChange={formHandleChange}>
            <div className="px-0">
              <div className="mt-7">
                <div className="flex flex-row gap-y-6 gap-x-10 flex-wrap w-full">
                  {adminDateOffer.map((item, index) => (
                    <div key={index} className="w-full">
                      <div className="my-auto mb-3" hidden={item.hide}>
                        <CheckBox
                          type="checkbox"
                          label={item.name}
                          onChange={(e) => selectDate(e, item)}
                          width={"5"}
                          checked={item.selected ? true : false}
                          value={item.day}
                        />                                                                          
                      </div>
                      {/* {item['dateDetails'].map((item0, index0) => ( */}
                        {/* <div> */}
                          <div className="flex flex-row text-xl w-full">
                            <div className="text-gray flex flex-row" hidden={item.textHide}>
                              <DatePicker
                                onChange={(e) => handleDatePickerNew(e, item, index)}
                                selected={item.date}
                                minDate={new Date()}
                                calendarIcon={<Calendar />}
                                className={`w-full py-2 border border-primary rounded-md`}
                                dateFormat="dd.M.yyyy"
                                clearIcon={null}
                                customInput={<CustomInput />}
                                filterDate={(date) => {
                                  const day = date.getDay();
                                  return day == item.day;
                                }}
                              />
                              <div hidden={!(item.hide)} className="ml-4">
                              <button 
                              type="button"
                              className="flex btn btn-danger text-center"
                              onClick={() => deleteDateRow(index)}
                            >
                              <RemoveButton className="h-10 w-10" />
                            </button>
                            </div>
                            </div>
                            {/* <div className="flex md:justify-center items-center"> */}
                                {item.selected && index === 0 && props?.page !== "editDate" && (
                                  <button
                                    onClick={() => addDateNewRow(item)}
                                    type="button"
                                    className="flex btn btn-primary text-center ml-4"
                                  >
                                    <AddButton className="h-10 w-10"  />
                                  </button>
                                )}
                            {/* </div> */}
                          </div>
                          <div className="text-primary my-auto ml-0 text-xl">
                            {item.selected || props.calledFromModel ? (
                              <TimeList
                                add={(e) => {
                                  addNewRow(item, index);
                                }}
                                delete={clickOnDelete.bind(this)}
                                hide={isAppEdit.current}
                                adminSelectedDate={item}
                                updateFromTime={updateFromTime.bind(this)}
                                updateToTime={updateToTime.bind(this)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        {/* </div> */}
                      {/* ))} */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-8">
                <div className="flex flex-row gap-0 mt-4 lg:mt-0">
                  <div className="sm:col-span-1 sm:flex-col sm:justify-start pr-4 lg:pr-0 lg:mb-5 md:mt-5 md:mt-8 flex flex-1 ">
                    {props?.page === "editDate" ? (
                      <Button type="submit" label="Update" textsize="base" />
                    ) : (
                      <Button type="submit" label={"Submit"} textsize="base" />
                    )}
                  </div>
                  <div className="lg:flex lg:flex-1" />
                </div>
              </div>
            </div>
            {errorMsg}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdminDateTimeOffer;
