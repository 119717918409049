const Country = (props) => {
  return (
    <div className=" border border-primary rounded-2xl overflow-hidden mt-4 px-3 py-2">
      <label
        htmlFor="name"
        className="block text-xs font-medium text-primary font-sans"
      >
        Phone Number
      </label>
      <div className="flex flex-row">
        <select
          className="bg-white font-sans appearance-none focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent"
          id="phoneCode"
          name="phoneCode"
          value={props.phonecode}
          onChange={props.onChange}
          onBlur={props.onBlur}
        >
          {props.phonecodeoptions}
        </select>

        <input
          {...props}
          type="text"
          name="phone"
          id="phone"
          value={props.phone}
          onChange={props.onChange}
          onBlur={props.onBlur}
          className="font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
        />
      </div>
    </div>
  );
};

export default Country;
