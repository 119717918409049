/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import CheckBox from "components/UI/CheckBox";
import SideNavBar from "components/Navbars/SideNavBar";
import TopNavBar from "components/Navbars/TopNavBar";
import PreviewImage from "components/PreviewImage";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "layouts/AdminConstants";
import { ReactComponent as DefaultPic } from "../../assets/ico_default_pic.svg";
import { ReactComponent as DropdownArrow } from "../../assets/ico_dropdown_arrow.svg";
import { IMAGE_SIZE } from "utils/constants";
import Dropdown from "views/landing/support/components/Dropdown";
import Country from "components/UI/Country";
import { Alert } from "@mui/material";
import { SERVER_URL } from "utils/constants";

const saveStudioValidationSchema = yup.object({
  placeName: yup
    .string()
    .max(40, ({ max }) => "Studio Name can be of maximum 40 characters long")
    .required("Studio Name is required"),
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
  address: yup
    .string()
    .max(100, ({ max }) => "Address can be of maximum 100 characters long")
    .required("Address is required"),
  zipcode: yup
    .string()
    .matches(/^[0-9]*$/, "Zip Code should be a number")
    .required(`Zip Code is required`),
  city: yup
    .string()
    .required("City is required")
    .test("alphabets", "City must contain valid characters", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  country: yup
    .string()
    .required("Country is required")
    .test("alphabets", "Country must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  studioImage: yup
    .mixed()
    .test(
      "fileSize",
      "File Size is too large",
      (value) =>
        value === undefined ||
        value === null ||
        value === "" ||
        typeof value === "string" ||
        (value && value.size <= IMAGE_SIZE)
    ),
  // .test('fileSize', "File Size is too large", (value) => value === null || typeof(value) === 'string' || (value && value .size <= 1000000)),
  // .test('fileType', "Unsupported File Format", value => SUPPORTED_FORMATS.includes(['image/*']) )
});

export default function SaveStudioView(props) {
  const [picEdited, setPicEdited] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };
  const [countryOptions, setCountryOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    setErrorMsg("");
    if (props.error?.message) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {props.error?.message?.error?.message}
        </Alert>
      );
      hideMessage();
    }
  }, [props.error]);

  const hideMessage = () => {
    setTimeout(() => {
      setErrorMsg("");
    }, 20000);
  };

  const handleFormSubmit = (values) => {
    setErrorMsg("");
    const hasVal = Object.values(values.studioWorkDays).includes(true);
    if (!hasVal) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Select atleast one work day."}
        </Alert>
      );
      return;
    }
    const obj = new FormData();
    obj.append("studioImage", values.studioImage);
    obj.append("placeName", values.placeName);
    obj.append("phoneNumber", values.phone);
    obj.append("street", values.address);
    obj.append("zipcode", values.zipcode);
    obj.append("city", values.city);
    obj.append("country", values.country);
    obj.append("phoneCode", values.phoneCode);
    obj.append("studioTypeId", props.selectedTab === "2" ? "1" : "2");
    if (props.selectedTab === "2") {
      //Studio Tab.
      obj.append("studioWorkDays", JSON.stringify(values.studioWorkDays));
    }
    if (props.selectedTab === "4") {
      //Partner Studio Tab.
      obj.append("marketPlaceDecisionId", values.marketPlaceDecisionId);
    }

    props.onSubmit(obj);
  };

  let optionItems;
  let marketPlaceDecisions = props.marketPlaceDecision;
  if (marketPlaceDecisions && marketPlaceDecisions.length > 0) {
    optionItems = marketPlaceDecisions.map((marketPlaceDecision) => (
      <option key={marketPlaceDecision.id} value={marketPlaceDecision.id}>
        {marketPlaceDecision.name}
      </option>
    ));
  }

  useEffect(() => {
    if (props?.studio?.imageUrl) {
      setPicEdited(true);
    }
  }, [props.studio]);

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ));
    }
    setCountryOptions(countryOptionItems);
  };

  const populatePhoneCode = () => {
    let countries = props.countries;
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  useEffect(() => {
    populateCountry();
    populatePhoneCode();
  }, [props.countries]);

  let studio = props.studio;
  let intialFormValues;
  if (studio) {
    intialFormValues = {
      studioImage: studio.imageUrl ? SERVER_URL + studio.imageUrl : null, //TODO: Show studio.imageUrl on edit.
      placeName: studio.placeName,
      phone: studio.phoneNumber,
      address: studio.street,
      zipcode: studio.zipcode,
      city: studio.city,
      country: studio.country,
      phoneCode: studio.phoneCode,
    };

    if (props.selectedTab === "2") {
      //Studio Tab.
      intialFormValues.studioWorkDays = {
        isMonday: studio.studio_work_day
          ? studio.studio_work_day.isMonday
          : false,
        isTuesday: studio.studio_work_day
          ? studio.studio_work_day.isTuesday
          : false,
        isWednesday: studio.studio_work_day
          ? studio.studio_work_day.isWednesday
          : false,
        isThursday: studio.studio_work_day
          ? studio.studio_work_day.isThursday
          : false,
        isFriday: studio.studio_work_day
          ? studio.studio_work_day.isFriday
          : false,
        isSaturday: studio.studio_work_day
          ? studio.studio_work_day.isSaturday
          : false,
        isSunday: studio.studio_work_day
          ? studio.studio_work_day.isSunday
          : false,
      };
    }

    if (props.selectedTab === "4") {
      //Partner Studio Tab.
      intialFormValues.marketPlaceDecisionId = studio.marketPlaceDecisionId;
    }
  } else {
    intialFormValues = {
      studioImage: null,
      placeName: "",
      phone: "",
      address: "",
      zipcode: "",
      city: "",
      country: props.countries ? props.countries[0].name : "Switzerland",
      phoneCode: "+41",
    };

    if (props.selectedTab === "2") {
      //Studio Tab.
      intialFormValues.studioWorkDays = {
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
        isSunday: false,
      };
    }

    if (props.selectedTab === "4") {
      //Partner Studio Tab.
      intialFormValues.marketPlaceDecisionId = "1"; //By default set marketPlaceDecision as 'Publishing'
    }
  }

  let title;
  if (props.selectedTab === "2") {
    title = props.selectedStudioId ? "Edit Studio" : "Add New Studio";
  } else if (props.selectedTab === "4") {
    title = props.selectedStudioId
      ? "Edit Partner Studio"
      : "Add New Partner Studio";
  }

  const history = useHistory();

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={props.loginData}
        />

        {/* Content area */}
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              props.loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={props.loginData.id}
          />
          {/* Main content */}
          <div className="flex-1 md:gap-4">
            <div className="bg-white sm:mb-12 sm:mx-0">
              <main className="overflow-hidden">
                <div className="bg-warm-gray-50">
                  <div className="py-6 lg:py-10">
                    <div className="relative z-10 mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                      <h1 className="mt-0 pt-2 text-2xl font-normal text-primary font-sans">
                        {title}
                      </h1>
                    </div>
                  </div>
                </div>

                <section
                  className="relative bg-white"
                  aria-labelledby="register-heading"
                >
                  <div className="mx-auto px-4 lg:px-8">
                    <div className="relative bg-white">
                      {/*  form */}
                      <div className="">
                        <Formik
                          enableReinitialize={true}
                          initialValues={intialFormValues}
                          validationSchema={saveStudioValidationSchema}
                          onSubmit={(values) => {
                            handleFormSubmit(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            resetForm,
                            setFieldValue,
                          }) => (
                            <form
                              encType="multipart/form"
                              onSubmit={handleSubmit}
                              className="md:grid md:grid-cols-12 md:gap-y-4 sm:gap-x-4"
                            >
                              <div className="col-span-12">
                                {values.studioImage && (
                                  <div className="flex flex-row items-center">
                                    {picEdited && (
                                      <img
                                        src={values.studioImage}
                                        className="h-24 w-24 rounded-2xl border border-tertiary object-cover object-center"
                                        alt="Studio"
                                      />
                                    )}
                                    {!picEdited && (
                                      <PreviewImage
                                        file={values.studioImage}
                                        rounded={"2xl"}
                                        height={"24"}
                                        width={"24"}
                                      />
                                    )}
                                    <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white p-1.5 rounded-xl">
                                      <input
                                        id="studioImage"
                                        type="file"
                                        name=""
                                        accept="image/png, image/jpeg"
                                        onChange={(event) => {
                                          setPicEdited(false);
                                          setFieldValue(
                                            "studioImage",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                        hidden
                                      />
                                      Change Picture
                                    </label>
                                    <p className="text-red">
                                      {errors.studioImage &&
                                        touched.studioImage &&
                                        errors.studioImage}
                                    </p>
                                  </div>
                                )}
                                {!values.studioImage && (
                                  <div className="flex flex-row items-center">
                                    <div className="w-24 h-24 bg-primary rounded-3xl flex justify-center items-center">
                                      <DefaultPic className="h-8 w-8" />
                                    </div>
                                    <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white py-1.5 px-2.5 rounded-2xl">
                                      <input
                                        id="studioImage"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        name=""
                                        onChange={(event) => {
                                          setFieldValue(
                                            "studioImage",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                        hidden
                                      />
                                      Upload Logo
                                    </label>
                                    <p className="text-red">
                                      {errors.studioImage &&
                                        touched.studioImage &&
                                        errors.studioImage}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div />
                              <div className="md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3">
                                <Input
                                  type="text"
                                  name="placeName"
                                  id="placeName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.placeName}
                                  label="Place Name"
                                  autocomplete = "off"
                                />
                                <p className="text-red">
                                  {errors.placeName &&
                                    touched.placeName &&
                                    errors.placeName}
                                </p>
                              </div>
                              <div className="md:col-start-7 md:col-span-6 lg:col-start-5 lg:col-span-4 3xl:col-start-4 3xl:col-span-3">
                                <Country
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  phonecode={values.phoneCode}
                                  phonecodeoptions={phoneCodeOptions}
                                  phone={values.phone}
                                />
                                <p className="text-red">
                                  {errors.phone &&
                                    touched.phone &&
                                    errors.phone}
                                </p>
                              </div>
                              <div className="md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3">
                                <Input
                                  type="text"
                                  name="address"
                                  id="address"
                                  label="Address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.address}
                                  className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                                />
                                <p className="text-red">
                                  {errors.address &&
                                    touched.address &&
                                    errors.address}
                                </p>
                              </div>
                              <div className="md:col-start-7 md:col-span-6 lg:col-start-5 lg:col-span-4 3xl:col-start-4 3xl:col-span-3">
                                <Input
                                  type="text"
                                  name="zipcode"
                                  id="zipcode"
                                  label="Zip Code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.zipcode}
                                  className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                                />
                                <p className="text-red">
                                  {errors.zipcode &&
                                    touched.zipcode &&
                                    errors.zipcode}
                                </p>
                              </div>
                              <div className="md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3">
                                <Input
                                  type="text"
                                  name="city"
                                  id="city"
                                  label="City"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                                />
                                <p className="text-red">
                                  {errors.city && touched.city && errors.city}
                                </p>
                              </div>
                              <div className="md:col-start-7 md:col-span-6 lg:col-start-5 lg:col-span-4 3xl:col-start-4 3xl:col-span-3">
                                <Dropdown label="Country">
                                  <select
                                    className="w-full bg-white border-transparent focus:outline-none font-sans"
                                    id="country"
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    {countryOptions}
                                  </select>
                                </Dropdown>
                                <p className="text-red font-sans">
                                  {errors.country &&
                                    touched.country &&
                                    errors.country}
                                </p>
                              </div>
                              {props.selectedTab === "2" ? (
                                <div className="mt-5 md:mt-0 md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3 font-sans">
                                  <label>Work Days</label>
                                  <div className="grid grid-cols-4 gap-5 mt-4">
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isMonday"
                                        name="studioWorkDays.isMonday"
                                        type="checkbox"
                                        label="Mon"
                                        onChange={handleChange}
                                        checked={values.studioWorkDays.isMonday}
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isTuesday"
                                        name="studioWorkDays.isTuesday"
                                        type="checkbox"
                                        label="Tue"
                                        onChange={handleChange}
                                        checked={
                                          values.studioWorkDays.isTuesday
                                        }
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isWednesday"
                                        name="studioWorkDays.isWednesday"
                                        type="checkbox"
                                        label="Wed"
                                        onChange={handleChange}
                                        checked={
                                          values.studioWorkDays.isWednesday
                                        }
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isThursday"
                                        name="studioWorkDays.isThursday"
                                        type="checkbox"
                                        label="Thu"
                                        onChange={handleChange}
                                        checked={
                                          values.studioWorkDays.isThursday
                                        }
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isFriday"
                                        name="studioWorkDays.isFriday"
                                        type="checkbox"
                                        label="Fri"
                                        onChange={handleChange}
                                        checked={values.studioWorkDays.isFriday}
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isSaturday"
                                        name="studioWorkDays.isSaturday"
                                        type="checkbox"
                                        label="Sat"
                                        onChange={handleChange}
                                        checked={
                                          values.studioWorkDays.isSaturday
                                        }
                                      />
                                    </div>
                                    <div className="my-0">
                                      <CheckBox
                                        id="studioWorkDays.isSunday"
                                        name="studioWorkDays.isSunday"
                                        type="checkbox"
                                        label="Sun"
                                        onChange={handleChange}
                                        checked={values.studioWorkDays.isSunday}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mt-5 md:mt-0 md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3">
                                  <label>Market Place Access after</label>
                                  <br />
                                  <div className="mb-0 border-primary border rounded-2xl flex items-center px-3 py-3 mt-2">
                                    <select
                                      name="marketPlaceDecisionId"
                                      id="marketPlaceDecisionId"
                                      className="font-sans text-base font-normal w-full appearance-none shadow-none border-0 focus:outline-none"
                                      value={values.marketPlaceDecisionId}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      {optionItems}
                                    </select>
                                    <DropdownArrow />
                                  </div>
                                  <p className="text-red">
                                    {errors.marketPlaceDecisionId &&
                                      touched.marketPlaceDecisionId &&
                                      errors.marketPlaceDecisionId}
                                  </p>
                                </div>
                              )}

                              
                              <div className="md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3 sm:flex-col sm:justify-start sm:px-4 mb-5 md:mb-10 md:mb-0 mt-5 md:mt-4">
                                <Button
                                  type="submit"
                                  label={props.studio ? "Save" : "Add Studio"}
                                  textsize="base"
                                  font="normal"
                                />
                                {errorMsg}
                              </div>
                              <div className="md:col-start-7 md:col-span-6 lg:col-start-5 lg:col-span-4 3xl:col-start-4 3xl:col-span-3 sm:flex-col sm:justify-start sm:px-4 mb-10 md:mb-0 md:mt-4">
                                <Button
                                  type="button"
                                  label="Cancel"
                                  textsize="base"
                                  background={"none"}
                                  color={"black"}
                                  bordercolor={"primary"}
                                  font="normal"
                                  onClick={() => {
                                    history.goBack();
                                  }}
                                />
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
