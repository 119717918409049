/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  VIEWS,
  PORTFOLIO_STATUS,
  ISTMPPORTFOLIO,
} from "../../../layouts/ArtistConstants";
import { ReactComponent as CheckPending } from "../../../assets/ic_pending.svg";
import { ReactComponent as CheckDone } from "../../../assets/ic_check_gray.svg";
import { ReactComponent as CheckCancelled } from "../../../assets/ic_cancelled.svg";
import { ReactComponent as MaximiseIcon } from "../../../assets/ic_maximise.svg";
import {
  USER_ROLE_BOSS,
  USER_ROLE_ADMIN,
  USER_ROLE_MANAGER,
} from "../../../layouts/ArtistConstants";
import { USER_ROLE_CLIENT } from "utils/constants";
import ShowPortfolioImages from "./ShowPortfolioImages";
import Swal from "sweetalert2";
import CustomThumbnail from "components/CustomThumbnail";

export default function TempArtistPortfolioListCell(props) {
  const [imageComment, setImageComment] = useState();

  const [selectedItem, setSelectedItem] = useState(
    props?.artistPortfolio?.tmpImg[0]
  );
  const [editOpen, setEditOpen] = useState(false);

  const showAlert = (text) => {
    return Swal.fire({
      icon: "question",
      title: "",
      text: text,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    });
  };

  const submitStatusConfirm = async () => {
    showAlert("Are you sure, you want to confirm the portfolio?").then(
      (value) => {
        if (value.isConfirmed) {
          let obj = {
            statusId: PORTFOLIO_STATUS.COMPLETE,
            updatedBy: props.actualLoginData.id,
          };
          props.onUpdateTmpStatus(obj, props.artistPortfolio.id);
        }
      }
    );
  };

  const submitStatusCancel = async () => {
    showAlert("Are you sure, you want to cancel the portfolio?").then(
      (value) => {
        if (value.isConfirmed) {
          let obj = {
            statusId: PORTFOLIO_STATUS.CANCELLED,
          };
          props.onUpdateTmpStatus(obj, props.artistPortfolio.id);
        }
      }
    );
  };

  const deletePortfolio = async () => {
    showAlert("Are you sure, you want to delete the portfolio?").then(
      (value) => {
        if (value.isConfirmed) {
          props.onDeleteTmpPortfolio(props.artistPortfolio.id);
        }
      }
    );
  };

  useEffect(() => {
    if (props?.artistPortfolio?.tmpImg) {
      setImageComment(props?.artistPortfolio?.tmpImg[0].comment);
    }
  }, [props?.artistPortfolio?.tmpImg]);

  const handleShowPortfolio = () => {
    setEditOpen(true);
  };

  return (
    <>
      <div className="rounded overflow-hidden">
        <div className="px-0 py-0">
          <div className="flex">
            <div className="mb-2 flex flex-row justify-center ml-0 items-center">
              <div className="font-sans text-primary font-bold text-xl">
                {props?.artistPortfolio?.styleTitle}{" "}
              </div>

              {!(
                props.role === USER_ROLE_BOSS ||
                props.role === USER_ROLE_ADMIN ||
                props.role === USER_ROLE_MANAGER ||
                props.role === USER_ROLE_CLIENT
              ) && (
                <div>
                  {props.showStatus && (
                    <div className="flex flex-row items-center ml-4">
                      {props?.artistPortfolio?.portfolioStatusId ===
                      PORTFOLIO_STATUS.COMPLETE ? (
                        <CheckDone className="mr-2" fill="#AFAFAF" />
                      ) : props?.artistPortfolio?.portfolioStatusId ===
                        PORTFOLIO_STATUS.CANCELLED ? (
                        <CheckCancelled className="mr-2" />
                      ) : (
                        <CheckPending className="mr-2" />
                      )}

                      <div
                        className={`flex text-${
                          props?.artistPortfolio?.portfolioStatusId ===
                          PORTFOLIO_STATUS.COMPLETE
                            ? "tertiary"
                            : props?.artistPortfolio?.portfolioStatusId ===
                              PORTFOLIO_STATUS.CANCELLED
                            ? "cancelled"
                            : "primary"
                        } text-sm font-normal`}
                      >
                        {props?.artistPortfolio?.status?.statusName}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-1" />
            {!(
              props.role === USER_ROLE_BOSS ||
              props.role === USER_ROLE_ADMIN ||
              props.role === USER_ROLE_MANAGER ||
              props.role === USER_ROLE_CLIENT
            ) && (
              <div>
                {props.calledFrom == VIEWS.ARTIST_HOME.id ? (
                  <a
                    href={`/artist/previewartistportfolio/${props?.artistPortfolio?.id}/${ISTMPPORTFOLIO.YES.id}`}
                    className={"text-primary text-sm font-sans font-semibold"}
                  >
                    Edit
                  </a>
                ) : (
                  <a
                    href={`/artist/artistportfolio/${props.calledFrom}/${props?.artistPortfolio?.id}/${ISTMPPORTFOLIO.YES.id}`}
                    className={"text-primary text-sm font-sans font-semibold"}
                  >
                    Edit
                  </a>
                )}
              </div>
            )}

            {(props.role === USER_ROLE_BOSS ||
              props.role === USER_ROLE_ADMIN ||
              props.role === USER_ROLE_MANAGER) &&
              props?.artistPortfolio?.portfolioStatusId ===
                PORTFOLIO_STATUS.COMPLETE && (
                <div>
                  <div
                    onClick={() => deletePortfolio()}
                    href="#"
                    className={"text-red text-base font-sans font-semibold"}
                  >
                    Delete
                  </div>
                </div>
              )}
          </div>
          <div className="flex">
            <span className="text-white bg-primary text-base font-light rounded-lg py-1 px-2 mb-1 font-sans">
              {props?.artistPortfolio?.style?.styleName}
            </span>
          </div>
          <div className={`w-full -mx-0 md:mx-0 md:w-full`}>
            <ul className="overflow-x-scroll mx-0 md:mx-0 my-2 whitespace-nowrap">
              {" "}
              {props?.artistPortfolio?.tmpImg?.map(
                (artistPortfolioImage, i) => (
                  <div
                    className={`inline-flex relative  ${
                      selectedItem &&
                      artistPortfolioImage.imageUrl == selectedItem.imageUrl
                        ? "border-primary"
                        : "border-white"
                    }  border-2 h-36 w-36 rounded-2xl`}
                    key={i}
                  >
                    <CustomThumbnail
                      file={artistPortfolioImage}
                      selectedItem={setSelectedItem}
                      onClick={() => {
                        setImageComment(artistPortfolioImage.comment);
                      }}
                      rounded="rounded-2xl"
                    />
                    <div className="absolute right-1 bottom-1">
                      <MaximiseIcon onClick={() => handleShowPortfolio()} />
                    </div>
                  </div>
                )
              )}
            </ul>
          </div>
          {imageComment && (
            <p className="font-sans text-base mt-1 ml-0">{imageComment}</p>
          )}

          {!(
            props?.artistPortfolio?.portfolioStatusId ===
              PORTFOLIO_STATUS.COMPLETE ||
            props?.artistPortfolio?.portfolioStatusId ===
              PORTFOLIO_STATUS.CANCELLED
          ) &&
            (props.role === USER_ROLE_BOSS ||
              props.role === USER_ROLE_ADMIN ||
              props.role === USER_ROLE_MANAGER) && (
              <div className="mt-2 flex space-x-4">
                <button
                  className="bg-primary text-white rounded-3xl px-2 py-2 w-1/2 md:w-1/3 lg:w-1/5"
                  onClick={() => submitStatusConfirm()}
                >
                  Confirm
                </button>
                <button
                  className="border border-primary  text-primary rounded-3xl px-2 py-2 w-1/2 md:w-1/3 lg:w-1/5"
                  onClick={() => submitStatusCancel()}
                >
                  Cancel
                </button>
              </div>
            )}

          {(props.role === USER_ROLE_BOSS ||
            props.role === USER_ROLE_ADMIN ||
            props.role === USER_ROLE_MANAGER) &&
            props?.artistPortfolio?.portfolioStatusId !==
              PORTFOLIO_STATUS.PENDING && (
              <div>
                {props.showStatus && (
                  <div className="flex flex-row items-center ml-2 mt-2">
                    {props?.artistPortfolio?.portfolioStatusId ===
                    PORTFOLIO_STATUS.COMPLETE ? (
                      <CheckDone className="mr-2" fill="#0BC851" />
                    ) : props?.artistPortfolio?.portfolioStatusId ===
                      PORTFOLIO_STATUS.CANCELLED ? (
                      <CheckCancelled className="mr-2" />
                    ) : (
                      ""
                    )}

                    <div
                      className={`flex text-${
                        props?.artistPortfolio?.portfolioStatusId ===
                        PORTFOLIO_STATUS.COMPLETE
                          ? "progress"
                          : props?.artistPortfolio?.portfolioStatusId ===
                            PORTFOLIO_STATUS.CANCELLED
                          ? "cancelled"
                          : ""
                      } text-sm font-normal`}
                    >
                      {props?.artistPortfolio?.status?.statusName}
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>

        {editOpen && (
          <ShowPortfolioImages
            open={editOpen}
            setOpen={setEditOpen}
            loggedInUserId={props.loggedInUserId}
            artistPortfolio={props?.artistPortfolio}
            role={props?.role}
            projectStatus={props?.projectStatus}
            isTmpPortfolio={ISTMPPORTFOLIO.YES.id}
          />
        )}
      </div>
    </>
  );
}
