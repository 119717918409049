/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "redux/actions/LoginActions";
import { getStudiosByStudioType } from "../../redux/actions/StudioAction";
import HomeView from "./HomeView";
import localStorageService from "../../services/localStorageService";
import { getManagersByStudioType } from "redux/actions/ManagerAction";
import { HOMETABS } from "layouts/AdminConstants";
const lss = localStorageService;

export default function HomeContainer(props) {
  const [selectedTab, setSelectedTab] = useState(
    lss.getItem("admin_home_tab") ? lss.getItem("admin_home_tab") : "1"
  );
  const managers = useSelector((state) => state.manager.getManagerByType);
  const managerError = useSelector((state) => state.manager.error);
  const studios = useSelector((state) => state.studio);
  const dispatch = useDispatch();

  useEffect(() => {
    if (studios.error) {
      return;
    }
    if (selectedTab === HOMETABS.MYMANAGERS.id) {
      dispatch(getManagersByStudioType(1));
    } else if (selectedTab === HOMETABS.MYSTUDIOS.id) {
      dispatch(getStudiosByStudioType(1));
    } else if (selectedTab === HOMETABS.PARTNERSTUDIOMANAGERS.id) {
      dispatch(getManagersByStudioType(2));
    } else if (selectedTab === HOMETABS.PARTNERSTUDIO.id) {
      dispatch(getStudiosByStudioType(2));
    }
  }, [selectedTab, dispatch, studios.createStudio, studios.updateStudio]);

  const signOutUser = () => {
    dispatch(signOut());
  };

  const showProfile = () => {
    props.history.push({
      pathname: "/admin/profile",
    });
  };

  return (
    <div>
      <HomeView
        studios={studios.getStudioByType}
        managers={managers}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        signOutUser={signOutUser}
        showProfile={showProfile}
        title="Home View"
        loginData={props.loginData}
        managerError={managerError}
        studioError={studios.error}
      />
    </div>
  );
}
