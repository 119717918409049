/* eslint-disable eqeqeq */
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Faq = ({ data, lang, deleteFaq, editFaq, fromHome }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-col gap-6 mb-4 text-sm">
      <div className="flex flex-row justify-between items-center px-4 py-4 border-transparent rounded bg-lightGray font-bold">
        <div className="">{lang == 1 ? data.questionEN : data.questionDE}</div>
        {isExpanded ? (
          <RemoveIcon onClick={() => setExpanded(false)} />
        ) : (
          <AddIcon onClick={() => setExpanded(true)} />
        )}
      </div>
      {isExpanded && (
        <div className="px-8">
          <div className="text-gray">
            {lang == 1 ? data.answerEN : data.answerDE}
          </div>
          {!fromHome && (
            <div className="flex flex-row justify-between md:w-1/6 mt-4">
              <div className="text-primary" onClick={() => editFaq(data)}>
                Edit
              </div>
              <div className="text-red" onClick={() => deleteFaq(data.id)}>
                Delete
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Faq;
