/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import CustomButton from "components/UI/Button";
import Input from "components/UI/Input";
import { sendInvite } from "../../../redux/actions/AdminArtistAction";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import TextArea from "components/UI/TextArea";

const SendInvite = ({ open, setOpen, iniError }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(iniError);
  const isValidEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*./g;
  const [lastName, setLastName] = useState("");

  const newInviteArtist = useSelector((state) => state.newInviteArtistReducer);

  useEffect(() => {
    setError(null);
    if (newInviteArtist.error) {
      setError(
        <Alert className="m-4 " severity="error">
          {newInviteArtist.error.message.error.message}
        </Alert>
      );
    }
  }, [newInviteArtist]);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setError(null);
    newInviteArtist.error = null;
  };

  const handleSave = () => {
    if (!name) {
      setError(
        <Alert className="m-4 " severity="error">
          {"Please enter the first name!"}
        </Alert>
      );
      return;
    }

    let regex = /^[A-Za-zÀ-ž]+$/;
    if (!regex.test(name)) {
      setError(
        <Alert className="m-4 " severity="error">
          {"First Name must only contain alphabets."}
        </Alert>
      );
      return;
    }
    if (lastName) {
      if (!regex.test(lastName)) {
        setError(
          <Alert className="m-4 " severity="error">
            {"Last Name must only contain alphabets."}
          </Alert>
        );
        return;
      }
    }

    if (!email) {
      setError(
        <Alert className="m-4 " severity="error">
          {"Please enter the email!"}
        </Alert>
      );
      return;
    }
    if (!email.match(isValidEmail)) {
      setError(
        <Alert className="m-4 " severity="error">
          {"Please enter proper email!"}
        </Alert>
      );
      return;
    }
    const formData = {
      name,
      email,
      comment,
      lastName,
    };
    newInviteArtist.error = null;
    dispatch(sendInvite(formData));
  };

  useEffect(() => {
    if (newInviteArtist.data && !newInviteArtist.error) {
      setOpen(false);
    }
    newInviteArtist.data = null;
  }, [newInviteArtist]);

  return (
    <div className="flex align-center justify-center">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div className="sm:hidden bg-white m-2 p-4 rounded-3xl xs:w-72 align-center justify-center">
            <h2 className="text-primary text-xl text-medium ml-2 mt-2">
              Invite New Artist
            </h2>
            <div className="w-full">
              <Input
                label="First Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full">
              <Input
                label="Last Name"
                value={lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="w-full">
              <Input
                label="Email"
                value={email}
                type="email"
                required
                onChange={(e) =>
                  setEmail(e.target.value.toLowerCase().replace(/\s/g, ""))
                }
              />
            </div>
            <TextArea
              label="Comment"
              value={comment}
              cols={74}
              required
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="flex gap-6 mt-6">
              <CustomButton
                type="submit"
                width="full"
                textsize="base"
                label="Save"
                onClick={handleSave}
              />
              <CustomButton
                width="full"
                textsize="base"
                label="Cancel"
                onClick={handleClose}
              />
            </div>
            {error}
          </div>

          <div className="hidden sm:block bg-white m-2 p-4 rounded-3xl sm:max-w-sm md:max-w-md lg:max-w-4xl align-center justify-center">
            <h2 className="text-primary text-xl text-medium ml-2 mt-2">
              Invite New Artist
            </h2>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-2 mb-6 mt-6">
              <div className="lg:w-1/2">
                <Input
                  label="First Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="lg:w-1/2">
                <Input
                  label="Last Name"
                  value={lastName}
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full">
              <Input
                label="Email"
                value={email}
                type="email"
                required
                onChange={(e) =>
                  setEmail(e.target.value.toLowerCase().replace(/\s/g, ""))
                }
              />
            </div>
            <TextArea
              label="Comment"
              value={comment}
              cols={74}
              required
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="flex gap-6 mt-6">
              <CustomButton
                type="submit"
                width="full"
                textsize="base"
                label="Save"
                onClick={handleSave}
              />
              <CustomButton
                width="full"
                textsize="base"
                label="Cancel"
                onClick={handleClose}
              />
            </div>
            {error}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendInvite;
