/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { signUp } from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/UI/Input";
import CheckBox from "components/UI/CheckBox";
import Button from "components/UI/Button";
import Dropdown from "views/landing/support/components/Dropdown";
import { Alert } from "@mui/material";
import Header from "components/Navbars/AuthNavbar";
import GoogleMapReact from "google-map-react";
import {
  defaultMapProps,
  MapIcon,
  MAPS_KEY,
  USER_ROLE_CLIENT,
  USER_TYPE_CLIENT,
} from "layouts/AdminConstants";
import { getCountries } from "redux/actions/CountryAction";
import Country from "components/UI/Country";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../../utils/constants";

const registerValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required")
    .test("alphabets", "First Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required")
    .test("alphabets", "Last Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  address: yup
    .string("Enter your Address")
    .max(100, ({ max }) => "Address can be of maximum 100 characters long")
    .required("Address is required"),
  birthYear: yup
  .number()
  .positive()
  .integer()
  .required(`Birth Year is Required`)
  .typeError('Year must be a four digit number')
  // .matches(/^SW\d{4}$/, 'Year can be only four digits')
    // .matches(/(\d){4}\b/, "Enter a valid birth year")
  .min(1930, "Enter a valid birth year")
  .max(2020, "Enter a valid birth year"),    
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
  zipcode: yup
    .string()
    .matches(/^[0-9]*$/, "Zip Code should be a number")
    .required(`Zip Code is required`),
  city: yup
    .string("Enter your city")
    .required("City is required")
    .test("alphabets", "City must contain valid characters", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  country: yup.string().required("Country is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .max(16, "Password should be of maximum 16 characters length")
    .matches(
      /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      "Must Contain One uppercase, One lowercase, One number and One special case Character"
    )
    .required("Password is required"),
  confirmPassword: yup
    .string("Enter your password")
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Confirm Password is required"),
  terms: yup
    .boolean()
    .default(false)
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
});

const Register = (props) => {
  const [firstTime, setFirstTime] = useState(false);
  const countries = useSelector((state) => state.country.data);
  const [state, setState] = useState({
    open: false,
  });

  const [latitude, setLatitude] = useState(defaultMapProps.center.lat);
  const [longitude, setLongitude] = useState(defaultMapProps.center.lng);
  const [countryOptions, setCountryOptions] = useState([]);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [registerTitle, setRegisterTitle] = useState("Register");

  const dispatch = useDispatch();
  const signup = useSelector((state) => state.UserReducerNew);

  useEffect(() => {
    if (!firstTime) {
      dispatch(getCountries());
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {
    populateCountry();
    populatePhoneCode();
  }, [countries]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showTerms])

  const handleFormSubmit = (values) => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
    var encrypted = CryptoJS.AES.encrypt(values.password, SCERETPASS).toString();
 
    let obj = {
      firstName: values.firstName,
      lastName: values.lastName,
      birthYear: values.birthYear,
      street: values.address,
      city: values.city,
      zipcode: values.zipcode,
      country: values.country,
      phone: values.phone,
      email: values.email.toLowerCase().replace(/\s/g, ""),
      password: encrypted,
      userTypeId: USER_TYPE_CLIENT,
      userRoleId: USER_ROLE_CLIENT,
      isWhatsappNotification: 0,
      subcription: 0,
      photo_url: "",
      latitude: latitude,
      longitude: longitude,
      phoneCode: values.phoneCode,
    };
    dispatch(signUp(obj));
  };

  const autoHideMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 5000);
  };

  const autoHideSuccessMessage = () => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
      props.history.push({
        pathname: "/signupData",
      });
    }, 1000);
  };

  let error = null;
  if (
    state.open &&
    signup.signupSuccess === false &&
    signup.signupData === null &&
    signup.signupError !== null
  ) {
    autoHideMessage();
    error = (
      // <p className="mt-6 text-red text-center ml-10">
      //   {props.signupData.error}
      // </p>
      <Alert className="m-4 " severity="error">
        {signup?.signupError?.data?.error?.message}
      </Alert>
    );
  }
  if (
    state.open &&
    signup.signupSuccess === true &&
    signup.signupData !== null &&
    signup.signupError === null
  ) {
    autoHideSuccessMessage();
    error = (
      <p className="mt-6 text-green text-center ml-10">
        {"Register Successful!"}
      </p>
    );
  }

  const loadMap = (map, maps) => {
    let marker = new maps.Marker({
      position: {
        lat: defaultMapProps.center.lat,
        lng: defaultMapProps.center.lng,
      },
      map,
      draggable: true,
      icon: MapIcon,
    });
    marker.addListener("dragend", (event) => {
      setLatitude(event.latLng.lat());
      setLongitude(event.latLng.lng());
    });
  };

  const populateCountry = () => {
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.id} value={country.id}>
          {country.name}
        </option>
      ));
    }
    setCountryOptions(countryOptionItems);
  };

  const populatePhoneCode = () => {
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  useEffect(() => {
    if (showTerms) {
      setRegisterTitle("Terms of Services");
    } else {
      setRegisterTitle("Register");
    }
  }, [showTerms]);

  return (
    <div className="bg-white sm:mb-12">
      {/* Header section */}
      <header>
        <Header className="" hideRegister={true} />
      </header>
      <main className="overflow-hidden">
        <div className="bg-warm-gray-50">
          <div className="py-6 lg:py-10">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="mt-2 pt-2 text-3.5xl font-normal text-primary font-sans">
                {registerTitle}
              </h1>
            </div>
          </div>
        </div>

        {showTerms && (
        <div className="grid grid-cols-2 mb-10 overflow-y-scroll max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" >
          <div className="flex mt-2 col-start-1 col-span-2 mb-5 ">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently
            with desktop publishing software like Aldus PageMaker including
            versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting, remaining essentially unchanged.
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy text ever since the 1500s, when an unknown printer took a
            galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. It was popularised in
            the 1960s with the release of Letraset sheets containing Lorem Ipsum
            passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
          </div>
          <Button
            label="Back"
            textsize="base"
            onClick={() => setShowTerms(false)}
            background="white"
            bordercolor="primary"
            color="primary"
          />
        </div>
      )}
      {!showTerms && (
        <section
          className="relative bg-white"
          aria-labelledby="register-heading"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative bg-white">
              {/*  form */}
              <div className="">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    firstName: "",
                    lastName: "",
                    address: "",
                    city: "",
                    country: "Switzerland",
                    zipcode: "",
                    phone: "",
                    birthYear: "",
                    terms: false,
                    phoneCode: "+41",
                  }}
                  validationSchema={registerValidationSchema}
                  onSubmit={(values) => {
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="grid grid-cols-1 md:gap-y-12 sm:grid-cols-2 sm:gap-x-8"
                    >
                      <div>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          label="First Name"
                          autoComplete="username"
                        />
                        <p className="text-red">
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </p>
                      </div>

                      <div>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          label="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          autoComplete="username"
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />

                        <p className="text-red">
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="text"
                          name="birthYear"
                          id="birthYear"
                          label="Year of Birth"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.birthYear}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />
                        <p className="text-red">
                          {errors.birthYear &&
                            touched.birthYear &&
                            errors.birthYear}
                        </p>
                      </div>
                      <div className=""></div>
                      <div>
                        <div className="border border-primary rounded-2xl mt-4 py-2 px-3 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
                          <label
                            htmlFor="address"
                            className="block text-xs font-medium text-primary font-sans"
                          >Address</label>
                          <div className={`flex flex-row`}>
                            <textarea
                                            type="text"
                                            name="address"
                                            id="address"
                                            label="Address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            rows={1}
                                            className="font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                                            />
                          </div>
                        </div>
                        <p className="text-red">
                          {errors.address && touched.address && errors.address}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          label="Zip Code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zipcode}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />
                        <p className="text-red">
                          {errors.zipcode && touched.zipcode && errors.zipcode}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="text"
                          name="city"
                          id="city"
                          label="City"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />

                        <p className="text-red">
                          {errors.city && touched.city && errors.city}
                        </p>
                      </div>
                      <div>
                        <Dropdown label="Country">
                          <select
                            className="w-full bg-white border-transparent focus:outline-none"
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {countryOptions}
                          </select>
                        </Dropdown>
                        <p className="text-red font-sans">
                          {errors.country && touched.country && errors.country}
                        </p>
                      </div>
                      <div className="sm:col-span-2 px-1 py-2 mt-4 overflow-hidden">
                        <label
                          htmlFor="name"
                          className="block text-sm font-normal text-primary"
                        >
                          Location
                        </label>
                        <div className="rounded-lg overflow-hidden h-64 w-full">
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: MAPS_KEY,
                            }}
                            defaultCenter={defaultMapProps.center}
                            defaultZoom={defaultMapProps.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) =>
                              loadMap(map, maps)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <Country
                          onChange={handleChange}
                          onBlur={handleBlur}
                          phonecode={values.phoneCode}
                          phonecodeoptions={phoneCodeOptions}
                          phone={values.phone}
                        />
                        <p className="text-red">
                          {errors.phone && touched.phone && errors.phone}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="text"
                          name="email"
                          id="email"
                          label="Email"
                          autoComplete="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />
                        <p className="text-red">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          label="Password"
                          autoComplete="new-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />
                        <p className="text-red">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>
                      </div>
                      <div>
                        <Input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          label="Confirm Password"
                          autoComplete="new-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                        />

                        <p className="text-red">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </p>
                      </div>
                      <div className="my-4">
                        <CheckBox
                          id="terms"
                          name="terms"
                          type="checkbox"
                          label="Terms of Service"
                          value={values.terms}
                          onChange={handleChange}
                          labelshowaslink={+true}
                          onLabelClick={() => {
                            // props.history.push({
                            //   pathname: "/terms",
                            // });
                            setShowTerms(true);
                          }}
                        />
                        <p className="text-red">
                          {errors.terms && touched.terms && errors.terms}
                        </p>
                      </div>
                      <div className=""> </div>
                      <div className="sm:col-span-1 sm:flex-col sm:justify-start sm:px-4 mb-10 md:mb-0">
                        <Button
                          type="submit"
                          label="Register"
                          textsize="base"
                        />
                        {error}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
              )}
      </main>
    </div>
  );
};

export default Register;
