import React, { useState, useEffect }  from "react";
import { ReactComponent as Width } from "../../../../assets/project/ic_width.svg";
import { ReactComponent as Height } from "../../../../assets/project/ic_height.svg";
import { SERVER_URL } from "utils/constants";
import { USER_ROLE_PROJECT_ADMIN } from "../../CONSTANTS";
import EditMySkin from "./EditMySkin";
import { ReactComponent as AroundIcon } from "../../../../assets/ic_rotate.svg";
import Swal from "sweetalert2";

const Dimensions = (props) => {  
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const [selectedIsAround, setSelectedIsAround] = useState(false);

  useEffect(() => {
    setSelectedIsAround(props?.isTatooAround);    
  }, [props?.isTatooAround]);

  let show = false;
  if (
    props?.role === USER_ROLE_PROJECT_ADMIN &&
    props?.projectStatus === "New Project"
  ) {
    show = true;
  }

  const handleChangeInAround = (value) => {
    let obj = {
      editIsAround: {
        isAround: value,
        loggedInUserId: props.loggedInUserId,
      },
    };
    props.onSubmit(obj);
  };

  const onChangeIsAround = (selectedIsAround) => {
    setSelectedIsAround(!selectedIsAround);
    handleChangeInAround(!selectedIsAround);
  }

  const confirmIsAroundChange = async (selectedIsAround) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change is-around setting for the project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        onChangeIsAround(selectedIsAround);
      }
    });
  };

  return (
    <div className="text-sm">
      <div className="font-sans text-base text-tertiary font-normal mb-2">
        Dimension
      </div>
      <div className="flex flex-1 font-sans">
        <div className="flex flex-1 flex-row items-center">
          <Width fill="#AFAFAF" />
          <div className="ml-2">{props?.data?.width} cm</div>
        </div>
        <div className="flex flex-1 flex-row items-center">
          <Height fill="#AFAFAF" />
          <div className="ml-2">{props?.data?.height} cm</div>
        </div>
      </div>
      <div className="flex flex-row mt-2 font-sans">
        <div className="font-sans text-sm text-tertiary font-normal mr-2">
          Approx.
        </div>
        <div className="font-sans text-sm font-normal">
          {props?.data?.height * props?.data?.width} cm<sup>2</sup>
        </div>
      </div>
      {/* <div className="flex flex-row mt-2">
        <div className="font-sans text-sm text-tertiary font-normal mr-2">
          Gender
        </div>
        {props?.gender && (
          <div className="flex items-start mt-1">
            {props?.clientById?.user?.gender === "Female" ? (
              <FemaleIcon className="ml-4" />
            ) : props?.clientById?.user?.gender === "male" ? (
              <MaleIcon />
            ) : (
              <GenderIcon />
            )}
          </div>
        )}
        {!props?.gender && (
          <div className="flex items-start mt-1">
            <GenderIcon />
          </div>
        )}
      </div> */}

      <div
        className={`flex flex-row mt-2 font-sans h-8 xl:h-10 w-8 items-center justify-items-center justify-center`} 
        onClick={show ? () => confirmIsAroundChange(selectedIsAround) : undefined}
      >
        <AroundIcon stroke={selectedIsAround ? "#C7A33F" : "#afafaf"} />
      </div>
      <div className="flex flex-col mt-2">
        {props?.skinToneUrl && (
          <div className="flex flex-row ">
            <div className="font-sans text-base text-tertiary font-normal mr-2">
              My Skin
            </div>
            <div className="flex items-start mt-0">
              <img
                src={`${SERVER_URL}` + props?.skinToneUrl}
                height="20"
                width="20"
                alt="Skin Tone"
              />
            </div>
          </div>
        )}
        {show && (
          <div
            className="text-primary text-base justify-center py-0 ml-0"
            onClick={handleEditOpen}
          >
            Edit My Skin
            {editOpen && (
              <EditMySkin
                open={editOpen}
                setOpen={setEditOpen}
                loggedInUserId={props.loggedInUserId}
                onSubmit={props.onSubmit}
                mySkinId={props.skinToneId}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dimensions;
