/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import {
  sections,
  TIME_RANGE_ERROR_MESSAGE,
  DUPLICATE_TIME_RANGE_ERROR_MESSAGE,
} from "../CONSTANTS";
import CheckBox from "components/UI/CheckBox";
import TimeList from "./timeCreate";
import { DAYS, getDaysByInt } from "utils/constants";
import { Alert } from "@mui/material";

const dateProposalPageValidationSchema = yup.object({});

export default function DateProposalPage({
  page,
  data,
  storedProjectState,
  loggedInUserId,
  onSubmit,
  setOpen,
  onBack,
  setStoredProjectState,
}) {
  const [timeDetails, setTimeDetails] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [error, setError] = useState(null);
  const [timeRangeError, setTimeRangeError] = useState(null);
  const [duplicateTimeRangeError, setDuplicateTimeRangeError] = useState(null);

  let isMonday = false;
  let isTuesday = false;
  let isWednesday = false;
  let isThursday = false;
  let isFriday = false;
  let isSaturday = false;
  let isSunday = false;

  if (page && page === "editDate") {
    if (data && data.length > 0) {
      data &&
        data.map((val1, idx1) => {
          let dayName = getDaysByInt(val1.day);
          let isDay = false;
          if (dayName === "Monday") {
            isMonday = true;
            isDay = true;
          }
          if (dayName === "Tuesday") {
            isTuesday = true;
            isDay = true;
          }
          if (dayName === "Wednesday") {
            isWednesday = true;
            isDay = true;
          }
          if (dayName === "Thursday") {
            isThursday = true;
            isDay = true;
          }
          if (dayName === "Friday") {
            isFriday = true;
            isDay = true;
          }
          if (dayName === "Saturday") {
            isSaturday = true;
            isDay = true;
          }
          if (dayName === "Sunday") {
            isSunday = true;
            isDay = true;
          }
        });
    }
  }

  if (storedProjectState?.proposedDateForm) {
    let d = JSON.parse(
      storedProjectState?.proposedDateForm?.get("proposedDays")
    );
    if (d && d.length > 0) {
      d.map((val1, idx1) => {
        let dayName = getDaysByInt(val1.day);
        let isDay = false;
        if (dayName === "Monday") {
          isMonday = true;
          isDay = true;
        }
        if (dayName === "Tuesday") {
          isTuesday = true;
          isDay = true;
        }
        if (dayName === "Wednesday") {
          isWednesday = true;
          isDay = true;
        }
        if (dayName === "Thursday") {
          isThursday = true;
          isDay = true;
        }
        if (dayName === "Friday") {
          isFriday = true;
          isDay = true;
        }
        if (dayName === "Saturday") {
          isSaturday = true;
          isDay = true;
        }
        if (dayName === "Sunday") {
          isSunday = true;
          isDay = true;
        }
      });
    }
  }

  useEffect(() => {
    if (page && page === "editDate") {
      setTimeDetails({
        Monday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Tuesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Wednesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Thursday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Friday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Saturday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Sunday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
      });
      if (data && data.length > 0) {
        for (let key in data) {
          let dayName = getDaysByInt(data[key].day);
          let initialDays = timeDetails[dayName];
          initialDays.push({
            index: Math.random(),
            fromTime: data[key].fromTime,
            toTime: data[key].toTime,
          });
          setTimeDetails((timeDetails) => ({
            ...timeDetails,
            [dayName]: initialDays,
          }));
        }
      }
    } else {
      setTimeDetails({
        Monday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Tuesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Wednesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Thursday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Friday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Saturday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Sunday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
      });
    }

    if (storedProjectState?.proposedDateForm) {
      setTimeDetails({
        Monday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Tuesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Wednesday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Thursday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Friday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Saturday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
        Sunday: [
          {
            index: Math.random(),
            fromTime: "",
            toTime: "",
          },
        ],
      });
      let d = JSON.parse(
        storedProjectState?.proposedDateForm?.get("proposedDays")
      );
      if (d && d.length > 0) {
        for (let key in d) {
          let dayName = getDaysByInt(d[key].day);
          let initialDays = timeDetails[dayName];
          initialDays.push({
            index: Math.random(),
            fromTime: d[key].fromTime,
            toTime: d[key].toTime,
          });
          setTimeDetails((timeDetails) => ({
            ...timeDetails,
            [dayName]: initialDays,
          }));
        }
      }
    }
  }, []);

  let intialFormValues = {
    Monday: isMonday,
    Tuesday: isTuesday,
    Wednesday: isWednesday,
    Thursday: isThursday,
    Friday: isFriday,
    Saturday: isSaturday,
    Sunday: isSunday,
  };

  const handleFormSubmit = (values) => {
    let dates = [];
    let isError = false;
    Object.keys(timeDetails).map((val1, idx1) => {
      if (values[val1]) {
        let dayInt = DAYS[`${val1.toUpperCase()}`].id;
        timeDetails[val1].map((val, idx) => {
          let dayObj = {
            day: dayInt,
            fromTime: val.fromTime,
            toTime: val.toTime,
          };
          dates.push(dayObj);

          //If day is selected, but time range is NOT selected.
          if (val.fromTime == "" || val.toTime == "") {
            isError = true;
            return;
          }
        });
      }
    });

    // Check if duplicate fromTime / toTime selected for a particular day.
    if (duplicateTimeRangeError != null) {
      setDuplicateTimeRangeError(
        <Alert className="m-4 " severity="error">
          {DUPLICATE_TIME_RANGE_ERROR_MESSAGE}
        </Alert>
      );
      return;
    }

    if (isError) {
      setTimeRangeError(
        <Alert className="m-4 " severity="error">
          {TIME_RANGE_ERROR_MESSAGE}
        </Alert>
      );
      return;
    }

    if (dates.length == 0) {
      setError(
        <Alert className="m-4 " severity="error">
          At least one date should be proposed
        </Alert>
      );
      return;
    }

    if (page === "editDate") {
      let obj = {
        EditDate: {
          proposedDays: JSON.stringify(dates),
          loggedInUserId: loggedInUserId,
        },
      };

      //If time range error, do not allow to submit.
      if (timeRangeError == null) {
        onSubmit(obj);
      }
      setOpen(false);
    } else {
      let obj = new FormData();
      obj.append("proposedDays", JSON.stringify(dates));
      setStoredProjectState((storedProjectState) => ({
        ...storedProjectState,
        "proposedDateForm": obj,
      }));
      if (timeRangeError == null) {
        onSubmit(obj, sections.Location, "proposedDateForm"); // if user is logged in
      }
      // setOpen(false);
    }
  };

  const formHandleChange = (e) => {
    setError(null);
    if (["fromTime", "toTime"].includes(e.target.name)) {
      timeDetails[e.target.dataset.day][e.target.dataset.id][e.target.name] =
        e.target.value;
    }
  };

  const addNewRow = (day) => {
    let initialDays = timeDetails[day];
    initialDays.push({
      index: Math.random(),
      fromTime: "",
      toTime: "",
    });

    setTimeDetails((timeDetails) => ({
      ...timeDetails,
      [day]: initialDays,
    }));
  };

  const clickOnDelete = (record, day) => {
    setTimeRangeError(null);
    setDuplicateTimeRangeError(null);
    let newDays = timeDetails[day].filter((r) => r !== record);
    setTimeDetails((timeDetails) => ({
      ...timeDetails,
      [day]: newDays,
    }));
  };

  const validateTimeRange = (fromTime, totime, daySelected) => {
    setTimeRangeError(null);
    setDuplicateTimeRangeError(null);
    Object.keys(timeDetails).map((day) => {
      if (daySelected === day) {
        timeDetails[day].map((time) => {
          // Check if duplicate fromTime / toTime selected for a particular day.
          if (
            fromTime &&
            time.fromTime === fromTime &&
            totime &&
            time.toTime === totime
          ) {
            setDuplicateTimeRangeError(
              <Alert className="m-4 " severity="error">
                {DUPLICATE_TIME_RANGE_ERROR_MESSAGE}
              </Alert>
            );
            return;
          }

          let fromTime2 = fromTime.split(":");
          let toTime2 = totime.split(":");

          if (fromTime2.length > 0 && toTime2.length > 0) {
            if (
              (fromTime2[0] == toTime2[0] && fromTime2[1] == toTime2[1]) || // Check if fromHour & toHour and fromMinute & toMinute is same.
              (fromTime2[0] == toTime2[0] &&
                Number(fromTime2[1]) > Number(toTime2[1])) || // Check if fromHour & toHour is same and fromMinute is greater than toMinute.
              Number(fromTime2[0]) > Number(toTime2[0])
            ) {
              // Check if fromHour is greater than toHour.
              setTimeRangeError(
                <Alert className="m-4 " severity="error">
                  {TIME_RANGE_ERROR_MESSAGE}
                </Alert>
              );
              return;
            }
          }
        });
      }
    });
  };

  return (
    <>
      <section className="mt-0 p-4" aria-labelledby="gallery-heading">
        <div className="">
          <Formik
            initialValues={intialFormValues}
            validationSchema={dateProposalPageValidationSchema}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col py-4 md:grid md:grid-cols-6 lg:grid-cols-8 gap-1"
                onChange={formHandleChange}
              >
                <div className="md:col-span-6">
                  <div className="col-span-4">
                    <CheckBox
                      id="Monday"
                      name="Monday"
                      type="checkbox"
                      onChange={handleChange}
                      label="Monday"
                      checked={values.Monday}
                      width={5}
                    />
                    {values.Monday === true ? (
                      <div className="row md:col-span-4">
                        <TimeList
                          add={(e) => {
                            addNewRow("Monday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Monday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Tuesday"
                      name="Tuesday"
                      type="checkbox"
                      label="Tuesday"
                      onChange={handleChange}
                      checked={values.Tuesday}
                      width={5}
                    />
                    {values.Tuesday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Tuesday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Tuesday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Wednesday"
                      name="Wednesday"
                      type="checkbox"
                      label="Wednesday"
                      onChange={handleChange}
                      checked={values.Wednesday}
                      width={5}
                    />
                    {values.Wednesday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Wednesday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Wednesday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Thursday"
                      name="Thursday"
                      type="checkbox"
                      label="Thursday"
                      onChange={handleChange}
                      checked={values.Thursday}
                      width={5}
                    />
                    {values.Thursday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Thursday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Thursday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Friday"
                      name="Friday"
                      type="checkbox"
                      label="Friday"
                      onChange={handleChange}
                      checked={values.Friday}
                      width={5}
                    />
                    {values.Friday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Friday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Friday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Saturday"
                      name="Saturday"
                      type="checkbox"
                      label="Saturday"
                      onChange={handleChange}
                      checked={values.Saturday}
                      width={5}
                    />
                    {values.Saturday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Saturday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Saturday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="my-4">
                    <CheckBox
                      id="Sunday"
                      name="Sunday"
                      type="checkbox"
                      label="Sunday"
                      onChange={handleChange}
                      checked={values.Sunday}
                      width={5}
                    />
                    {values.Sunday === true ? (
                      <div className="row">
                        <TimeList
                          add={(e) => {
                            addNewRow("Sunday");
                          }}
                          delete={clickOnDelete.bind(this)}
                          timeDetails={timeDetails}
                          day="Sunday"
                          validateTimeRange={validateTimeRange.bind(this)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {page === "editDate" ? (
                  <div className="col-start-1 col-span-8 md:col-start-1 xl:col-start-1 xl:col-span-2 md:col-span-3 mt-10">
                    <Button type="submit" label="Update" textsize="base" />
                  </div>
                ) : (
                  <div className="col-start-1 col-span-8 md:col-start-1 xl:col-start-1 xl:col-span-4 md:col-span-5 mt-4">
                    <div className=" flex space-x-2">
                      <Button
                        label="Back"
                        textsize="base"
                        onClick={() => onBack(sections.Budget)}
                        background="white"
                        bordercolor="primary"
                        color="primary"
                      />
                      <Button
                        type="submit"
                        label="Next"
                        textsize="base"
                        bordercolor="primary"
                      />
                    </div>
                  </div>
                )}
                <div className="col-start-1 col-span-8 -mx-4">
                  {error}
                  {timeRangeError}
                  {duplicateTimeRangeError}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
