/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ProfileView from "./ProfileView";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserById,
  updateUser,
  changePassword,
} from "../../redux/actions/UserActions";
import localStorageService from "../../services/localStorageService";
import { getCountries } from "redux/actions/CountryAction";
import { getTechniques } from "redux/actions/TechniqueAction";

export default function ProfileContainer(props) {
  const [selectedTab, setSelectedTab] = useState("1");
  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.error);
  const [userData, setUserData] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const countries = useSelector((state) => state.country.data);
  const techniques = useSelector((state) => state.technique.data);
  const [alertMessageIcon, setAlertMessageIcon] = useState("success");

  useEffect(() => {
    let loggedUser = localStorageService.getItem("auth_user");
    setUserData(loggedUser);

    if (selectedTab === "1") {
      dispatch(getUserById(loggedUser.id));
      dispatch(getCountries());
      dispatch(getTechniques());
    }
  }, [selectedTab, dispatch]);

  const onSubmit = (obj) => {
    obj.id = userData.id;
    if (selectedTab === "1") {
      dispatch(updateUser(obj)).then((res) => {
        if (res.type == "UPDATE_USER_ERROR") {
          setShowAlert(true);
          setAlertMessage(res.payload.data);
          let loggedUser = localStorageService.getItem("auth_user");
          setUserData(loggedUser);
        } else {
          setUserData(res.payload.user);
          localStorageService.setItem("auth_user", res.payload.user);
          setAlertMessage("User Saved");
          setShowAlert(true);
        }
      });
    } else {
      dispatch(changePassword(obj)).then((res) => {
        if (res && res.type === "ADD_USER") {          
          setAlertMessage("Password Updated");
          setShowAlert(true);
          setAlertMessageIcon("success");
        } else if (res && res.type === "USER_CREATE_ERROR") {
          setAlertMessageIcon("error");
          setAlertMessage(res?.payload?.data?.error?.message);
          setShowAlert(true); 
        }
      });
    }
  };

  return (
    <div>
      <ProfileView
        onSubmit={onSubmit}
        error={error}
        userDataObj={userData}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loginData={props.loginData}
        artistReviewsAvg={props.artistReviewsAvg}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
        countries={countries}
        techniques={techniques}
        alertMessageIcon={alertMessageIcon}
      />
    </div>
  );
}
