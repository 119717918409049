import { Slider } from "@mui/material";

const CustomSlider = (props) => {
  return (
    <Slider
      value={props.value}
      sx={{
        color: "#C7A33F",
        "& .MuiSlider-thumb": {
          color: "#C7A33F",
          width: "15px",
          height: "15px",
        },
        "& .Mui-active": {
          color: "#C7A33F",
        },
        "& .MuiSlider-rail": {
          // Default Bar Color
          color: "#AFAFAF",
          height: "2px",
        },
        "& .MuiSlider-track": {
          // Selected Bar
          color: "#C7A33F",
          height: "2px",
        },
        "& .MuiSlider-valueLabel": {
          display: props.displayLabel ? props.displayLabel : "none",
          fontSize: 14,
          fontWeight: "bold",
          top: 50,
          right: -5,
          backgroundColor: "unset",
          color: "#C7A33F",
          "&:before": {
            display: "none",
          },
          "& *": {
            background: "transparent",
            color: "#000",
          },
        },
        "& .MuiSlider-mark": {
          height: 8,
        },
        "& .MuiSlider-markLabel": {
          display: props.displayLabel ? "none" : "true",
          transform: "translateX(-100%)",
          '&[data-index="0"]': {
            transform: "none",
          },
          fontSize: 14,
          fontWeight: "bold",
        },
      }}
      onChange={(e, newValue) => {
        props.setValue(newValue);
      }}
      marks={[
        {
          value: props.min,
          label: (
            <div>
              {props.min} {props.unit ? props.unit : ""}
            </div>
          ),
        },
        {
          value: props.max,
          label: (
            <div>
              {props.max} {props.unit ? props.unit : ""}
            </div>
          ),
        },
      ]}
      min={props.min}
      max={props.max}
      valueLabelDisplay={props.valueLabelDisplay === false ? "off" : "on"}
      size="small"
      disabled={props.disabled ? props.disabled : false}
    />
  );
};

export default CustomSlider;
