/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AccountingView from "./AccountingView";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvoices,
  getGraphsData,
  getStatisticsData,
} from "redux/actions/AccountingAction";

const paymentMethods = [
  { id: 1, name: "Paypal", value: "paypal", email: "aa@gamil.com" },
  { id: 2, name: "Google Pay", value: "googlePay", email: "go@gamil.com" },
  { id: 3, name: "Paytm", value: "paytm", email: "yo@gamil.com" },
];

const AccountingContainer = (props) => {
  const dispatch = useDispatch();
  const accountingState = useSelector((state) => state.accounting);
  const graphsState = useSelector((state) => state.graphsData);
  const [invoicesScreen, setInvoices] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const queryString = `start=0&limit=5`;
    if (!invoicesScreen || refresh) {
      setRefresh(false);
      dispatch(getInvoices(queryString));
      dispatch(getGraphsData());
      dispatch(getStatisticsData());
    }
  }, [invoicesScreen, refresh]);

  const applyFilters = (queryString) => {
    dispatch(getInvoices(queryString));
  };


  return (
    <AccountingView
      loginData={props.loginData}
      paymentMethods={paymentMethods}
      accountingState={accountingState}
      setInvoices={setInvoices}
      invoicesScreen={invoicesScreen}
      applyFilters={applyFilters}
      graphsState={graphsState}
      setRefresh={setRefresh}
    />
  );
};

export default AccountingContainer;
