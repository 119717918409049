import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { skinTheme } from "styles/muiTheme";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100% !important",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${skinTheme.palette.primary.main} !important`,
    borderRadius: "10px !important",
  },
  cssLabel: {
    color: `${skinTheme.palette.primary.main} !important`,
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${skinTheme.palette.primary.main} !important`,
    },
  },
  inputLabel: {
    fontsize: "1px !important",
  },
}));

const CustomInput = (props) => {
  const classes = useStyles();
  return (
    <TextField
      id="outlined-basic"
      color="primary"
      variant="outlined"
      disabled = {props.disabled ? props.disabled : false}
      multiline={props.multiline ? props.multiline : false}
      minRows={props.rows ? undefined : props.minRows ? props.minRows : 1}
      //    variant={props.error ? "outlined-error" : "outlined"}
      className={classes.input}
      inputProps={{
        style: {
          fontSize: 14,
        },
      }}
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
          input: classes.inputLabel,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      {...props}
    />
  );
};

export default CustomInput;
