/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import localStorageService from "services/localStorageService";
import NotificationView from "./NotificationView";
import { useDispatch, useSelector } from "react-redux";
import {
  markNotificationsAsReadforUser,
  getUnReadNotications,
  getAllNotifications,
  markNotificationRowAsRead
} from "redux/actions/NotificationAction";
import { getUserRole } from "../../layouts/ArtistConstants";
import { useHistory } from "react-router-dom";

const NotificationContainer = (props) => {
  // const [data, setData] = useState(localStorageService.getItem("notification"));
  const data = useSelector((state) => state?.notification?.update?.notification);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {    
      dispatch(getAllNotifications(props.loginData.id));     
  }, []);


  const onMarkRead = () => {
    dispatch(markNotificationsAsReadforUser(props.loginData.id)).then((res) => {
      if (res && res.type === "NOTIFICATION_SUCCESS") {
        dispatch(getUnReadNotications(props.loginData.id)).then((res1) => { 
          if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
            localStorageService.setItem(
              "notification",
              res1?.payload?.notification
            );
            window.location.reload();
          }
        });
      }
    });
  };

  const onMarkRowRead = (rowId, redirectUrl) => {
    dispatch(markNotificationRowAsRead(rowId)).then((res) => {
      if (res && res.type === "NOTIFICATION_SUCCESS") {
        dispatch(getUnReadNotications(props.loginData.id)).then((res1) => {
          if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
            localStorageService.setItem(
              "notification",
              res1?.payload?.notification
            );
            history.replace({
              pathname: redirectUrl,
            });   
          }
        });
      }
    });
  }

  const user_role = getUserRole(props?.loginData?.userRoleId);

  return (
    <div>
      <NotificationView
        data={data}
        loginData={props.loginData}
        onMarkRead={onMarkRead}
        role={user_role}
        onMarkRowRead={onMarkRowRead}
      />
    </div>
  );
};

export default NotificationContainer;
