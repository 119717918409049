/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../../../assets/ic_search.svg";
import { SERVER_URL } from "utils/constants";

const ClientList = (props) => {
  const [clientStatus, setClientStatus] = useState(0);
  const [searchText, setSearchText] = useState("");

  let clientStatuses = [
    {
      id: 0,
      name: "All",
    },
    {
      id: 1,
      name: "Blocked",
    },
  ];

  const handleClick = (id) => {
    props.onClientSelect(id);
  };

  // let optionItems;
  if (clientStatuses && clientStatuses.length > 0) {
    clientStatuses.map((clientStatus) => (
      <option
        className="text-primary shadow-none border-0 focus:border-0"
        key={clientStatus.id}
        value={clientStatus.id}
      >
        {clientStatus.name}
      </option>
    ));
  }

  return (
    <div className="h-full flex flex-1 flex-col">
      <div className="text-2xl font-semibold font-sans text-primary mb-6">
        Clients
      </div>
      <div className="w-full md:flex md:flex-row items-center mb-5">
        <div className="pl-3 rounded-xl py-2 mb-0 bg-tertiary bg-opacity-10 flex flex-row items-center h-full">
          <SearchIcon className="h-4 w-4" />
          <input
            className="text-black mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none"
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
          ></input>
        </div>
        {/* <div className="mb-0 border-red border rounded-xl flex items-center pl-7 pr-2 py-2 md:ml-5 mt-4 md:mt-0">
          <select
            className="font-sans text-base font-normal w-full appearance-none shadow-none border-0 focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent"
            name="studioId"
            id="studioId"
            onChange={(e) => {
              setClientStatus(e.target.value);
            }}
            value={clientStatus}
          >
            {optionItems}
          </select>
          <DropdownArrow className="ml-5" />
        </div> */}

        {/* Blocked */}
        {/* </div> */}
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table
                className="min-w-full md:table-fixed flex-col md:flex-row flex-1"
                id="more-tables"
              >
                <thead className="lg:flex lg:flex-1 mb-2">
                  <tr className="lg:flex lg:flex-1">
                    <th
                      scope="col"
                      className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6 whitespace-nowrap"
                    >
                      Birth Year
                    </th>
                    <th
                      scope="col"
                      className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6 whitespace-nowrap"
                    >
                      Projects
                    </th>
                    {/* <th
                      scope="col"
                      className="font-sans text-tiny font-normal text-tertiary lg:flex lg:flex-1 text-left px-6"
                    >
                      Gender
                    </th> */}
                  </tr>
                </thead>
                <tbody className="">
                  {props?.clientData?.user?.map(
                    (row) =>
                      (row?.fullName + row?.lastName)
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) && (
                        //TO BE CHECKED
                        <tr
                          key={row.id}
                          className="lg:flex lg:flex-1 mt-3 items-center border-b border-tableLine"
                          onClick={(e) => handleClick(row.id)}
                        >
                          <td
                            data-title="Name"
                            className="lg:flex lg:flex-1 whitespace-nowrap px-6 py-1 items-center"
                          >
                            {" "}
                            <div className="flex flex-row items-center">
                              <img
                                className="mr-4 h-8 w-8 bg-gray-300 rounded-full flex-shrink-0 border border-tertiary object-cover object-center"
                                src={
                                  row.photoUrl
                                    ? SERVER_URL + row.photoUrl
                                    : SERVER_URL +
                                      "uploads/default-images/DefaultProfile.jpeg"
                                }
                                alt=""
                              />
                              <div className="">
                                {row?.fullName} {row?.lastName}
                              </div>
                            </div>
                          </td>
                          <td
                            data-title="Birth"
                            className="lg:flex lg:flex-1 px-6"
                          >
                            {row?.birthYear}
                          </td>
                          <td
                            data-title="Project"
                            className="lg:flex lg:flex-1 px-6"
                          >
                            {row?.ptotal}
                          </td>
                          {/* <td className="lg:flex lg:flex-1 px-6">
                            {" "}
                            {row?.gender === "Female" ? (
                              <div className="flex flex-row items-center">
                                <FemaleIcon />
                                <div className="flex items-center ml-2 text-female">
                                  {row?.gender}
                                </div>
                              </div>
                            ) : row?.gender === "male" ? (
                              <div className="flex flex-row items-center">
                                <MaleIcon />
                                <div className="flex items-center ml-2 text-male">
                                  {row?.gender}
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center">
                                <GenderIcon />
                                <div className="flex items-center ml-2 text-tertiary">
                                  Unspecified
                                </div>
                              </div>
                            )}
                          </td> */}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientList;
