import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "components/UI/Button";
import { sections } from "../views/project/CONSTANTS";
import CustomSlider from "./UI/Slider";

export default function EditBudget(props) {
  let iniFromPrice;
  let iniToPrice;

  if (props?.page === "editBudget") {
    iniFromPrice = props?.data?.fromPrice;
    iniToPrice = props?.data?.toPrice;
  }
  if (props?.storedProjectState?.budgetForm) {
    iniFromPrice = parseInt(
      props?.storedProjectState?.budgetForm?.get("fromPrice")
    );
    iniToPrice = parseInt(
      props?.storedProjectState?.budgetForm?.get("toPrice")
    );
  }

  let intialFormValues = {
    fromPrice: iniFromPrice ? iniFromPrice : 100,
    toPrice: iniToPrice ? iniToPrice : 500,
  };

  const validatedFromPice = intialFormValues?.fromPrice
    ? intialFormValues?.fromPrice - 5000 < 0
      ? 0
      : intialFormValues?.fromPrice - 5000
    : 0;
  const validatedToPice = intialFormValues.toPrice
    ? intialFormValues?.toPrice + (props?.page === "editBudget" ? 0 : 5000)
    : 5000;

  const placeYourTattoValidationSchema = yup.object({
    fromPrice: yup
      .number()
      .required("From price is required.")
      .positive()
      .integer()
      .min(
        validatedFromPice + 1,
        "From price should be greater than " + validatedFromPice + "."
      )
      .max(
        validatedToPice,
        "From price should be less than " + validatedToPice + "."
      )
      .lessThan(yup.ref("toPrice"), "From price should be less than To price"),
    toPrice: yup
      .number()
      .required("To price is required.")
      .positive()
      .integer()
      .min(
        validatedFromPice + 2,
        "To price should be greater than " + validatedFromPice + 1 + "."
      )
      .max(
        validatedToPice,
        "To price should be less than " + validatedToPice + "."
      ),
  });

  const [value, setValue] = useState([
    iniFromPrice ? iniFromPrice : 100,
    iniToPrice ? iniToPrice : 500,
  ]); //slider

  const handleFormSubmit = (values) => {
    if (props?.page === "editBudget") {
      let obj = {
        budget: {
          fromPrice: values.fromPrice,
          toPrice: values.toPrice,
          loggedInUserId: props.loggedInUserId,
        },
      };
      if (props?.isNegotiate) {
        obj.status = {};
        obj.status.statusId = 1;
        obj.budget.isPriceNegotiated = true;
      }

      props.onSubmit(obj);
      props.setOpen(false);
    } else {
      let obj = new FormData();
      obj.append("fromPrice", values.fromPrice);
      obj.append("toPrice", values.toPrice);
      props.setStoredProjectState((storedProjectState) => ({
        ...storedProjectState,
        "budgetForm": obj,
      }));
      props.onSubmit(obj, sections.DateProposal, "budgetForm");
    }
  };

  return (
    <>
      <section className="mt-0 pb-12" aria-labelledby="gallery-heading">
        <div className="">
          <Formik
            initialValues={intialFormValues}
            validationSchema={placeYourTattoValidationSchema}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col py-2 grid grid-cols-8 gap-4"
                encType="multipart/form-data"
              >
                <div className="col-span-3 md:col-span-2 xl:col-span-2">
                  <span className="text-sm font-sans text-tertiary">From</span>
                  <input
                    className="border border-tertiary rounded-lg font-sans text-base p-3 box-border w-full focus:outline-none"
                    type="text"
                    name="fromPrice"
                    id="fromPrice"
                    onChange={(e) => {
                      const fromPrice = e.target.value;
                      const toPrice = value[1];
                      setFieldValue("fromPrice", fromPrice);
                      setValue([fromPrice && fromPrice !== '' ? parseInt(fromPrice) :0 , toPrice]);
                    }}
                    onBlur={handleBlur}
                    value={values.fromPrice}
                    label="From Price"
                    maxLength="5"
                  />
                  <p className="text-red text-sm">
                    {errors.fromPrice && touched.fromPrice && errors.fromPrice}
                  </p>
                </div>
                <div className="col-span-3 md:col-span-2 xl:col-span-2">
                  <span className="text-sm font-sans text-tertiary">To</span>
                  <input
                    className="border border-tertiary rounded-lg font-sans text-base p-3 box-border w-full focus:outline-none"
                    type="text"
                    name="toPrice"
                    id="toPrice"
                    onChange={(e) => {
                      const fromPrice = value[0];
                      const toPrice = e.target.value;
                      setFieldValue("toPrice", toPrice);
                      setValue([fromPrice, toPrice && toPrice !== '' ? parseInt(toPrice) :0]);
                    }}
                    onBlur={handleBlur}
                    value={values.toPrice}
                    label="To Price"
                    maxLength="4"
                  />
                  <p className="text-red">
                    {errors.toPrice && touched.toPrice && errors.toPrice}
                  </p>
                </div>
                <div className="col-start-1 col-span-7 mt-8">
                  <CustomSlider
                    value={value}
                    setValue={(newValue) => {
                      setValue(newValue);
                      setFieldValue("fromPrice", newValue[0]);
                      setFieldValue("toPrice", newValue[1]);
                    }}
                    min={0}
                    max={5500}
                    // min={
                    //   intialFormValues.fromPrice
                    //     ? intialFormValues.fromPrice - 5000 < 0
                    //       ? 0
                    //       : intialFormValues.fromPrice - 5000
                    //     : 0
                    // }
                    // max={
                    //   intialFormValues.toPrice
                    //     ? intialFormValues.toPrice +
                    //       (props?.page === "editBudget" ? 0 : 5000)
                    //     : 5000
                    // }
                  />
                </div>
                {props?.page === "editBudget" ? (
                  <div className="col-start-1 col-span-8 md:col-start-1 xl:col-start-1 xl:col-span-2 md:col-span-3 mt-10">
                    <Button type="submit" label="Update" textsize="base" />
                  </div>
                ) : (
                  <div className="col-start-1 col-span-8 md:col-start-1 xl:col-start-1 xl:col-span-4 md:col-span-5 mt-4 flex space-x-2">
                    <Button
                      label="Back"
                      textsize="base"
                      onClick={() => props.onBack(sections.PlaceYourTatto)}
                      background="white"
                      bordercolor="primary"
                      color="primary"
                    />
                    <Button
                      type="submit"
                      label="Next"
                      textsize="base"
                      bordercolor="primary"
                    />
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
