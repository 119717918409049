/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getClientCurrentProjects,
  getClientById,
  getClientProjectsHistory,
} from "redux/actions/ClientAction";
import ClientHomeView from "./ClientHomeView";
import { CLIENT_PROJECT_TABS as TABS } from "layouts/ClientConstants";

const ClientHomeContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(TABS.CURRENTPROJECTS.id);
  const selectedClientId = props.loginData.id;

  const clientState = useSelector((state) => state.client);
  const clientCurrentProjects = useSelector(
    (state) => state.client.clientsProjects
  );
  const clientHistoryProjects = useSelector(
    (state) => state.client.clientsProjectsHistory
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientById(selectedClientId));
  }, [selectedTab, dispatch]);

  useEffect(() => {   
    dispatch(getClientCurrentProjects(selectedClientId));
    dispatch(getClientProjectsHistory(selectedClientId));
  }, []);

  useEffect(() => {
    if (selectedTab === TABS.CURRENTPROJECTS.id) {
      //dispatch(getClientById(selectedClientId));
      dispatch(getClientCurrentProjects(selectedClientId));
    } else if (selectedTab === TABS.PROJECTHISTORY.id) {
      //dispatch(getClientById(selectedClientId));
      dispatch(getClientProjectsHistory(selectedClientId));
    }
  }, [selectedTab, dispatch]);
  return (
    <div>
      <ClientHomeView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        clientState={clientState}
        loginData={props.loginData}
        clientCurrentProjects={clientCurrentProjects}
        clientHistoryProjects={clientHistoryProjects}
      />
    </div>
  );
};

export default ClientHomeContainer;
