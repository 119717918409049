/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Image, Line } from "react-konva";
import useImage from "use-image";
// import { isMobile } from "utils/helper";

const URLImage = ({ image, boundingRect }) => {
  const [img] = useImage(image);
  return (
    <Image
      image={img}
      // width={boundingRect.width - (isMobile() ? 2 : 2)}
      width={boundingRect.width -  2}
      height={boundingRect.height}
      perfectDrawEnabled={false}
      listening={false}
      scaleX={1}
      scaleY={1}
      draggable={false}
    />
  );
};

const PolyDrawKonva = (props) => {
  const tool = "pen";
  const lineColor = "#A389FF";
  const inputRef = useRef(null);
  const isDrawing = useRef(false);
  const [lines, setLines] = useState([]);
  const [history, setHistory] = useState([]);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();

    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    lastLine.points = lastLine.points.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => {
    const stage = inputRef.current.getStage().content;

    function preventDefault(e) {
      e.preventDefault();
    }

    const handleMouseDown = (e) => {
      document.body.style.overflow = "hidden";
      preventDefault(e);
    };

    const handleMouseUp = () => {
      document.body.style.overflow = "auto";
    };

    stage.addEventListener("mousedown", handleMouseDown);
    stage.addEventListener("touchstart", handleMouseDown, { passive: false });

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      stage.removeEventListener("mousedown", handleMouseDown);
      stage.removeEventListener("touchstart", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (props.undo) {
      handleUndo();
      props.setUndo(false);
    }
  }, [props.undo]);

  useEffect(() => {
    if (props.redo) {
      handleRedo();
      props.setRedo(false);
    }
  }, [props.redo]);

  useEffect(() => {
    if (props.reset) {
      handleReset();
      props.setReset(false);
    }
  }, [props.reset]);

  const handleReset = () => {
    setLines([]);
    setHistory([]);
  };

  const handleUndo = () => {
    if (lines.length === 0) {
      return;
    }
    const tmp = [...lines];
    var popped = tmp.pop();
    setLines(tmp);
    setHistory([...history, popped]);
  };

  const handleRedo = () => {
    if (history.length === 0) {
      return;
    }
    const tmp = [...history];
    var popped = tmp.pop();
    setHistory(tmp);
    setLines([...lines, popped]);
  };

  return (
    <Stage
      ref={inputRef}
      width={props.dimensions.width}
      height={props.dimensions.height}
      onMouseDown={!props.disableDraw ? handleMouseDown : null}
      onMousemove={!props.disableDraw ? handleMouseMove : null}
      onMouseUp={!props.disableDraw ? handleMouseUp : null}
      onTouchStart={!props.disableDraw ? handleMouseDown : null}
      onTouchMove={!props.disableDraw ? handleMouseMove : null}
      onTouchEnd={!props.disableDraw ? handleMouseUp : null}
    >
      {/* <Layer width={props.dimensions.width} height={props.dimensions.height}> */}
      <Layer>
        <URLImage image={props.modelPicture} boundingRect={props.dimensions} />
        {lines.map((line, i) => (
          <Line
            key={i}
            points={line.points}
            stroke={lineColor}
            strokeWidth={2}
            tension={0.5}
            lineCap="round"
            lineJoin="round"
            globalCompositeOperation={(line.tool = "source-over")}
          />
        ))}
      </Layer>
    </Stage>
  );
};

export default PolyDrawKonva;
