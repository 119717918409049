/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { createArtist } from "../../../redux/actions/UserActions";
import StudioVerificationView from "./StudioVerificationView";
// import { getInvitation } from "../../../redux/actions/ArtistModuleAction";
import { getUserByVerifyString, updateVerifyString } from "../../../redux/actions/UserActions";

export default function StudioVerificationContainer(props) {
  const verifyString = props.match.params.verifyString;  
  const error = useSelector((state) => state.user.error);
  const data = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(getUserByVerifyString(verifyString));
  }, [verifyString]);

  const onSubmit = (passData) => {    
    let obj = {
      id: data.id,
      password: passData.password
    };
    dispatch(updateVerifyString(obj));    
    history.push("/auth/login");
  } 

  return (
    <div>
      <StudioVerificationView onSubmit={onSubmit} error={error} data={data}  />
    </div>
  );
}
