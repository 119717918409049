/* eslint-disable eqeqeq */
import React, { useState } from "react";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations, VIEWS } from "layouts/ArtistConstants";
import ImageCarouselSlider from "components/ImageCarouselSlider";
import { ReactComponent as CheckPending } from "../../../assets/ic_pending.svg";
import { ReactComponent as CheckDone } from "../../../assets/ic_check_gray.svg";
import { ReactComponent as CheckCancelled } from "../../../assets/ic_cancelled.svg";

export default function PreviewArtistPortFolioView(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [imageComment, setImageComment] = useState(
    props.isTmpArtistPortfolioId == 1
      ? props.artistPortfolio?.tmpImg[0].comment
      : props.artistPortfolio?.artist_portfolio_images[0].comment
  );
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };
  return (
    <>
      <div className="h-full w-full flex">
        <div className="flex-1 flex flex-col overflow-hidden w-full h-full bg-white">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={props.loginData}
            hideMenuOptions={"true"}
          />
          {/* Content area */}
          <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
            {/* Main content */}
            <div className="bg-white md:m-5 w-full flex flex-1">
              <main className="overflow-hidden w-full flex flex-1">
                <section
                  className="mt-0 pb-0 w-full flex flex-1"
                  aria-labelledby="gallery-heading"
                >
                  {props.artistPortfolio && (
                    <div className="flex flex-col lg:grid lg:grid-cols-2 w-full m-4 flex-1">
                      <div className="w-full flex flex-1 flex-col">
                        <ImageCarouselSlider
                          images={
                            props?.isTmpArtistPortfolioId == 1
                              ? props?.artistPortfolio?.tmpImg
                              : props?.artistPortfolio?.artist_portfolio_images
                          }
                          setImageComment={setImageComment}
                          artistPortfolioId={props.artistPortfolio.id}
                          isTmpArtistPortfolioId={props.isTmpArtistPortfolioId}
                          openLink={true}
                        />
                        <p className="flex text-black text-base mt-4 pr-10 flex-1 w-full">
                          {imageComment}
                        </p>
                      </div>
                      <div className="col-start-2 flex-1 flex-col w-full lg:ml-4">
                        <div className="flex flex-row items-center flex-1">
                          <div className="font-semibold text-3xl mb-2 text-primary">
                            {props.artistPortfolio.styleTitle}{" "}
                          </div>
                          <div className="flex flex-1" />
                          <a
                            href={`/artist/artistportfolio/${VIEWS.PREVIEW_ARTIST_PORTFOLIO.id}/${props.artistPortfolio.id}/${props.isTmpArtistPortfolioId}`}
                            className={"text-primary font-semibold text-base"}
                          >
                            Edit
                          </a>
                        </div>

                        <div className="flex flex-row flex-1 w-full items-center">
                          {props?.artistPortfolio?.portfolioStatusId === 2 ? (
                            <CheckDone className="mr-2" fill="#afafaf" />
                          ) : props?.artistPortfolio?.portfolioStatusId ===
                            3 ? (
                            <CheckCancelled className="mr-2" />
                          ) : (
                            <CheckPending className="mr-2" />
                          )}
                          <span
                            className={`flex text-${
                              props?.artistPortfolio?.portfolioStatusId === 2
                                ? "tertiary"
                                : props?.artistPortfolio?.portfolioStatusId ===
                                  3
                                ? "cancelled"
                                : "pending"
                            } text-sm font-normal`}
                          >
                            {props.isTmpArtistPortfolioId == 1
                              ? props.artistPortfolio.status.statusName
                              : props.artistPortfolio.portfolio_status
                                  .statusName}
                          </span>
                        </div>
                        <div className="text-white text-base flex flex-1 w-full">
                          <div className="bg-primary rounded px-2 py-1 mt-4">
                            {props.artistPortfolio.style.styleName}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
