/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

import Profile from "views/profile/ProfileContainer.js";
import SignOut from "views/SignOut";
import StudioHomeContainer from "views/studio/StudioHome/StudioHomeContainer";
import StudioReviewContainer from "views/studio/StudioReview/StudioReviewContainer";
import ProjectDetailsContainer from "views/project/projectDetails/ProjectDetailsContainer";

export default function StudioLayout(props) {
  const [loginData, setloginData] = useState(props.loginData)
  return (
    <>
      <main>
        <Switch>
          <Route path="/" exact render={(props) => <StudioHomeContainer {...props} loginData={loginData}/>} />
          <Route path="/admin/profile" exact render={(props) => <Profile {...props} loginData={loginData}/>} />
          <Route path="/admin/signout" component={SignOut} />
          <Route path="/studio/main-menu" exact render={(props) => <StudioHomeContainer {...props} loginData={loginData}/>} />
          <Route path="/studio/reviews/:id" exact render={(props) => <StudioReviewContainer {...props} loginData={loginData}/>} />
          <Route path="/studio/project-details/:id" exact render={(props) => <ProjectDetailsContainer {...props} loginData={loginData}/>}/>
          
        </Switch>
      </main>
    </>
  );
}
