/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import Dropdown from "./Dropdown";
import Button from "components/UI/Button";
import AlertMessage from "../../../../components/AlertMessage";
import { DEFAULT_COUNTRY } from "utils/constants";
import Country from "components/UI/Country";

const supportFormValidationSchema = yup.object({
  fullName: yup.string().required("Name is required"),
  userTypeId: yup.string().required("User Type is required"),
  email: yup.string().email("Enter valid Email").required("Email is required"),
  phone: yup.string()
  .matches(/(\d){9}\b/, "Enter a valid mobile number")
  .max(10, "Enter a valid mobile number")
  .required(`Phone Number is required`),
  address: yup.string().max(100, ({ max }) => "Address can be of maximum 100 characters long").required("Address is required"),
  city: yup.string().required("City is required"),
  countryId: yup.string().required("Country is required"),
  subjectId: yup.string().required("Subject is required"),
  description: yup.string().required("Description is required"),
});

export default function SupportForm(props) {
  const [userTypeOptions, setUserTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);  
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);

  const initialVales = {
    fullName: "",
    userTypeId: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    countryId: "",
    subjectId: "",
    description: "",
    phoneCode: "+41",
  };

  let countryFiltered = props?.countries?.filter((rec) => {
    return rec.name === DEFAULT_COUNTRY      
  });
  if (countryFiltered && countryFiltered.length > 0) {
    initialVales.countryId = countryFiltered[0].id;
  }

  useEffect(() => {
    populateUserType();
    populateCountry();   
    populateSubjects();
    populatePhoneCode();
  }, [props.userTypes, props.countries, props.subjects]);

  useEffect(() => {
    if (props.ticketCreateSuccess === true) {
      formik.resetForm();
    }
  }, [props.ticketCreateSuccess]);

  const populateUserType = () => {
    let userTypes = props.userTypes;
    let userTypeOptionItems = [];
    if (userTypes && userTypes.length > 0) {
      userTypeOptionItems = userTypes.map((item) => (
        <option key={item.id} value={item.id}>
          {item.userType.charAt(0).toUpperCase() + item.userType.slice(1).toLowerCase()}          
        </option>
      ));
    }
    setUserTypeOptions(userTypeOptionItems);
  };

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.id} value={country.id}>
          {country.name}
        </option>
      ));      
    }
    setCountryOptions(countryOptionItems);
  };

  const populateSubjects = () => {
    let subjects = props.subjects;
    let subjectOptionItems = [];
    if (subjects && subjects.length > 0) {
      subjectOptionItems = subjects.map((subject) => (
        <option key={subject.id} value={subject.id}>
          {subject.subjectName}
        </option>
      ));
    }
    setSubjectOptions(subjectOptionItems);
  };

  const populatePhoneCode = () => {
    let phoneCodeOptionItems = [];
    if (props.countries && props.countries.length > 0) {
      phoneCodeOptionItems = props.countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  

  const handleFormSubmit = (values) => {
    props.onSubmit({
      fullName: values.fullName,
      userTypeId: values.userTypeId,
      email: values.email,
      phone: values.phone,
      address: values.address,
      city: values.city,
      countryId: values.countryId,
      subjectId: values.subjectId,
      description: values.description,
      phoneCode: values.phoneCode
    });
  };
  
  const formik = useFormik({
    initialValues: initialVales,
    validationSchema: supportFormValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <div className="w-full">
      <FormikProvider value={formik}>
        <div className="">
          <form onSubmit={formik.handleSubmit} className="">
            <div>
              <Input
                type="text"
                name="fullName"
                id="fullName"
                label="Full Name"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="text-red font-sans">
                {formik.errors.fullName &&
                  formik.touched.fullName &&
                  formik.errors.fullName}
              </p>
            </div>
            <div>
              <Dropdown label="I'm a">
                <select
                  className="w-full bg-white border-transparent focus:outline-none"
                  id="userTypeId"
                  name="userTypeId"
                  value={formik.values.userTypeId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=""></option>
                  {userTypeOptions}
                </select>
              </Dropdown>
              <p className="text-red font-sans">
                {formik.errors.userTypeId &&
                  formik.touched.userTypeId &&
                  formik.errors.userTypeId}
              </p>
            </div>
            <div>
              <Input
                type="text"
                name="email"
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="text-red font-sans">
                {formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email}
              </p>
            </div>
            {/* <div>
              <Input
                type="text"
                name="phone"
                id="phone"
                label="Phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="text-red font-sans">
                {formik.errors.phone &&
                  formik.touched.phone &&
                  formik.errors.phone}
              </p>
            </div> */}
            <div>
            <Country
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              phonecode={formik.values.phoneCode}
              phonecodeoptions={phoneCodeOptions}
              phone={formik.values.phone}
            />
            <p className="text-red">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </p>
          </div>
            <div>
              <div className="border border-primary rounded-2xl mt-4 py-2 px-3 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
                <label htmlFor="address" className="block text-xs font-medium text-primary font-sans">Address</label>
                <div className={`flex flex-row`}>
                  <textarea
                    type="text"
                    name="address"
                    id="address"
                    label="Address"                  
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={3}
                    className="font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                </div>
              </div>
              <p className="text-red font-sans">
                {formik.errors.address &&
                  formik.touched.address &&
                  formik.errors.address}
              </p>
            </div>
            <div>
              <Input
                type="text"
                name="city"
                id="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="text-red font-sans">
                {formik.errors.city &&
                  formik.touched.city &&
                  formik.errors.city}
              </p>
            </div>
            <div>
              <Dropdown label="Country">
                <select
                  className="w-full bg-white border-transparent focus:outline-none"
                  id="countryId"
                  name="countryId"
                  value={formik.values.countryId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {countryOptions}
                </select>
              </Dropdown>
              <p className="text-red font-sans">
                {formik.errors.countryId &&
                  formik.touched.countryId &&
                  formik.errors.countryId}
              </p>
            </div>
            <div>
              <Dropdown label="Subject">
                <select
                  className="w-full bg-white border-transparent  focus:outline-none"
                  id="subjectId"
                  name="subjectId"
                  value={formik.values.subjectId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=""></option>
                  {subjectOptions}
                </select>
              </Dropdown>
              <p className="text-red font-sans">
                {formik.errors.subjectId &&
                  formik.touched.subjectId &&
                  formik.errors.subjectId}
              </p>
            </div>
            <div className="mb-12">
              <div className="border border-primary rounded-2xl mt-4 py-2 px-3 shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary">
                <label htmlFor="description" className="block text-xs font-medium text-primary font-sans">Description</label>
                <div className={`flex flex-row`}>
                  <textarea
                    type="text"
                    name="description"
                    id="description"
                    label="Description"                  
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={8}
                    className="font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                </div>
              </div>
              <p className="text-red font-sans">
                {formik.errors.description &&
                  formik.touched.description &&
                  formik.errors.description}
              </p>
            </div>
            <div className="md:col-start-1 lg:col-start-2 lg:col-span-2 lg:pb-0 sm:col-span-1 sm:flex-col sm:justify-start lg:px-0 mb-10 md:mb-0 mt-6 xl:mt-0 xl:col-start-3 xl:col-span-2">
              <Button type="submit" label="Submit" textsize="base" />
            </div>
            <AlertMessage
              message={props.alertMessage}
              showAlert={props.showAlert}
              setShowAlert={props.setShowAlert}
              onAlertClose={() => {
                formik.resetForm();
              }}
            />
          </form>
        </div>
      </FormikProvider>
    </div>
  );
}
