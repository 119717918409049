/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Body } from "./body";

export function Male(props) {
  const { nodes, materials } = useGLTF("/male.glb");

  const boneRef = useRef(null);
  const skeletonRef = useRef(null);

  useEffect(() => {
    Body(skeletonRef.current);
  }, [skeletonRef]);

  let material = materials.lambert3;

  const skeleton = useMemo(() => {
    return (
      <group
        frustumCulled={false}
        scale={props.scale}
        position-y={props.positiony}
        dispose={null}
      >
        <group
          frustumCulled={false}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          <primitive ref={boneRef} object={nodes.mixamorigHips} />
          <skinnedMesh
            frustumCulled={false}
            ref={skeletonRef}
            geometry={nodes.head.geometry}
            material={material}
            skeleton={nodes.head.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.left_arm.geometry}
            material={material}
            skeleton={nodes.left_arm.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.left_leg.geometry}
            material={material}
            skeleton={nodes.left_leg.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            name="hand"
            geometry={nodes.right_arm.geometry}
            material={material}
            skeleton={nodes.right_arm.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.right_leg.geometry}
            material={material}
            skeleton={nodes.right_leg.skeleton}
          />
          <skinnedMesh
            frustumCulled={false}
            geometry={nodes.torso.geometry}
            material={material}
            skeleton={nodes.torso.skeleton}
          />
        </group>
      </group>
    );
  }, []);

  return <>{skeleton}</>;
}

useGLTF.preload("/male.glb");
