/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import {
  USER_ROLE_BOSS,
  USER_ROLE_ARTIST,
} from "../../layouts/ArtistConstants";
import { useDispatch } from "react-redux";
import {
  markNotificationsAsReadforUser,
  getUnReadNotications,
  markNotificationRowAsRead,
} from "redux/actions/NotificationAction";
import localStorageService from "services/localStorageService";
import { SERVER_URL, BOSS_USER_NAME, BOSS_USER_ID } from "utils/constants";
import moment from "moment";
import { XIcon } from "@heroicons/react/outline";

export default function NotificationPopUpView(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => props.setOpen(false);
  let redirectUrl = "";
  const [rowData, setRowData] = useState(
    localStorageService.getItem("notification")
  );

  if (props.role === USER_ROLE_BOSS) {
    redirectUrl = "/admin/";
  } else if (props.role === USER_ROLE_ARTIST) {
    redirectUrl = "/artist/";
  } else {
    redirectUrl = "/client/";
  }

  const onClickSeeAll = () => {
    history.push(redirectUrl + "notification");
  };

  const onMarkRead = () => {
    dispatch(markNotificationsAsReadforUser(props.loginData.id)).then((res) => {
      if (res && res.type === "NOTIFICATION_SUCCESS") {
        dispatch(getUnReadNotications(props.loginData.id)).then((res1) => {
          if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
            localStorageService.setItem(
              "notification",
              res1?.payload?.notification
            );
            window.location.reload();
          }
        });
      }
    });
  };

  const onMarkRowRead = (rowId, redirectUrl) => {
    dispatch(markNotificationRowAsRead(rowId)).then((res) => {
      if (res && res.type === "NOTIFICATION_SUCCESS") {
        dispatch(getUnReadNotications(props.loginData.id)).then((res1) => {
          if (res1 && res1.type === "NOTIFICATION_SUCCESS") {
            localStorageService.setItem(
              "notification",
              res1?.payload?.notification
            );
            history.push(redirectUrl);
          }
        });
      }
    });
  };

  const rowOnClick = (url, id) => {
    onMarkRowRead(id, redirectUrl + url);
    handleClose();
  };

  useEffect(() => {
    setRowData(localStorageService.getItem("notification"));
  }, [localStorageService.getItem("notification").length]);

  return (
    <div>
      <Transition.Root show={props.open}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto "
          onClose={handleClose}
        >
          <div className="flex items-start justify-center md:justify-end max-h-screen">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className="pr-0"
            >
              <div
                className="md:inline-block align-top bg-white overflow-hidden rounded-xl p-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-top 
                xl:max-w-sm lg:max-w-sm md:max-h-4/5 max-w-sm mx-2 my-4"
              >
                <div className="absolute top-1 right-0">
                  <button
                    type="button"
                    className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={handleClose}
                  >
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="h-full w-full">
                  <div className="flex flex-col">
                    <div className="overflow-x-auto mb-8">
                      <div className="text-lg font-bold font-sans">
                        Notifications
                      </div>
                      <div className="py-2 align-middle flex">
                        <div className="overflow-hidden">
                          <table className="flex-col md:flex-row">
                            <thead className="flex flex-1 mb-4">
                              <tr className="flex flex-1">
                                <th
                                  scope="col"
                                  className="font-sans text-sm font-normal text-primary text-left whitespace-nowrap"
                                >
                                  <div
                                    onClick={() => onMarkRead()}
                                    hidden={rowData?.length == 0}
                                  >
                                    Mark all as read
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  className="font-sans text-tiny font-normal text-tertiary flex flex-1 text-left px-0"
                                ></th>
                                <th
                                  scope="col"
                                  className="font-sans text-sm font-normal text-primary text-left whitespace-nowrap"
                                >
                                  <div onClick={() => onClickSeeAll()}>
                                    See all
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="flex flex-col">
                              {rowData?.length > 0 &&
                                rowData?.map((row) => (
                                  <div>
                                    <tr
                                      key={row.id}
                                      className="flex mb-2"
                                      //onClick={() => rowOnClick(row?.redirectUrl)}
                                      onClick={() =>
                                        rowOnClick(row?.redirectUrl, row.id)
                                      }
                                      style={
                                        row?.isRead
                                          ? {}
                                          : { backgroundColor: "#FFF3E0" }
                                      }
                                    >
                                      <td className="lg:flex ">
                                        {" "}
                                        <div className="flex flex-row items-top">
                                          <img
                                            className="mr-4 h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 border border-tertiary object-cover object-center"
                                            src={
                                              row?.createdBy?.photoUrl
                                                ? SERVER_URL +
                                                  row?.createdBy?.photoUrl
                                                : SERVER_URL +
                                                  "uploads/default-images/DefaultProfile.jpeg"
                                            }
                                            alt=""
                                          />
                                          <div className="font-semibold text-base font-sans">
                                            {row?.createdBy.id ===
                                            BOSS_USER_ID ? (
                                              <span> {BOSS_USER_NAME} </span>
                                            ) : (
                                              <span>
                                                {row?.createdBy?.firstName}{" "}
                                                {row?.createdBy?.lastName}{" "}
                                              </span>
                                            )}

                                            <span className="text-tertiary pl-1 font-sans">
                                              {row?.subject}
                                            </span>
                                            <div className="lg:flex font-sans text-tiny font-normal text-tertiary">
                                              {moment(row?.createdAt).from(
                                                moment(new Date())
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                ))}
                              {rowData?.length == 0 && (
                                <p className="flex flex-1 w-full font-sans text-tertiary text-s mx-8 ">{"No Unread Messages"}</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
