/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import TopNavBar from "components/Navbars/TopNavBar";
import SideNavBar from "components/Navbars/SideNavBar";
import {
  navigations,
  userNavigations,
  USER_ROLE_MANGER_ID,
} from "layouts/AdminConstants";
import CurrentProjects from "./components/CurrentProjects";
import ProjectsHistory from "./components/ProjectsHistory";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import CustomSlider from "components/UI/Slider";
// import SessionExpiry from "components/SessionExpiry";
import NewProjects from "./components/NewProjects";
import localStorageService from "../../../services/localStorageService";
import TabNavBar from "components/Navbars/TabNavBar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ReactComponent as GridIcon } from "../../../assets/ic_grid.svg";
import { ReactComponent as MapIcon } from "../../../assets/ic_map.svg";
import ProjectsOnMap from "./components/ProjectsOnMap";
import CustomInput from "components/CustomInput";

import {
  setHichemProjectMode,
  setHichemProjectTab,
} from "redux/actions/TabStateAction";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_DISABLE_FLAG } from "utils/constants";

const ProjectsView = ({
  loginData,
  selectedTab,
  setSelectedTab,
  projectList,
  stylesData,
  projectOnClick,
  faqState,
  onSubmit,
  showForm,
  setForm,
  selectedOption,
  setSelectedOption,
  options,
  deleteFaq,
  editFaq,
  editedData,
  projectError,
}) => {
  // const [lang, setSelectedLang] = useState("1");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFilter, setShowFIlter] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const [searchText, setSearchText] = useState("");
  const [cost, setCost] = useState([0, 10000]);
  const [size, setSize] = useState([0, 10000]);
  const TabState = useSelector((state) => state.TabState);
  const [mode, setMode] = useState(TabState.HICHEM_PROJECT_MODE);
  const [projectfilteredData, setProjectfilteredData] = useState("");
  const [styleFilter, setStyleFilter] = useState();
  const dispatch = useDispatch();
  const getProjectCount = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        return projectList?.projectsNew?.project?.length;
      case 2:
        return projectList?.projectsCurrent?.project?.length;
      case 3:
        return projectList?.projectsHistory?.project?.length;
      default:
        return 0;
    }
  };

  const [tabs, setTabs] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if(width >= 768) {
      setShowFIlter(true);
    }
  }, [])
  

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    dispatch(setHichemProjectTab(tabToSet.id));
    localStorageService.setItem("admin_project_tab", tabToSet.id);
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true, count: getProjectCount(tab.id) }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  useEffect(() => {
    setTabs([
      {
        id: "1",
        name: "New Projects",
        href: "#",
        current: selectedTab == "1",
        count: getProjectCount(1),
      },
      {
        id: "2",
        name: "Current Projects",
        href: "#",
        current: selectedTab == "2",
        count: getProjectCount(2),
      },
      {
        id: "3",
        name: "Project History",
        href: "#",
        current: selectedTab == "3",
        count: getProjectCount(3),
      },
      {
        id: "4",
        name: "Market Place",
        href: "#",
        current: selectedTab == "4",
        count: 0,
        isDisabled: HIDE_DISABLE_FLAG,
      },
    ]);
  }, [projectList]);

  useEffect(() => {
    let finalData = "";

    if (selectedTab === "1" && projectList?.projectsNew?.project) {
      finalData = projectList?.projectsNew?.project;
    } else if (selectedTab === "2" && projectList?.projectsCurrent?.project) {
      finalData = projectList?.projectsCurrent?.project;
    } else if (selectedTab === "3" && projectList?.projectsHistory?.project) {
      finalData = projectList?.projectsHistory?.project;
    }

    if (finalData && size && size.length > 0) {
      let projectfilteredData = finalData?.filter((pro) => {
        return (
          pro.tatooHeight * pro.tatooWidth >= size[0] &&
          pro.tatooHeight * pro.tatooWidth <= size[1]
        );
      });
      finalData = projectfilteredData;
    }

    if (finalData && cost && cost.length > 0) {
      let projectfilteredData = finalData?.filter((pro) => {
        return pro.budgetPriceTo >= cost[0] && pro.budgetPriceTo <= cost[1];
      });
      finalData = projectfilteredData;
    }

    //search text
    if (finalData && searchText && searchText !== "") {
      let projectfilteredData = finalData.filter((rec) => {
        return (
          (rec.user.firstName &&
            rec.user.firstName
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1) ||
          (rec.user.lastName &&
            rec.user.lastName
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1)
        );
      });

      finalData = projectfilteredData;
    }

    //styles
    if (finalData && stylesData && stylesData.length > 0) {
      const styleIds = stylesData
        .filter((item) => item.selected === true)
        .map((a) => a.id);
      if (styleIds.length > 0) {
        let projectfilteredData = finalData.filter((rec) => {
          return (
            rec.client_proposed_styles &&
            rec.client_proposed_styles.length > 0 &&
            styleIds.includes(rec.client_proposed_styles[0].styleId)
          );
        });
        finalData = projectfilteredData;
      }
    }

    setProjectfilteredData(finalData);
    setStyleFilter(false);
  }, [
    projectList,
    cost,
    size,
    selectedTab,
    searchText,
    stylesData,
    styleFilter,
  ]);

  let grid;
  if (selectedTab === "1") {
    grid =
      mode == 1 ? (
        <NewProjects
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          projectList={projectfilteredData}
          projectOnClick={projectOnClick}
        />
      ) : (
        // Map
        <ProjectsOnMap
          projectList={projectfilteredData}
          projectOnClick={projectOnClick}
        />
      );
  } else if (selectedTab === "2") {
    grid =
      mode == 1 ? (
        <CurrentProjects
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          projectList={projectfilteredData}
          projectOnClick={projectOnClick}
        />
      ) : (
        // Map
        <ProjectsOnMap
          projectList={projectfilteredData}
          projectOnClick={projectOnClick}
        />
      );
  } else if (selectedTab === "3") {
    grid = (
      <ProjectsHistory
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        projectList={projectfilteredData}
        projectOnClick={projectOnClick}
      />
    );
  }

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={loginData}
        />
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full w-full">
          <SideNavBar
            navigations={navigations(
              loginData.userRoleId !== USER_ROLE_MANGER_ID
            )}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            bg={"bgg"}
            loginID={loginData.id}
          />
          <div className="flex-1 flex">
            <main className="flex flex-1">
              <div className="flex flex-1 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mx-2 md:mx-4">
                {/* Search Box */}
                <div className="border-0 border-primary pt-7 pr-0 md:pr-6 lg:pr-2">
                  <div className="items-center">
                    <div className="pl-1 py-2 rounded-xl bg-bgg flex flex-1 items-center">
                      <SearchIcon className="h-4 w-4" />
                      <input
                        className="text-black mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none"
                        placeholder="Search"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={() => setShowFIlter(!showFilter)}
                      className="text-center text-primary font-bold mt-4"
                    >
                      {showFilter ? "Hide Filters" : "Show Filters"}
                    </div>
                  </div>
                  <div hidden={!showFilter}>
                    <span className="font-normal text-base text-tertiary flex mt-0 mb-4 flex-col grid grid-cols-8">
                      Cost:
                      <span className="px-2 col-start-1 col-span-8">
                        <CustomSlider
                          value={cost}
                          setValue={setCost}
                          min={0}
                          max={10000}
                          className=""
                          displayLabel={true}
                          valueLabelDisplay={false}
                        />
                      </span>
                      <div className="col-start-1 col-span-2">
                      <CustomInput
                        label="Min"
                        type="text"
                        value={cost[0]}   
                        inputProps={{
                          maxLength: 8    
                        }}    
                        onChange={(e) => {
                          setCost([e.target.value, cost[1]]);
                        }}                                                 
                      />
                    </div>
                    <div className="col-start-7 col-span-2">
                      <CustomInput
                        label="Max"
                        type="text"
                        value={cost[1]}   
                        inputProps={{
                          maxLength: 8    
                        }}    
                        onChange={(e) => {
                          setCost([cost[0], e.target.value]);
                        }}                                                 
                      />
                    </div>
                    </span>
                    
                    <span className="font-normal text-base text-tertiary flex mb-4 flex-col grid grid-cols-8">
                      <span className="font-normal text-base text-tertiary col-start-1 col-span-8">
                      Size: (cm<sup>2</sup>)
                      </span>
                      <span className="px-2 col-start-1 col-span-8">
                        <CustomSlider
                          value={size}
                          setValue={setSize}
                          min={0}
                          max={10000}
                          unit={
                            <span className="font-bold text-secondary">
                              cm<sup>2</sup>
                            </span>
                          }
                          displayLabel={true}
                          valueLabelDisplay={false}
                        />
                      </span>
                      <div className="col-start-1 col-span-2">
                      <CustomInput
                        label="Min"
                        type="text"
                        value={size[0]}   
                        inputProps={{
                          maxLength: 8    
                        }}    
                        onChange={(e) => {
                          setSize([e.target.value, size[1]]);
                        }}                                                 
                      />
                    </div>
                    <div className="col-start-7 col-span-2">
                      <CustomInput
                        label="Max"
                        type="text"
                        value={size[1]}   
                        inputProps={{
                          maxLength: 8    
                        }}    
                        onChange={(e) => {
                          setSize([size[0], e.target.value]);
                        }}                                                 
                      />
                    </div>
                    </span>

                    <span className="font-normal text-base text-tertiary flex my-4">
                      Style:
                    </span>
                    <div className="">
                      {stylesData &&
                        stylesData.length > 0 &&
                        stylesData.map((item) => (
                          <button
                            key={item.id}
                            className={`bg-${
                              item.selected ? "primary" : "white"
                            } border border-${
                              item.selected ? "white" : "primary"
                            } py-1 px-2 rounded-lg text-${
                              item.selected ? "white" : "primary"
                            } mr-1 mb-1`}
                            onClick={() => {
                              item.selected = item.selected
                                ? !item.selected
                                : true;
                              setStyleFilter(true);
                            }}
                          >
                            {item.styleName}
                          </button>
                        ))}
                    </div>
                    <button
                      className="text-primary text-base mt-5"
                      onClick={() => {
                        setCost([0, 10000]);
                        setSize([0, 10000]);
                        stylesData.map((item) => {
                          item.selected = false;
                        });
                      }}
                    >
                      <u>Reset</u>
                    </button>
                  </div>
                </div>
                {/* Tabs */}
                <div className="border-0 border-primary md:col-start-2 md:col-span-2 lg:col-span-3 px-0">
                  <div
                    className={`flex flex-row md:flex-col lg:flex-row items-center border-b border-primaryBorder ${
                      selectedTab == "3" ? "py-3" : ""
                    }`}
                  >
                    <TabNavBar
                      tabs={tabs}
                      handleChange={setTab}
                      showBorder={false}
                    />
                    <div className="flex flex-1" />
                    <div hidden={selectedTab == "3"}>
                      <ToggleButtonGroup
                        exclusive
                        aria-label="text alignment"
                        sx={{
                          "& .MuiToggleButtonGroup-grouped": {
                            border: 0,
                            "&.Mui-disabled": {
                              border: 0,
                            },
                          },
                        }}
                      >
                        <ToggleButton
                          value={mode == "1" ? "yes" : "no"}
                          onClick={() => {
                            setMode(1);
                            dispatch(setHichemProjectMode(1));
                          }}
                          aria-label="left aligned"
                        >
                          <GridIcon
                            fill={mode == "1" ? "#C7A33F" : "#C7A33F33"}
                          />
                        </ToggleButton>
                        <ToggleButton
                          value={mode == "2" ? "yes" : "no"}
                          onClick={() => {
                            setMode(2);
                            dispatch(setHichemProjectMode(2));
                          }}
                          aria-label="justified"
                        >
                          <MapIcon
                            fill={mode == "2" ? "#C7A33F" : "#C7A33F33"}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                  {grid}
                </div>
              </div>
            </main>
            {/* <SessionExpiry sessionError={projectError} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsView;
