import { ReactComponent as home } from "../assets/SideBar/ico_side_home.svg";
import { ReactComponent as account } from "../assets/SideBar/ico_side_account.svg";
import { ReactComponent as artist } from "../assets/SideBar/ico_side_artist.svg";
import { ReactComponent as client } from "../assets/SideBar/ico_side_client.svg";
import { ReactComponent as project } from "../assets/SideBar/ico_side_project.svg";
import { ReactComponent as support } from "../assets/SideBar/ico_side_support.svg";
import { ReactComponent as rating } from "../assets/SideBar/ico_side_rating.svg";
import { ReactComponent as faq } from "../assets/SideBar/ico_side_faq.svg";
import { HIDE_DISABLE_FLAG } from "utils/constants";

export const USER_TYPE_MANAGER = 3;

export const NOTIFICATION_TYPE_NEW_PROJECT = 'NEW_PROJECT';
export const NOTIFICATION_TYPE_NEW_ARTIST = 'NEW_ARTIST';
export const NOTIFICATION_TYPE_NEW_REVIEW = 'CLIENT_NEW_REVIEW';
export const NOTIFICATION_TYPE_NEW_CLIENT = 'NEW_CLIENT';
export const NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE = 'NEW_MESSAGE(S)';

export const navigations = (isAdmin) => {
  return isAdmin ? AdminNavigations : ManagerNavigations
}

const AdminNavigations = [
  {
    id: "1",
    name: "Projects",
    href: "/",
    icon: project,
    current: true,
    type: NOTIFICATION_TYPE_NEW_PROJECT
  },
  {
    id: "3",
    name: "Artists",
    href: "/admin/artist",
    icon: artist,
    current: false,
    type: NOTIFICATION_TYPE_NEW_ARTIST
  },
  {
    id: "4",
    name: "Clients",
    href: "/admin/client",
    icon: client,
    current: false,
    type:  NOTIFICATION_TYPE_NEW_CLIENT
  },
  {
    id: "10",
    name: "Rating",
    href: "/admin/reviews",
    icon: rating,
    current: false,
    type: NOTIFICATION_TYPE_NEW_REVIEW
  },
  {
    id: "7",
    name: "Support",
    href: "/admin/support",
    icon: support,
    current: false,
    type: NOTIFICATION_TYPE_NEW_SUPPORT_MESSAGE
  },
  { id: "6", name: "Manager", href: "/admin/manager", icon: home, current: false },
  {
    id: "2",
    name: "Accounting",
    href: "/admin/accounting",
    icon: account,
    current: false,
  },
  // {
  //   id: "5",
  //   name: "Newsletter",
  //   href: "/admin/newsletter",
  //   icon: news,
  //   current: false,
  // },
  // { id: "8", name: "Chat", href: "/admin/chat", icon: chat, current: false, isDisabled: HIDE_DISABLE_FLAG },
  // {
  //   id: "9",
  //   name: "Withdrawn",
  //   href: "/admin/withdrawn",
  //   icon: withdrawn,
  //   current: false,
  //   isDisabled: HIDE_DISABLE_FLAG
  // },

  { id: "11", name: "FAQ", href: "/admin/faq", icon: faq, current: false },
];

const ManagerNavigations = [
  {
    id: "1",
    name: "Projects",
    href: "/",
    icon: project,
    current: false,
    type: NOTIFICATION_TYPE_NEW_PROJECT
  },
  {
    id: "3",
    name: "Artists",
    href: "/admin/artist",
    icon: artist,
    current: false,
    type: NOTIFICATION_TYPE_NEW_ARTIST
  },
  {
    id: "4",
    name: "Clients",
    href: "/admin/client",
    icon: client,
    current: false,
    type: NOTIFICATION_TYPE_NEW_CLIENT
  },
  { id: "6", name: "Home", href: "/admin/manager", icon: home, current: true }
];

export const userNavigations = [
  { id: "1", name: "Your profile", href: "/admin/profile" },
  { id: "2", name: "Sign out", href: "/admin/signout" },
];

export const tabTypes = {
  "My Managers": "1",
  "Partner Studios Managers": "3",
};

export const studioTypes = {
  "My Studio": "1",
  "Partner Studio": "2",
};

export const HOMETABS = Object.freeze({  
  MYMANAGERS: { id: "1"},
  MYSTUDIOS: { id: "2"},
  PARTNERSTUDIOMANAGERS:{ id: "3"},
  PARTNERSTUDIO: { id: "4"}
});

export const projectTabs = {
  "New Projects": "1",
  "Current Projects": "2",
  "Project History": "3",
  "Market Place": "4",
};

export const MAPS_KEY = "AIzaSyBY-i52KA34bee8MjWFYKiqc2XRoUaSt6Y";

export const defaultMapProps = {
  // Zurich
  center: {
    lat: 47.36667,
    lng: 8.55,
  },
  zoom: 11,
};

export const REVIEWS_TABS = Object.freeze({
  ALL: { id: 1, name: "All" },
  NEW: { id: 2, name: "New" },
});

export const ARTIST_RATING_STATUS = Object.freeze({
  PUBLISH: { id: 1, name: "Publish" },
  UNDISCLOSE: { id: 2, name: "Undisclose" },
});

export const MapIcon = {
  path: "M11.339,26.666a1.811,1.811,0,0,1-.978-.33,28.684,28.684,0,0,1-7.387-6.854A13.846,13.846,0,0,1,0,11.09,10.891,10.891,0,0,1,3.337,3.245,11.374,11.374,0,0,1,11.324,0,11.231,11.231,0,0,1,22.667,11.09a13.853,13.853,0,0,1-2.974,8.392A29.227,29.227,0,0,1,12.3,26.336,1.759,1.759,0,0,1,11.339,26.666ZM11.324,7.7a3.737,3.737,0,0,0-3.732,3.733,3.615,3.615,0,0,0,1.1,2.606,3.764,3.764,0,0,0,6.387-2.606A3.766,3.766,0,0,0,11.324,7.7Z",
  fillColor: "#C7A33F",
  fillOpacity: 1,
  strokeColor: "#C7A33F",
};

export const SUPPORT_TABS = Object.freeze({
  OUR_PROJECTS: { id: 1, name: 'Our Projects' },
  MARKET_PLACE: { id: 2, name: 'Market Place', isDisabled: HIDE_DISABLE_FLAG},
  MARKED_AS_FINISHED_REPORT: { id: 3, name: 'Marked as finished report', isDisabled: HIDE_DISABLE_FLAG},
  GENERAL: { id: 4, name: 'General' },
  CLIENT: { id: 5, name: 'Client' },
  ARTIST: { id: 6, name: 'Artist' }
});

// Based on Portal
export const USER_TYPE_CLIENT = 1
export const USER_TYPE_ARTIST = 2
export const USER_TYPE_STUDIO = 3
export const USER_TYPE_HICHEM = 4

export const USER_ROLE_CLIENT = 4
export const USER_ROLE_MANGER_ID = 5

export const CLIENT_PROJECT_TABS = Object.freeze({
  CURRENTPROJECTS: { id: "1", name: "Current Projects" },
  PROJECTHISTORY: { id: "2", name: "Project History" },
});


