import moment from "moment";
import React from "react";
import { getDaysByInt, SERVER_URL } from "utils/constants";
import { USER_ROLE_PROJECT_ADMIN, USER_ROLE_CLIENT } from "../../CONSTANTS";
import { useHistory } from "react-router-dom";

const FinalArtistInfo = (props) => {
  let history = useHistory();
  let cShow = true;
  if (props?.role === USER_ROLE_PROJECT_ADMIN || props?.role === "Admin") {
    cShow = false;
  }

  let apptDate = props?.userdata?.counter_offer_dates.filter(
    (counterOffDt) => counterOffDt.statusId === 1
  );

  const showArtistProfile = (user) => {
    if (props?.role === USER_ROLE_CLIENT) {
      history.push("/client/artist-profile/" + user.studio_artists[0].userId);
    }
    if (props?.role === USER_ROLE_PROJECT_ADMIN) {
      history.push("/admin/artist/" + user.studio_artists[0].userId);
    }
  };

  return (
    <div>      
      {apptDate && apptDate.length > 0 &&
      <div>
        <div className="flex flex-row gap-52">
          <div className="text-sm text-gray mb-4 mt-5">{"Date & Time:"}</div>
        </div>
        <div>
        {getDaysByInt(parseInt(apptDate[0].day)).slice(0, 3) +
          ", " +
          moment(apptDate[0].offerDate).format("MM.DD.YY") +
          " | " +
          apptDate[0].fromTime}
        </div>
      </div>
      }      
      <div className="flex flex-row gap-52">
        <div className="text-sm text-gray mb-0 mt-10">{"Artist:"}</div>
      </div>
      <div className="mt-2 w-full" onClick={() => showArtistProfile(props.userdata.user)}>
        <div className="font-bold flex flex-row items-center mt-2 mb-10 gap-0 flex-1 w-full">
          <img
            className="h-12 w-12 rounded-full mt-0"
            src={
              props.userdata.user.photoUrl
                ? SERVER_URL + props.userdata.user.photoUrl
                : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
            }
            alt="profile"
          />
          <div className="w-4"></div>
          <div className="w-full font-sans font-black text-base">
            {props.userdata.user.firstName + " " + props.userdata.user.lastName}
          </div>
        </div>
      </div>
      {cShow && (
        <div className="mt-10 mb-10 w-full flex flex-row">
          <div className="font-bold flex-none items-top mt-0 w-16">
            <img
              className="h-12 w-12 rounded-full mt-0 mr-4 object-cover min-w-12"
              src={
                props.userdata.studio.imageUrl
                  ? SERVER_URL + props.userdata.studio.imageUrl
                  : `${SERVER_URL}uploads/default-images/DefaultStudio.svg`
              }
              alt="profile"
            />
          </div>
          <div className="flex flex-col ml-0 gap-1 mt-0 mb-4 text-gray">
            <div className="font-bold text-black">
              {props.userdata.studio.placeName}
            </div>
            <div>
              {(props?.userdata?.studio?.phoneCode) ? "(" + (props?.userdata?.studio?.phoneCode) + ")" : "" }{" "}
              {props.userdata.studio.phoneNumber}
            </div>
            <div>
              {props.userdata.studio.street +
                " " +
                props.userdata.studio.zipcode}
            </div>
            <div> {props.userdata.studio.city}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinalArtistInfo;
