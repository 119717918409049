/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {userNavigations } from "layouts/StudioConstants";
import TopNavBar from "components/Navbars/TopNavBar";
import { TABS, tabsNav, ADMINISTRATIONVIEWS } from "layouts/StudioConstants";
import TabNavBar from "components/Navbars/TabNavBar";
import CurrentArtists from "../components/CurrentArtists";
import CurrentProjects from "../StudioProjects/CurrentProjects";
import ProjectsHistory from "../StudioProjects/ProjectsHistory";
import ManagerGrid from "components/ManagerGrid";
import { tabTypes } from "layouts/AdminConstants";
import EditProfile from "components/EditProfile";
import ChangePassword from "components/ChangePassword";
import ManagerAddEdit from "components/ManagerAddEdit";
import Earnings from "../components/Earnings";
import { SERVER_URL } from "utils/constants";
import { ReactComponent as EditPicture } from "../../../assets/ico_edit_picture_white.svg";
// import SessionExpiry from "components/SessionExpiry";

export default function StudioView({
  selectedTab,
  setSelectedTab,
  loginData,
  studioState,
  managerState,
  selectedAdminView,
  setSelectedAdminView,
  userDataObj,
  onSubmit,
  selectedManagerId,
  setSelectedManagerId,
  artistState,
  studio,
  onDelete,
  studioError,
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [tabs, setTabs] = useState(tabsNav);

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  const setTab = (tabToSet) => {
    setSelectedTab(tabToSet.id);
    if (tabToSet.id === TABS.ADMINISTRATION.id) {
      setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
    }
    let updatedTabs = tabs.map((tab) =>
      tab.id === tabToSet.id
        ? { ...tab, current: true }
        : { ...tab, current: false }
    );
    setTabs(updatedTabs);
  };

  const handleClickEditProfile = (e) => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.PROFILE.id);
  };

  const handleClickCancelEditProfile = (e) => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
  };

  const handleClickChangePassword = (e) => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.CHANGEPASSWORD.id);
  };

  const handleClickCancelChangePassword = (e) => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.PROFILE.id);
  };

  const handleAddManager = () => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.ADDMANAGER.id);
  };

  const handleCancelAddManager = () => {
    setSelectedAdminView(ADMINISTRATIONVIEWS.MANAGERLIST.id);
  };

  const handleEditManager = (editId) => {
    setSelectedManagerId(editId);
    setSelectedAdminView(ADMINISTRATIONVIEWS.EDITMANAGER.id);
  };

  const getManagerGrid = () => {
    return (
      <ManagerGrid
        managers={managerState.getManagerByType}
        selectedTab={tabTypes["My Managers"]}
        roleId={loginData.userRoleId}
        typeId={loginData.userTypeId}
        handleAddManager={handleAddManager}
        handleEditManager={handleEditManager}
        loginData={loginData}
      />
    );
  };

  const getAdministrationScreen = (gridToShow) => {
    return (
      <main className="flex flex-1 grid grid-cols-12 w-full">
        <div className="col-span-12 lg:col-span-8 flex flex-col">
          <div className="flex flex-row">
            <div className="flex justify-center">
              <img
                className="bg-red w-24 h-24 rounded-2xl justify-right object-cover"
                src={
                  studio?.imageUrl
                    ? SERVER_URL + studio.imageUrl
                    : SERVER_URL + "uploads/default-images/DefaultStudio.svg"
                }
                alt='Studio'
              />
            </div>
            <div className="flex flex-col ml-5">
              <span>{studio?.placeName}</span>
              {countOfAdmins()}
            </div>
          </div>
          <div className="">
            {/* <ManagerGrid
                managers={managerState.getManagerByType}
                selectedTab={tabTypes["My Managers"]}
                roleId={loginData.userRoleId}
                typeId={loginData.userTypeId}
                handleAddManager={handleAddManager}
                handleEditManager={handleEditManager}
                loginData={loginData}
              /> */}
            {gridToShow}
          </div>
        </div>

        <div className="flex col-start-1 lg:col-start-10 bg-primary flex-col col-span-12 md:col-span-4 lg:col-span-3 rounded-2xl py-4 h-80 p-5">
          <div className="w-full flex items-center justify-center p-6 space-x-6">
            <div className="relative">
              <img
                className="h-28 w-28 bg-gray-300 rounded-full flex-shrink-0 border-primary border-2"
                src={
                  loginData?.photoUrl
                    ? SERVER_URL + loginData.photoUrl
                    : SERVER_URL + "uploads/default-images/DefaultProfile.jpeg"
                }
                alt=""
              />
              <button
                className="absolute top-20 right-0"
                onClick={handleClickEditProfile}
              >
                <EditPicture />
              </button>
              <input
                type="file"
                id="profile-pic-upload"
                style={{ display: "none" }}
                // onChange={(event) => {
                //   handleClickEditProfile
                // }}
              />
            </div>
          </div>

          <div className="pb-4">
            <p className="mt-2 text-white text-sm font-sans font-normal flex justify-center">
              {loginData.firstName} {loginData.lastName}
            </p>
            <p className="mt-2 text-white text-sm font-sans font-normal flex justify-center">
              {loginData.email}
            </p>
            <p className="mt-2 text-white text-sm font-sans font-normal flex justify-center">
              ({loginData.phoneCode}){loginData.phone}
            </p>
            {loginData.userRoleId === 1 ? (
              <p className="mt-2 text-white text-sm font-sans font-normal flex justify-center">
                Admin
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    );
  };

  const countOfAdmins = () => {
    var count = 0;

    managerState?.getManagerByType?.forEach((manager) => {
      if (manager.userRoleId == 1) {
        count++;
      }
    });
    return (
      <span className="text-tertiary">
        {" "}
        {count + (count > 1 ? " Admins" : " Admin")}
      </span>
    );
  };

  let grid;
  if (selectedTab === TABS.CURRENTPROJECTS.id) {
    grid = (
      <CurrentProjects
        selectedTab={selectedTab}
        studioProjects={studioState.studioCurrentProjects}
      />
    );
  } else if (selectedTab === TABS.PROJECTHISTORY.id) {
    grid = (
      <ProjectsHistory
        selectedTab={selectedTab}
        studioProjects={studioState.studioProjectsHistory}
      />
    );
  } else if (selectedTab === TABS.MARKETPLACE.id) {
    grid = <div></div>;
  } else if (selectedTab === TABS.CURRENTARTISTS.id) {
    grid = <CurrentArtists selectedTab={selectedTab} data={artistState.data} />;
  } else if (selectedTab === TABS.ADMINISTRATION.id) {
    if (selectedAdminView === ADMINISTRATIONVIEWS.MANAGERLIST.id) {
      grid = getAdministrationScreen(getManagerGrid());
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.PROFILE.id) {
      grid = getAdministrationScreen(
        <div>
          <EditProfile
            userDataObj={userDataObj}
            onSubmit={onSubmit}
            type={"STUDIO"}
            loginData={loginData}
            handleClickChangePassword={handleClickChangePassword}
            handleClickCancelEditProfile={handleClickCancelEditProfile}
          />
        </div>
      );
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.CHANGEPASSWORD.id) {
      grid = getAdministrationScreen(
        <div className="flex justify-right w-full h-full">
          <ChangePassword
            userDataObj={userDataObj}
            onSubmit={onSubmit}
            loginData={loginData}
            handleClickChangePassword={handleClickChangePassword}
            handleClickCancelChangePassword={handleClickCancelChangePassword}
          />
        </div>
      );
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.ADDMANAGER.id) {
      grid = getAdministrationScreen(
        <ManagerAddEdit
          onSubmit={onSubmit}
          loginData={loginData}
          handleCancelAddManager={handleCancelAddManager}
        />
      );
    } else if (selectedAdminView === ADMINISTRATIONVIEWS.EDITMANAGER.id) {
      grid = getAdministrationScreen(
        <ManagerAddEdit
          onSubmit={onSubmit}
          loginData={loginData}
          userDataObj={managerState.getManager}
          handleCancelAddManager={handleCancelAddManager}
          selectedManagerId={selectedManagerId}
          onDelete={onDelete}
        />
      );
    }
  } else if (selectedTab === TABS.EARNINGS.id) {
    grid = <Earnings data={studioState.studioEarnings} />;
  }

  return (
    <div>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
          <TopNavBar
            userNavigations={userNavigations}
            mobileMenuOpen={mobileMenuOpen}
            handleMenu={handleMenuOpen}
            loginData={loginData}
          />
          <div className="flex-1 flex flex-col overflow-hidden h-full w-full px-6">
            <TabNavBar tabs={tabs} handleChange={setTab} />
            <div className="container my-4">{grid}</div>
            {/* <SessionExpiry sessionError={studioError} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
