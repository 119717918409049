/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "utils/constants";
import { ReactComponent as Workplace } from "../../../assets/ic_workplace.svg";
import { ImageList, ImageListItem } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/ic_search.svg";
import Rating from "@mui/material/Rating";
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#C7A33F',
  },
  '& .MuiRating-iconHover': {
    color: '#AFAFAF',
  },
  '& .MuiRating-iconEmpty': {
    color: '#AFAFAF',
  },
});

const Artists = (props) => {
  let history = useHistory();

  const artistonClick = (artistId) => {
    history.push({
      pathname: "/admin/artist/" + artistId,
    });
  };

  const [studioOptions, setStudioOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [styleOptions, setStyleOptions] = useState([]);
  const [techniqueoptions, setTechniqueoptions] = useState([]);
  const [selectedStudioId, setSelectedStudioId] = useState("");
  const [selectedStyleId, setSelectedStyleId] = useState("");
  const [selectedTechId, setSelectedTechId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [artistfilteredData, setArtistfilteredData] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedSearchText, setSelectedSearchText] = useState("");

  const [studioValue, setStudioValue] = useState(0);
  const [styleValue, setStyleValue] = useState(0);
  const [techniqueValue, setTechniqueValue] = useState(0);
  const [searchTextValue, setSearchTextValue] = useState("");
  const [countryValue, setCountryValue] = useState(0);
  const [ratingValue, setRatingValue] = useState(0);

  const populateStudios = () => {
    let studios = props.studios;
    let studioOptionItems = [];
    if (studios && studios.length > 0) {
      studioOptionItems = studios.map((studio) => (
        <option key={studio.id} value={studio.id}>
          {studio.placeName}
        </option>
      ));
    }
    studioOptionItems.unshift(
      <option key={0} value={0}>
        {"All Studios"}
      </option>
    );
    setStudioOptions(studioOptionItems);
  };

  const populateCountry = () => {
    let countries = props.countries;
    let countryOptionItems = [];
    if (countries && countries.length > 0) {
      countryOptionItems = countries.map((country) => (
        <option key={country.name} value={country.name}>
          {country.name}
        </option>
      ));
    }

    countryOptionItems.unshift(
      <option key={0} value={0}>
        {"All Countries"}
      </option>
    );
    setCountryOptions(countryOptionItems);
  };

  const populateStyles = () => {
    let styles = props.styles;
    let stylesOptionItems = [];
    if (styles && styles.length > 0) {
      stylesOptionItems = styles.map((style) => (
        <option key={style.id} value={style.id}>
          {style.styleName}
        </option>
      ));
    }

    stylesOptionItems.unshift(
      <option key={0} value={0}>
        {"All Styles"}
      </option>
    );

    setStyleOptions(stylesOptionItems);
  };

  const populateTechniques = () => {
    let techniques = props.techniques;
    let techniqueOptionItems = [];
    if (techniques && techniques.length > 0) {
      techniqueOptionItems = techniques.map((technique) => (
        <option key={technique.id} value={technique.id}>
          {technique.techniqueName}
        </option>
      ));
    }

    techniqueOptionItems.unshift(
      <option key={0} value={0}>
        {"All Techniques"}
      </option>
    );

    setTechniqueoptions(techniqueOptionItems);
  };

  const onReset = () => {
    setStudioValue(0);
    setStyleValue(0);
    setTechniqueValue(0);
    setCountryValue(0);
    setRatingValue(0);
    setSelectedStudioId(0);
    setSelectedStyleId(0);
    setSelectedTechId(0);
    setSelectedCountry(0);
    setSelectedRating(0)
    setSelectedSearchText("");
    setSearchTextValue("");    
  }

  useEffect(() => {
    populateCountry();
    populateStudios();
    populateStyles();
    populateTechniques();
  }, [props.countries, props.techniques, props.styles, props.studios]);

  const currentStudioSelection = (val) => {
    setSelectedStudioId(val);
    setStudioValue(val);
  };

  const currentStyleSelection = (val) => {
    setSelectedStyleId(val);
    setStyleValue(val);
  };

  const currentTechSelection = (val) => {
    setSelectedTechId(val);
    setTechniqueValue(val);
  };

  const currentCountrySelection = (val) => {
    setSelectedCountry(val);
    setCountryValue(val);
  };

  const currentRatingSelection = (val) => {
    setSelectedRating(val);
    setRatingValue(val);
  };

  const currentTextSearchSelection = (val) => {
    setSelectedSearchText(val);
    setSearchTextValue(val)
  };

  useEffect(() => {
    let finalData = props?.data?.user;

    //studio
    if (selectedStudioId && selectedStudioId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.studio_artists.some((aStudio) => {
          return aStudio.studioId === parseInt(selectedStudioId);
        });
      });
      finalData = studiofilteredData;
    }

    //country
    if (selectedCountry && selectedCountry !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.country === selectedCountry;
      });

      finalData = studiofilteredData;
    }

    //style
    if (selectedStyleId && selectedStyleId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.artist_portfolios.some((aPortfolio) => {
          return aPortfolio.styleId === parseInt(selectedStyleId);
        });
      });
      finalData = studiofilteredData;
    }

    //technique
    if (selectedTechId && selectedTechId !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.artist_techniques.some((aTech) => {
          return aTech.techniqueId === parseInt(selectedTechId);
        });
      });
      finalData = studiofilteredData;
    }

    //rating
    if (selectedRating && selectedRating !== "0") {
      let studiofilteredData = finalData.filter((rec) => {
        return rec.artist_ratings.some((aRating) => {
          return aRating.rating === parseInt(selectedRating);
        });
      });
      finalData = studiofilteredData;
    }

    //search text
    if (selectedSearchText && selectedSearchText !== "") {
      let studiofilteredData = finalData.filter((rec) => {
        return (
          (rec.firstName &&
            rec.firstName
              .toLowerCase()
              .indexOf(selectedSearchText.toLowerCase()) !== -1) ||
          (rec.lastName &&
            rec.lastName
              .toLowerCase()
              .indexOf(selectedSearchText.toLowerCase()) !== -1)
        );
      });

      finalData = studiofilteredData;
    }

    setArtistfilteredData(finalData);
  }, [
    props?.data?.user,
    selectedStudioId,
    selectedCountry,
    selectedTechId,
    selectedStyleId,
    selectedRating,
    selectedSearchText,
  ]);

  return (
    <div className="my-10">
      {/* Search Bar */}
      <div className="flex md:grid md:grid-cols-10 flex-col">
        <div className="col-start-1 col-span-4 lg:col-span-3">
          <div className="p-2 bg-white border border-tertiary md:mx-4 rounded-xl">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              onChange={(e) => currentStudioSelection(e.target.value)}
              value={studioValue}
            >
              {studioOptions}
            </select>
          </div>
          <div className="p-2 bg-white border border-tertiary md:mx-4 rounded-xl mt-3">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              onChange={(e) => currentStyleSelection(e.target.value)}
              value={styleValue}
            >
              {styleOptions}
            </select>
          </div>
          <div className="hidden md:block py-1 px-4 mt-3 mb-3 md:mb-0">
            <div className="pl-3 rounded-xl py-2 mb-0 bg-tertiary bg-opacity-20 flex flex-row items-center">
              <SearchIcon className="h-4 w-4" />
              <input
                className="text-black mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none"
                placeholder="Search"
                onChange={(e) => currentTextSearchSelection(e.target.value)}
                value={searchTextValue}
              ></input>
            </div>
          </div>
        </div>
        <div className="col-start-5 lg:col-start-4 col-span-4 lg:col-span-3 mt-3 md:mt-0">
          <div className="p-2 bg-white border border-tertiary md:mx-4 rounded-xl">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              // value={values.nationality}
              onChange={(e) => currentCountrySelection(e.target.value)}
              value={countryValue}
            >
              {countryOptions}
            </select>
          </div>
          <div className="p-2 bg-white border border-tertiary md:mx-4 rounded-xl mt-3">
            <select
              className="w-full bg-white border-transparent focus:outline-none"
              // value={values.nationality}
              onChange={(e) => currentTechSelection(e.target.value)}
              value={techniqueValue}
            >
              {techniqueoptions}
            </select>
          </div>
          <div className="md:hidden py-1 md:x-4 mt-3 md:mb-3 md:mt-0">
            <div className="pl-3 rounded-xl py-2 mb-0 bg-tertiary bg-opacity-20 flex flex-row items-center">
              <SearchIcon className="h-4 w-4" />
              <input
                className="text-black mr-2 pl-2 pr-35 bg-opacity-0 flex bg-tertiary outline-none"
                placeholder="Search"
                onChange={(e) => currentTextSearchSelection(e.target.value)}
                value={searchTextValue}
              ></input>
            </div>
          </div>
          <div className="p-2 md:mx-4 md:mt-3">
            <StyledRating
              size="small"
              name="simple-controlled"
              onChange={(e) => currentRatingSelection(e.target.value)}
              getLabelText={(value) =>
                `${value} Heart${value !== 1 ? "s" : ""}`
              }
              value={ratingValue}
              // value={avg}
              // icon={<RatingIcon />}
              // emptyIcon={<RatingEmptyIcon />}
            />
          </div>
        </div>
        <div className="col-start-7 col-span-3"></div>
      </div>
      <button
        className="text-primary text-base mt-2 px-4 mb-8"
        onClick={() => {
          onReset();
        }}
      >
        <u>Reset</u>
      </button>
      {/* Grid */}
      <div className="flex flex-col gap-6">
        {artistfilteredData &&
          artistfilteredData?.map((items, index) => (
            <div
              className="flex flex-1 flex-col border-b border-tertiary pb-0 md:grid grid-cols-10 overflow-hidden"
              key={index.toString()}
              onClick={() => artistonClick(items.id)}
            >
              <div className="flex flex-row gap-4 col-start-1 col-span-3">
                <img
                  className="mr-4 h-12 w-12 bg-gray-300 rounded-full flex-shrink-0 border border-tertiary mt-0 object-cover object-center"
                  src={
                    items.photoUrl
                      ? SERVER_URL + items.photoUrl
                      : SERVER_URL +
                        "uploads/default-images/DefaultProfile.jpeg"
                  }
                  alt=""
                />
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row align-right">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-2">
                        <div className="text-base">
                          {items.firstName} {items.lastName} <span className="text-red text-xl">{(items.isBlocked) ? "(Blocked)" : ""}</span>
                        </div>
                        {items.artist_work_places.length > 0 && (
                          <div className="text-sm" style={{ color: "#b5b1a7" }}>
                            Public
                          </div>
                        )}
                      </div>
                      <div className="flex items-center mt-1">
                        {/* Average of rating */}
                        <StyledRating
                          name="simple-controlled"
                          value={items.artist_ratings.reduce(
                            (acc, v, i, a) => acc + v.rating / a.length,
                            0
                          )}
                          size="small"
                        />
                        {/* Number of ratings */}
                        <span className="ml-1 text-sm text-tertiary">
                          ({items.artist_ratings.length})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm text-tertiary font-sans">
                    {items.country ? items.country : "Country Not Selected"}
                  </div>

                  <div className="flex flex-col items-start">
                    {items.studio_artists.length == 0 && (
                      <div className="flex flex-row">
                        <Workplace className="mr-2 w-4 h-4" />

                        <div className="text-sm " style={{ color: "#b5b1a7" }}>
                          No Place
                        </div>
                      </div>
                    )}
                    {items.studio_artists.length > 0 &&
                      items.studio_artists.map((item, index) => (
                        <div key={index} className="flex flex-row">
                          <Workplace className="mr-2 w-4 h-4" />
                          <div key={index} className="text-sm font-sans">
                            {item.studio.placeName}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="text-primary text-sm text-wrap mb-6 flex flex-row flex-1">
                    {items?.artist_portfolios
                      ?.map((item) => " " + item.style.styleName)
                      .toString()}
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-0 ml-0 col-start-5 col-span-6">
                <ImageList
                  sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns:
                      "repeat(auto-fill,minmax(196px,1fr)) !important",
                    gridAutoColumns: "minmax(196px, 1fr)",
                  }}
                  gap={5}
                  cols={4}
                >
                  {items?.artist_portfolios?.map((a, i) =>
                    a.artist_portfolio_images?.map((b, j) => (
                      <ImageListItem key={j}>
                        <img
                          src={SERVER_URL + b.imageUrl}
                          alt=""
                          className="object-cover h-48 "
                        />
                      </ImageListItem>
                    ))
                  )}
                </ImageList>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Artists;
