/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getClientCurrentProjects,
  getClientById,
  getClientProjectsHistory,
} from "redux/actions/ClientAction";
import ClientProjectView from "./ClientProjectView";
import { CLIENT_PROJECT_TABS as TABS } from "layouts/AdminConstants";

const ClientProjectContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(TABS.CURRENTPROJECTS.id);
  const selectedClientId = props.match.params.id;

  const clientState = useSelector((state) => state.client);
  const dispatch = useDispatch();

  useEffect(() => {   
    dispatch(getClientCurrentProjects(selectedClientId));
    dispatch(getClientProjectsHistory(selectedClientId));
  }, []);

  useEffect(() => {
    if (selectedTab === TABS.CURRENTPROJECTS.id) {
      dispatch(getClientById(selectedClientId));
      dispatch(getClientCurrentProjects(selectedClientId));      
    } else if (selectedTab === TABS.PROJECTHISTORY.id) {
      dispatch(getClientById(selectedClientId));
      dispatch(getClientProjectsHistory(selectedClientId));      
    }
  }, [selectedTab, dispatch]);

  return (
    <div>
      <ClientProjectView
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        clientState={clientState}
        loginData={props.loginData}
      />
    </div>
  );
};

export default ClientProjectContainer;
