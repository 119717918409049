/* eslint-disable eqeqeq */
const CheckBox = ({
  width = "6",
  rounded = "md",
  labelshowaslink = false,
  textborder = null,
  label,
  onLabelClick,
  ...otherProps
}) => {
  return (
    <div className="flex items-center align-center font-sans">
      <input
        {...otherProps}
        type="checkbox"
        className={`form-checkbox h-${width} w-${width} rounded-${rounded} text-primary focus:ring-0 border-2 border-tertiary`}
      />
      <label
        className={`ml-1 block text-base ${
          labelshowaslink
            ? "text-primary border-b"
            : textborder == "selected"
            ? "text-primary border border-primary p-1 rounded-md"
            : textborder == "unselected"
            ? "text-tertiary border border-tertiary p-1 rounded-md"
            : "text-black border-0"
        }   font-sans font-normal`}
        onClick={onLabelClick}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
