import { ReactComponent as EyeIcon } from "../../assets/ic_eye.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/ic_eye_off.svg";

const Input = ({
  onShowPassword,
  showpassword,
  hasborder = true,
  showeye = false,
  rounded = "2xl",
  placeholder = "",
  textsize = "base",
  label,
  ...otherProps
}) => {
  return (
    <div
      className={`${
        hasborder === true
          ? `border border-primary rounded-${rounded} mt-4 py-2 px-3`
          : ""
      } shadow-sm focus-within:ring-0 focus-within:ring-primary focus-within:border-primary`}
    >
      <label
        htmlFor="name"
        className="block text-xs font-medium text-primary font-sans"
      >
        {label}
      </label>
      <div className={`flex flex-row`}>
        <input
          {...otherProps}
          placeholder={placeholder}
          className={`text-${textsize} placeholder-opacity-25 font-sans w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ...`}
        />
        <div hidden={!showeye} onClick={onShowPassword}>
          {showpassword ? <EyeIcon /> : <EyeOffIcon />}
        </div>
      </div>
    </div>
  );
};

export default Input;
