import React, { useState } from "react";
import Button from "components/UI/Button";
import { Formik } from "formik";
import * as yup from "yup";
import Input from "components/UI/Input";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { SCERETPASS } from "../../../utils/constants";
import { SUPPORT } from "navigation/CONSTANTS";
import { Link } from "react-router-dom";

const setPasswordValidationSchema = yup.object({
  password: yup.string().required("This field is required"),
  confirmPassword: yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf(
        [yup.ref("password")],
        "Password and Confirmed Password should match."
      ),
  }),
});

export default function ArtistRegistrationView({ onSubmit, invitation }) {
  const [showTerms, setShowTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  let buttonLabel = "Register";
  // invitation = false;

  const handleFormSubmit = (values) => {
    var encrypted = CryptoJS.AES.encrypt(
      values.password,
      SCERETPASS
    ).toString();
    let obj = {
      password: encrypted,
    };
    onSubmit(obj);
  };

  return (
    <div className="flex flex-1 flex-col min-h-screen items-center p-4">
      <div className="flex flex-1 flex-col h-full max-w-sm items-center justify-between">
        <div className="mt-4">
          <object
            type="image/svg+xml"
            data="/2ndSkin.svg"
            className="h-20 w-auto content-center mx-auto object-cover pointer-events-none "
          >
            2ndSkin
          </object>
        </div>

        <div>
          <div className="mt-4 text-2xl font-bold text-primary font-sans text-center">
            {invitation
              ? showTerms
                ? "Terms"
                : "Register Account"
              : "Invitation Expired"}
          </div>
          <div className="mt-4 text-sm text-primary font-sans text-center">
            {invitation
              ? showTerms
                ? ""
                : "Your email address has been successfully verified."
              : "Contact"}

            {!invitation && (
              <Link
                to={SUPPORT}
                className="text-primary hover:text-primary text-sm font-sans font-normal text-center pl-1"
              >
                Support
              </Link>
            )}
          </div>
          {showTerms && invitation && (
            <div className="mb-8 overflow-y-scroll mx-auto">
              <div className="flex mb-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum. Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it
                to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s
                with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software
                like Aldus PageMaker including versions of Lorem Ipsum. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </div>
              <Button
                label="Back"
                textsize="base"
                onClick={() => setShowTerms(false)}
                background="white"
                bordercolor="primary"
                color="primary"
                width="full"
              />
            </div>
          )}
          {!showTerms && invitation && (
            <div className="mt-8">
              <object
                type="image/svg+xml"
                data="/ic_login_user.svg"
                className="h-10 w-8 content-center mx-auto object-cover pointer-events-none"
              >
                2ndSkin
              </object>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={setPasswordValidationSchema}
                onSubmit={(values) => {
                  handleFormSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className="overflow-hidden">
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        label={t("login.password")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        showeye={+true}
                        onShowPassword={() => setShowPassword(!showPassword)}
                        showpassword={+showPassword}
                      />
                      <p className="text-red font-sans text-sm ml-4">{errors.password}</p>
                    </div>
                    <div className="overflow-hidden">
                      <Input
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        label="Confirm Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        showeye={+true}
                        onShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                        showpassword={+showConfirmPassword}
                      />
                      <p className="text-red font-sans text-sm ml-4">
                        {errors.confirmPassword}
                      </p>
                    </div>
                    <div className="flex items-center mt-6">
                      <Button
                        type="submit"
                        width="full"
                        textsize="base"
                        label={buttonLabel}
                      />
                    </div>
                    <div className="sm:col-span-1 flex flex-row sm:px-4 mb-10 md:mb-0 items-center mt-3">
                      <Button
                        onClick={() => setShowTerms(true)}
                        label="Terms of Service"
                        textsize="base"
                        border="none"
                        background="white"
                        color="primary"
                        shadow="shadow-none"
                        hoverbg="white"
                        hovertext="primary"
                        font="normal"
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>

        <p className="text-tertiary text-sm font-sans font-normal text-center mb-4">
          &copy; 2024 Ink Foundation, All rights reserved.
        </p>
      </div>
    </div>
  );
}
