/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Modal } from "antd";

export function RouterPrompt(props) {
  const { when, okText, cancelText } = props;

  const history = useHistory();
  const unblockRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    unblockRef.current = history.block((prompt) => {
      if (when) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  function handleOK() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  function handleCancel() {
    setShowPrompt(false);
  }

  return showPrompt ? (
    <Modal
      title={"warning!!!"}
      open={showPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      closable={true}
      cancelButtonProps={{
        style: { borderColor: "#C7A33F", colorAdjust: "#000000" },
      }}
      okButtonProps={{
        style: { backgroundColor: "#C7A33F" },
        color: "#ffffff",
      }}
    >
      There are unsaved changes. Are you sure want to leave this page ?
    </Modal>
  ) : null;
}
