/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ManagerAddEditView from "./ManagerAddEditView";
import { useDispatch, useSelector } from "react-redux";
import { getStudiosByStudioType } from "../../redux/actions/StudioAction";
import { tabTypes, studioTypes } from "../../layouts/AdminConstants";
import {
  addManager,
  deleteManager,
  editManager,
  getManager,
} from "redux/actions/ManagerAction";
import { useHistory } from "react-router-dom";

const ManagerAddEditContainer = (props) => {
  const selectedManagerTab = props.match.params.id;
  const selectedManagerId = props.match.params.uid;

  const studiosObj = useSelector((state) => state.studio.getStudioByType);
  const error = useSelector((state) => state.user.error);
  const manager = useSelector((state) => state.manager.getManager);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [firstTime, setFirstTime] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (firstTime) {
      if (selectedManagerTab === tabTypes["My Managers"]) {
        dispatch(getStudiosByStudioType(studioTypes["My Studio"]));
      } else if (selectedManagerTab === tabTypes["Partner Studios Managers"]) {
        dispatch(getStudiosByStudioType(studioTypes["Partner Studio"]));
      }
      if (selectedManagerId) {
        dispatch(getManager(selectedManagerId));
      }
      setFirstTime(false);
    }
  }, []);

  const onSubmit = (obj) => {
    if (selectedManagerId) {
      obj.id = selectedManagerId;
      dispatch(editManager(obj)).then(() => history.replace('/admin/manager'));
    } else {
      dispatch(addManager(obj)).then((res) => {
        if (res.type == "MANAGER_ERROR") {
          setShowAlert(true);
          setAlertMessage(res.payload.message.error.message);
        } else {           
          history.replace('/admin/manager');
        }
      });
    }
  };

  const onDelete = (uid) => {
    dispatch(deleteManager(uid)).then(() => history.replace('/admin/manager'));
  };

  return (
    <div>
      <ManagerAddEditView
        onSubmit={onSubmit}
        onDelete={onDelete}
        error={error}
        selectedManagerTab={selectedManagerTab}
        studiosObj={studiosObj}
        userDataObj={manager}
        selectedManagerId={selectedManagerId}
        title="Manager Add Edit View"
        loginData={props.loginData}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
      />
    </div>
  );
};

export default ManagerAddEditContainer;
