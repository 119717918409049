/* eslint-disable array-callback-return */
import React, { useRef } from "react";
import { ReactComponent as AddButton } from "../../../assets/ic_button_add.svg";
import { ReactComponent as RemoveButton } from "../../../assets/ic_button_remove.svg";

const TimeList = (props) => {
  var i, j;
  var optionItems = [];

  optionItems.push(
    <option value="" label="" key="" className="font-sans text-xs">
      Select
    </option>
  );

  const fromTimeCtrl = useRef("");
  const toTimeCtrl = useRef("");

  for (i = 0; i < 24; i++) {
    for (j = 0; j < 2; j++) {
      var v = i + ":" + (j === 0 ? "00" : 30 * j);
      optionItems.push(
        <option key={v} label={v} value={v}>
          {v}
        </option>
      );
    }
  }

  //Admin Mode
  if (props.adminSelectedDate) {
    return props.adminSelectedDate.timeEntries.map((timeEntry, index) => {
      if (!timeEntry.isDeleted) {
        let fromTime = `${props.adminSelectedDate.day}-fromTime-${index}`;
        let toTime = `${props.adminSelectedDate.day}-toTime-${index}`;
        let fval = timeEntry.fromTime
          ? timeEntry.fromTime.substring(0, 5).replace(/^0/, "")
          : "";
        let tval = timeEntry.toTime
          ? timeEntry.toTime.substring(0, 5).replace(/^0/, "")
          : "";
        return (
          <div
            className="flex grid md:grid-cols-8 md:gap-4 items-center"
            key={index}
          >
            <div className="col-span-3 grid grid-rows-2">
              <label className="flex font-sans text-sm text-tertiary my-2 items-end">
                From
              </label>
              <select
                className="form-select border border-tertiary rounded-xl py-3 w-full"
                name="fromTime"
                id={fromTime}
                data-id={index}
                data-day={props.day}
                defaultValue={fval}
                // onChange={props.validateTimeRange}
                onChange={(e) =>
                  props.updateFromTime(
                    props.adminSelectedDate,
                    timeEntry.id,
                    e.target.value
                  )
                }
              >
                {optionItems}
              </select>
            </div>
            <div className="col-span-3 grid grid-rows-2">
              <label className="flex font-sans text-sm text-tertiary my-2 items-end">
                To
              </label>
              <select
                className="form-select border border-tertiary rounded-xl py-3 w-full"
                name="toTime"
                id={toTime}
                data-id={index}
                data-day={props.day}
                defaultValue={tval}
                onChange={(e) =>
                  props.updateToTime(
                    props.adminSelectedDate,
                    timeEntry.id,
                    e.target.value
                  )
                }
              >
                {optionItems}
              </select>
            </div>
            <div className="col-span-1 md:grid grid-rows-2 my-4 md:my-0">
              <div className="flex"></div>
              <div className="flex md:justify-center items-center">
                {index === 0 ? (
                  <button
                    onClick={() => props.add()}
                    type="button"
                    className="flex btn btn-primary text-center"
                  >
                    <AddButton className="h-12 w-12" hidden={props.hide} />
                  </button>
                ) : (
                  <button
                    className="flex btn btn-danger text-center"
                    onClick={() =>
                      props.delete(props.adminSelectedDate, timeEntry.id)
                    }
                  >
                    <RemoveButton className="h-12 w-12" />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      }
    });
  } else {
    //Client Mode
    return Object.keys(props.timeDetails).map((val1, idx1) => {
      if (props.day === val1) {
        return props.timeDetails[val1].map((val, idx) => {
          let fromTime = `${props.day}-fromTime-${idx}`;
          let toTime = `${props.day}-toTime-${idx}`;
          let fval = val.fromTime
            ? val.fromTime.substring(0, 5).replace(/^0/, "")
            : "";
          let tval = val.toTime
            ? val.toTime.substring(0, 5).replace(/^0/, "")
            : "";
          return (
            <div
              className="flex grid md:grid-cols-8 md:gap-4 items-center"
              key={val.index}
            >
              <div className="col-span-3 grid grid-rows-2">
                <label className="flex font-sans text-sm text-tertiary my-2 items-end">
                  From
                </label>
                <select
                  ref={(ref) => (fromTimeCtrl.current = ref)}
                  className="form-select border border-tertiary rounded-xl py-3 w-full"
                  name="fromTime"
                  id={fromTime}
                  data-id={idx}
                  data-day={props.day}
                  defaultValue={fval}
                  onChange={(e) =>
                    props.validateTimeRange(
                      fromTimeCtrl.current.value,
                      toTimeCtrl.current.value,
                      val1,
                      e.target.value
                    )
                  }
                >
                  {optionItems}
                </select>
              </div>
              <div className="col-span-3 grid grid-rows-2">
                <label className="flex font-sans text-sm text-tertiary my-2 items-end">
                  To
                </label>
                <select
                  ref={(ref) => (toTimeCtrl.current = ref)}
                  className="form-select border border-tertiary rounded-xl py-3 w-full"
                  name="toTime"
                  id={toTime}
                  data-id={idx}
                  data-day={props.day}
                  defaultValue={tval}
                  onChange={(e) =>
                    props.validateTimeRange(
                      fromTimeCtrl.current.value,
                      toTimeCtrl.current.value,
                      val1,
                      e.target.value
                    )
                  }
                >
                  {optionItems}
                </select>
              </div>
              <div className="col-span-1 md:grid grid-rows-2 my-4 md:my-0">
                <div className="flex"></div>
                <div className="flex md:justify-center items-center">
                  {idx === 0 ? (
                    <button
                      onClick={() => props.add()}
                      type="button"
                      className="flex btn btn-primary text-center"
                    >
                      <AddButton className="h-12 w-12" hidden={props.hide} />
                    </button>
                  ) : (
                    <button
                      className="flex btn btn-danger text-center"
                      onClick={() => props.delete(val, val1)}
                    >
                      <RemoveButton className="h-12 w-12" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        });
      }
    });
  }
};
export default TimeList;
