/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { forwardRef } from "react";
import { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "components/UI/Button";
import { getDaysByInt } from "utils/constants";
import TimeList from "./../../common/timeCreate";
import DatePicker from "react-datepicker";
import { ReactComponent as Calendar } from "../../../../assets/ic_calendar.svg";
import "../styles/datePicker.css";
import { Alert } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CheckBox from "components/UI/CheckBox";

const AdminNextAppointmentDate = (props) => {
  const [adminDateOffer, setAdminDateOffer] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  // const [artistWorkdays, setArtistWorkdays] = useState([]);

  useEffect(() => {
    // let tmpArtistWorkdays = [];
    // props.artistData.map((item) => {
    //   tmpArtistWorkdays.push({
    //     studioId: item.studioId,
    //     day: item.artist_studio_work_days[0].day,
    //   });
    // });
    // setArtistWorkdays(tmpArtistWorkdays);
  }, [props.artistData]);


  useEffect(() => {
    let filterDataOfDates = props.dates.filter((rec) => {
      return rec.statusId == 1;
    });
    let dateParts = filterDataOfDates[0]?.offerDate.split("-");
    let minDateObject = new Date(
      +dateParts[0],
      dateParts[1] - 1,
      +dateParts[2]
    );
    let lastAppDate = Date.parse(minDateObject);
    minDateObject.setDate(minDateObject.getDate());
   

    let currentDateObject = new Date();
    let todaysDate = Date.parse(currentDateObject);
    currentDateObject.setDate(currentDateObject.getDate());    
    var tempDate;
     
    if (todaysDate >= lastAppDate) {      
      setMinDate(currentDateObject);
      tempDate = new Date(currentDateObject);
    } else {     
      setMinDate(minDateObject);
      tempDate = new Date(minDateObject);
    }    

    let adminPreferedDateTime = [];

    let inDate = tempDate;
    for (var i = 0; i < 7; i++) {
      let k = inDate.setDate(inDate.getDate() + 1);   
      let aaa = new Date(k);
      let dayNum = (Number(aaa.getDay())); 
      dayNum = dayNum === 0 ? 7 : dayNum;
            
      adminPreferedDateTime.push({
        day: dayNum,
        timeEntries: [
          {
            fromTime: null,
            toTime: null,
            id: i,
            isDeleted: false,
          },
        ],
        name: getDaysByInt(dayNum),
        date: k, 
        selected: false,
        counterOfferId: null,
      });
    }

    // let artistDates = [];
    // let fectchArtistWorkDays = props.artistData.filter(
    //   (item) =>
    //     item.userId === props.artistId && item.studioId === props.studioId
    // );
    // if (fectchArtistWorkDays.length > 0) {
    //   artistDates = fectchArtistWorkDays[0].artist_studio_work_days;
    // }

    // artistDates = artistDates.sort((a, b) => (a.day > b.day ? 1 : -1));
    // artistDates.map((artistDay, index) => {
    //   var tempDate = new Date(minDateObject);
    //   adminPreferedDateTime.push({
    //     day: artistDay.day,
    //     timeEntries: [
    //       {
    //         fromTime: null,
    //         toTime: null,
    //         id: index,
    //         isDeleted: false,
    //       },
    //     ],
    //     name: getDaysByInt(artistDay.day),
    //     date:
    //       tempDate.getDay() - artistDay.day === 0
    //         ? tempDate.setDate(tempDate.getDate() + 7)
    //         : tempDate.setDate(
    //             tempDate.getDate() + (artistDay.day - tempDate.getDay())
    //           ),
    //     selected: false,
    //     counterOfferId: null,
    //   });
    // });
    setAdminDateOffer(adminPreferedDateTime);
  }, [props]);

  const handleFormSubmit = (values) => {
    let dates = [];
    let errormsg = "";
    setErrorMsg(null);
    adminDateOffer.map((adminDateOfferEntry) => {
      if (adminDateOfferEntry.selected === true) {
        adminDateOfferEntry.timeEntries.map((timeEntry) => {
          if (!timeEntry.toTime) {
            errormsg = "Please select to time";
          }
          if (!timeEntry.fromTime) {
            errormsg = "Please select from time";
          }

          // Check if fromHour is greater than toHour.
          if (timeEntry.fromTime && timeEntry.toTime) {
            let arrFromTime = timeEntry.fromTime.split(":");
            let arrToTime = timeEntry.toTime.split(":");

            if (arrFromTime.length > 0 && arrToTime.length > 0) {
              if (
                (arrFromTime[0] == arrToTime[0] &&
                  arrFromTime[1] == arrToTime[1]) ||
                (arrFromTime[0] == arrToTime[0] &&
                  Number(arrFromTime[1]) > Number(arrToTime[1])) ||
                Number(arrFromTime[0]) > Number(arrToTime[0])
              ) {
                errormsg = "Selected time range is incorrect";
              }
            }
          }

          let dayObj = {
            day: adminDateOfferEntry.day,
            fromTime: timeEntry.fromTime,
            toTime: timeEntry.toTime,
            offerDate: moment(adminDateOfferEntry.date).format("YYYY-MM-DD"),
            id: timeEntry.id,
            isDeleted: timeEntry.isDeleted ?? false,
          };
          dates.push(dayObj);
        });
      }
    });

    if (errormsg) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {errormsg}
        </Alert>
      );
      return;
    }

    if (dates && dates.length <= 0) {
      setErrorMsg(
        <Alert className="m-4 " severity="error">
          {"Please select atleast one day."}
        </Alert>
      );
      return;
    }
    if (dates && dates.length > 0) {      
      let pickedDate = Date.parse(dates[0].offerDate + ' ' + dates[0].fromTime);
      let todaysDate = Date.parse(new Date());
      if (pickedDate <= todaysDate) {
        setErrorMsg(<Alert className="m-4 " severity="error">
                        {"Selected Date has already passed. Please select some other date."}
                      </Alert>);
        return;
      }
    }




    let obj = {
      nextAppointmentDateEdit: {
        appointmentDate: dates,
        counterOfferDtId: props.dates[0].counterOfferId,
        updatedBy: props.loggedInUserId,
      },
    };

    props.onSubmit(obj);
    props.setOpen(false);
  };

  const formHandleChange = (e) => {};

  const updateFromTime = (selectedDay, timeEntryId, value) => {
    adminDateOffer.map((item1) => {
      if (item1.date === selectedDay.date) {
        item1.timeEntries.map((r) => {
          if (r.id === timeEntryId) {
            r.fromTime = value;
          }
        });
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const updateToTime = (selectedDay, timeEntryId, value) => {
    adminDateOffer.map((item1) => {
      if (item1.date === selectedDay.date) {
        item1.timeEntries.map((r) => {
          if (r.id === timeEntryId) {
            r.toTime = value;
          }
        });
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const selectDate = (e, item) => {
    adminDateOffer.map((item1) => {
      if (item1.date === item.date && item1.day === item.day) {
        item1.selected = true;
      } else {
        item1.selected = false;
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const handleDatePickerNew = (value, item) => {
    adminDateOffer.map((item1) => {
      if (item1.date === item.date) {
        item1.date = value;
      }
    });
    setAdminDateOffer((prev) => [...adminDateOffer]);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="w-full py-2 border border-primary rounded-md flex flex-row px-4"
      onClick={onClick}
      ref={ref}
    >
      <button type="button" className="text-base mr-4">
        {value}
      </button>
      <div className="flex flex-1" />
      <Calendar />
    </div>
  ));

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} onChange={formHandleChange}>
            <div className="px-0">
              <div className="mt-7">
                <div className="flex flex-row gap-y-6 gap-x-10 flex-wrap w-full">
                  {adminDateOffer.map((item, index) => (
                    <div key={index} className="w-full">
                      <div className="my-auto mb-3">
                        <CheckBox
                          type="checkbox"
                          label={item.name}
                          onChange={(e) => selectDate(e, item)}
                          width={"5"}
                          checked={item.selected ? true : false}
                          value={item.day}
                        />
                      </div>
                      <div className="flex flex-col text-xl">
                        <div className="text-gray">
                          <DatePicker
                            onChange={(e) => handleDatePickerNew(e, item)}
                            selected={item.date}
                            minDate={minDate}
                            calendarIcon={<Calendar />}
                            className={`w-full py-2 border border-primary rounded-md`}
                            dateFormat="dd.M.yyyy"
                            clearIcon={null}
                            customInput={<CustomInput />}                          
                            filterDate={(date) => {
                              const day = date.getDay();
                              return day == item.day;
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-primary my-auto ml-0 text-xl">
                        {item.selected || props.calledFromModel ? (
                          <TimeList
                            // add={(e) => {
                            //   addNewRow(item);
                            // }}
                            // delete={clickOnDelete.bind(this)}
                            hide={true}
                            adminSelectedDate={item}
                            updateFromTime={updateFromTime.bind(this)}
                            updateToTime={updateToTime.bind(this)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-8">
                <div className="flex flex-row gap-0 mt-4 lg:mt-0">
                  <div className="sm:col-span-1 sm:flex-col sm:justify-start pr-4 lg:pr-0 lg:mb-5 md:mt-5 md:mt-8 flex flex-1 ">
                    <Button type="submit" label={"Submit"} textsize="base" />
                  </div>
                  <div className="lg:flex lg:flex-1" />
                </div>
              </div>
            </div>
            {errorMsg}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdminNextAppointmentDate;
