import React, { useState, useEffect } from "react";
import TopNavBar from "components/Navbars/TopNavBar";
import { userNavigations } from "layouts/ClientConstants";
import Input from "components/UI/Input";

const PaymentView = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [actualPrice, setActualPrice] = useState(0.0);
  const advancePercentage = 20;

  const handleMenuOpen = (isOpen) => {
    setMobileMenuOpen(isOpen);
  };

  useEffect(() => {
    if (props?.projectDataObj?.addDiscount) {
      let offp = props?.projectDataObj?.offerPrice * 0.2;
      let dis = props?.projectDataObj?.addDiscount;
      let actp = offp - (dis / 100) * offp;
      setActualPrice(Math.round(actp));
    } else {
      if (props?.projectDataObj?.offerPrice) {
        setActualPrice(Math.round(props?.projectDataObj?.offerPrice * 0.2));
      }
    }
  }, [props?.projectDataObj]);

  const onPay = () => {
    props.onInitialize(actualPrice);
  };

  const getAmountAfterDiscount = () => {
    let discount = (props?.projectDataObj?.addDiscount) ? props?.projectDataObj?.addDiscount : 0;    
    let offerPrice = (props?.projectDataObj?.offerPrice) ? props?.projectDataObj?.offerPrice : 0;  
    return Math.round(
      offerPrice -
        (discount / 100) *
        offerPrice
    );
  };

  return (
    <div className="h-full flex">
      <div className="flex-1 flex flex-col overflow-hidden h-full bg-white">
        <TopNavBar
          userNavigations={userNavigations}
          mobileMenuOpen={mobileMenuOpen}
          handleMenu={handleMenuOpen}
          loginData={props?.loginData}
        />
        <div className="flex-1 flex flex-col md:flex-row overflow-hidden h-full lg:w-1/2">
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 px-4 md:px-12">
                <div className="container my-5 lg:my-10">
                  <div className="md:w-1/2">
                    <div className="mt-5">
                      <div className="mt-10">
                        <div className="text-primary text-xl flex">{`We only take ${advancePercentage}% as advance`}</div>
                        <div className="text-primary text-xl flex mb-5">{`Remaining you can pay at the studio.`}</div>
                        <div className="overflow-hidden">
                          <Input
                            type="text"
                            name="amount"
                            id="amount"
                            label="Original Amount"
                            value={props?.projectDataObj?.offerPrice || ""}
                            readOnly={true}
                          />
                        </div>                        
                        {props?.projectDataObj?.addDiscount && (
                          <>
                            <div className="overflow-hidden">
                              <Input
                                type="text"
                                name="discount"
                                id="discount"
                                label="Discount"
                                readOnly={true}
                                value={
                                  props?.projectDataObj?.addDiscount + " %" || ""
                                }
                              />
                            </div>
                            <div className="overflow-hidden">
                              <Input
                                type="text"
                                name="discount"
                                id="discount"
                                label="Amount After Discount"
                                readOnly={true}
                                value={getAmountAfterDiscount() || ""}
                              />
                            </div>
                          </>
                        )}

                        <div className="overflow-hidden">
                          <Input
                            type="text"
                            name="actualAmount"
                            id="actualAmount"
                            label="Prepayment Amount (Pay Now)"
                            readOnly={true}
                            value={actualPrice || ""}
                          />
                        </div>
                        <div className="overflow-hidden">
                          <Input
                            type="text"
                            name="actualAmount"
                            id="actualAmount"
                            label="Balance Amount (Pay at the Studio)"
                            readOnly={true}
                            value={getAmountAfterDiscount() - actualPrice}
                          />
                        </div>
                        <div className="flex items-center mt-10">
                          <button
                            label="Pay"
                            className="p-3 bg-primary w-1/2 text-white font-sans font-semibold mt-2 rounded-3xl"
                            textsize="base"
                            onClick={() => onPay()}
                          >
                            {props.loading ? " Processing..." : "Pay"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        {props.loading && (
          <div className="flex flex-col justify-center items-center">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            ></div>
            <div className="visually-hidden">Processing...</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentView;
