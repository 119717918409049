/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPwd } from "../../../redux/actions/UserActions";
import ResetPasswordView from "./ResetPasswordView";

const ResetPasswordContainer = (props) => {
  const error = useSelector((state) => state.user.error);
  const data = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    "An email is sent to your email address"
  );

  const onSubmit = (obj) => {
    dispatch(resetPwd(obj)).then(() => {
      setShowAlert(true);
    });
  };

  const onAlertClose = () => {
    history.goBack();
  };

  return (
    <div>
      <ResetPasswordView
        onSubmit={onSubmit}
        error={error}
        data={data}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        alertMessage={alertMessage}
        onAlertClose={onAlertClose}
      />
    </div>
  );
};

export default ResetPasswordContainer;
