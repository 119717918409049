/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import Input from "components/UI/Input";
import CheckBox from "components/UI/CheckBox";
import Button from "components/UI/Button";
import {
  USER_TYPE_MANAGER,
  tabTypes,
  USER_TYPE_HICHEM,
} from "layouts/AdminConstants";
import PreviewImage from "components/PreviewImage";
import { ReactComponent as DefaultPic } from "assets/ico_default_pic.svg";
import { ReactComponent as DropdownArrow } from "assets/ico_dropdown_arrow.svg";
import { HIDE_DISABLE_FLAG, IMAGE_SIZE, SERVER_URL } from "utils/constants";
import AlertMessage from "./AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "redux/actions/CountryAction";
import Country from "./UI/Country";
import Swal from "sweetalert2";

const managerValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required")
    .test("alphabets", "First Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required")
    .test("alphabets", "Last Name must only contain alphabets", (value) => {
      return /^[A-Za-zÀ-ž]+$/.test(value);
    }),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/(\d){9}\b/, "Enter a valid mobile number")
    .max(10, "Enter a valid mobile number")
    .required(`Phone Number is required`),
  profilePic: yup
    .mixed()
    .test(
      "fileSize",
      "File Size is too large",
      (value) =>
        value === undefined ||
        value === null ||
        value === "" ||
        typeof value === "string" ||
        (value && value.size <= IMAGE_SIZE)
    ),
});

const ManagerAddEdit = (props) => {
  const countries = useSelector((state) => state.country.data);
  let sShow = true;
  if (props.loginData.userRoleId === 6) {
    sShow = false;
  }
  let showAddEdit = false;
  if (props.loginData.userRoleId === 1 || props.loginData.userRoleId === 2) {
    showAddEdit = true;
  }

  const [submit, setSubmit] = useState(false);
  const [picEdited, setPicEdited] = useState(false);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (submit && props.error == null) {
      goToPrevPage();
    }
  }, [props.userDataObj]);

  useEffect(() => {
    if (!firstTime) {
      dispatch(getCountries());
      setFirstTime(true);
    }
  }, []);

  useEffect(() => {
    populatePhoneCode();
  }, [countries]);

  const goToPrevPage = () => {
    if (sShow) {
      history.goBack();
    } else {
      props.handleCancelAddManager();
    }
  };

  const handleFormSubmit = (values) => {
    const obj = new FormData();
    obj.append("firstName", values.firstName);
    obj.append("lastName", values.lastName);
    obj.append("email", values.email.toLowerCase().replace(/\s/g, ""));
    obj.append("phone", values.phone);
    obj.append("phoneCode", values.phoneCode);
    if (props.selectedManagerTab === tabTypes["My Managers"]) {
      obj.append("userTypeId", USER_TYPE_HICHEM);
      obj.append("studioType", "My Studio");
    } else {
      obj.append("userTypeId", USER_TYPE_MANAGER);
      obj.append("studioType", "Partner Studio");
    }
    obj.append("isAdmin", values.isAdmin);

    if (sShow) {
      obj.append("studioId", values.studioId);
      obj.append("isMarketPlaceDecision", values.isMarketPlaceDecision);
      obj.append("isSupport", values.isSupport);
      obj.append("profilePic", values.profilePic);
    } else {
      obj.append("studioId", props.loginData.studioId);
    }

    setSubmit(true);
    props.onSubmit(obj);
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the manager?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        props.onDelete(props.selectedManagerId);
      }
    });
  };

  let studios = props.studiosObj;
  let optionItems;
  if (studios && studios.length > 0) {
    optionItems = studios.map((studio) => (
      <option
        className="text-primary shadow-none border-0 focus:border-0"
        key={studio.id}
        value={studio.id}
      >
        {studio.placeName}
      </option>
    ));
  }

  useEffect(() => {
    if (props?.userDataObj?.photoUrl) {
      setPicEdited(true);
    }
  }, [props.userDataObj]);

  let userData = props.userDataObj;
  let intialFormValues;
  if (userData) {
    let isAdminVal = userData.userRoleId === 1 ? true : false;
    if (props.selectedManagerTab === tabTypes["Partner Studios Managers"]) {
      isAdminVal = userData.userRoleId === 6 ? true : false;
    }
    intialFormValues = {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      phoneCode:
        userData.phoneCode && userData.phoneCode !== "null"
          ? userData.phoneCode
          : "+41",
      isMarketPlaceDecision: userData.isMarketPlaceDecision,
      isSupport: userData.isSupport,
      isAdmin: isAdminVal,
      studioId: userData.studioId,
      profilePic: userData.photoUrl ? SERVER_URL + userData.photoUrl : null,
    };
  } else {
    intialFormValues = {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      isMarketPlaceDecision: false,
      isSupport: false,
      isAdmin: false,
      studioId: "",
      profilePic: "",
      phoneCode: "+41",
    };
  }

  let addButtonLabel;
  let deleteLink;
  if (props.selectedManagerId) {
    addButtonLabel = "Save";
    deleteLink = (
      <div className="md:col-start-1 md:col-span-12 lg:col-start-1 lg:col-span-8 xl:col-start-1 xl:col-span-8 3xl:col-start-1 3xl:col-span-6 items-start mt-4">
        <Button
          onClick={() => handleDelete()}
          label="Delete - Suspend"
          textsize="base"
          type="button"
          // bordercolor={"tertiary"}
          background="white"
          color="tertiary"
          shadow="shadow-none"
          hoverbg="white"
          hovertext="red"
          font="normal"
        />
      </div>
    );
  } else {
    addButtonLabel = "Add Manager";
  }

  const history = useHistory();

  const populatePhoneCode = () => {
    let phoneCodeOptionItems = [];
    if (countries && countries.length > 0) {
      phoneCodeOptionItems = countries.map((country) => (
        <option key={country.phoneCode} value={country.phoneCode}>
          {country.phoneCode}
        </option>
      ));
    }
    setPhoneCodeOptions(phoneCodeOptionItems);
  };

  return (
    <section
      className="relative bg-white"
      aria-labelledby="manager-add-heading"
    >
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative bg-white">
          {/*  form */}
          <div className="">
            <Formik
              enableReinitialize={true}
              initialValues={intialFormValues}
              validationSchema={managerValidationSchema}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="md:grid md:grid-cols-12 md:gap-y-0 sm:gap-x-4"
                  encType="multipart/form-data"
                >
                  {sShow && (
                    <div className="md:col-start-1 md:col-span-12">
                      <div>
                        {values.profilePic && (
                          <div className="flex flex-row items-center">
                            {picEdited && (
                              <img
                                src={values.profilePic}
                                className="h-24 w-24 rounded-full border border-tertiary object-cover object-center"
                                alt="Manager"
                              />
                            )}
                            {!picEdited && (
                              <PreviewImage
                                file={values.profilePic}
                                rounded={"full"}
                                height={"24"}
                                width={"24"}
                              />
                            )}
                            <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white py-1.5 px-2.5 rounded-xl">
                              <input
                                id="profilePic"
                                type="file"
                                name=""
                                onChange={(event) => {
                                  setPicEdited(false);
                                  setFieldValue(
                                    "profilePic",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                hidden
                              />
                              Change Picture
                            </label>
                            <p className="text-red">
                              {errors.profilePic &&
                                touched.profilePic &&
                                errors.profilePic}
                            </p>
                          </div>
                        )}
                        {!values.profilePic && (
                          <div className="flex flex-row items-center">
                            <div className="w-24 h-24 bg-primary rounded-full flex justify-center items-center">
                              <DefaultPic className="h-8 w-8" />
                            </div>
                            <label className="m-4 h-8 bg-primary text-sm font-normal font-sans text-white py-1.5 px-2.5 rounded-2xl">
                              <input
                                id="profilePic"
                                type="file"
                                name=""
                                accept="image/png, image/jpeg"
                                onChange={(event) => {
                                  setFieldValue(
                                    "profilePic",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                hidden
                              />
                              Upload Picture
                            </label>
                            <p className="text-red">
                              {errors.profilePic &&
                                touched.profilePic &&
                                errors.profilePic}
                            </p>
                          </div>
                        )}
                      </div>
                      {props.selectedManagerTab !== tabTypes["My Managers"] ? (
                        <div className="mt-8">
                          <label className="text-gray px-1 font-sans text-base font-normal">
                            {" "}
                            Studio
                          </label>
                          <div className="mb-0 border-primary border rounded-2xl flex items-center px-3 py-3 mt-2">
                            <select
                              className="font-sans text-base font-normal w-full appearance-none shadow-none border-0 focus:outline-none"
                              name="studioId"
                              id="studioId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.studioId}
                            >
                              <option
                                className="shadow-none border-0"
                                value=""
                                label=""
                                key=""
                              >
                                Select Studio
                              </option>
                              {optionItems}
                            </select>
                            <DropdownArrow />
                          </div>
                          <p className="text-red px-3">
                            {errors.studioId &&
                              touched.studioId &&
                              errors.studioId}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  )}
                  <div className={`${!sShow ? "hidden" : ""}`} />
                  <div className="mt-4 md:col-start-1 md:col-span-6 xl:col-start-1 xl:col-span-4 3xl:col-start-1 3xl:col-span-3">
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      label="First Name"
                      autoComplete="username"
                    />
                    <p className="text-red">
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </p>
                  </div>
                  <div className="mt-4 md:col-start-7 md:col-span-6 xl:col-start-5 xl:col-span-4 3xl:col-start-4 3xl:col-span-3">
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      label="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      autoComplete="username"
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />

                    <p className="text-red">
                      {errors.lastName && touched.lastName && errors.lastName}
                    </p>
                  </div>
                  <div className="mt-4 md:col-start-1 md:col-span-6 xl:col-start-1 xl:col-span-4 3xl:col-start-1 3xl:col-span-3">
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      label="Email"
                      autoComplete="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="border w-full border-transparent focus:outline-none focus:ring-0 focus:ring-transparent focus:border-transparent ..."
                    />
                    <p className="text-red">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>
                  <div className="mt-4 mb-4 md:col-start-7 md:col-span-6 xl:col-start-5 xl:col-span-4 3xl:col-start-4 3xl:col-span-3">
                    <Country
                      onChange={handleChange}
                      onBlur={handleBlur}
                      phonecode={values.phoneCode}
                      phonecodeoptions={phoneCodeOptions}
                      phone={values.phone}
                    />
                    <p className="text-red font-sans">
                      {errors.phone && touched.phone && errors.phone}
                    </p>
                  </div>
                  <div className="mt-4 md:col-start-1 md:col-span-6 xl:col-start-1 xl:col-span-3">
                    <CheckBox
                      id="isAdmin"
                      name="isAdmin"
                      type="checkbox"
                      label={sShow ? "Studio Admin" : "Admin"}
                      onChange={(e) => {
                        handleChange(e);
                        if (
                          props.selectedManagerTab ===
                            tabTypes["My Managers"] &&
                          e.target.checked
                        ) {
                          setFieldValue(
                            "isMarketPlaceDecision",
                            e.target.checked
                          );
                          setFieldValue("isSupport", e.target.checked);
                        }
                      }}
                      checked={values.isAdmin}
                      width={"5"}
                    />
                  </div>
                  {props.selectedManagerTab === tabTypes["My Managers"] ? (
                    <div className="mt-4 md:col-start-7 md:col-span-6 xl:col-start-5 xl:col-span-4 3xl:col-start-4 3xl:col-span-3">
                      <CheckBox
                        id="isSupport"
                        name="isSupport"
                        type="checkbox"
                        label="Support"
                        onChange={handleChange}
                        checked={values.isSupport}
                        width={"5"}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {props.selectedManagerTab === tabTypes["My Managers"] &&
                  HIDE_DISABLE_FLAG ? (
                    <div className="mt-4 md:col-start-1 md:col-span-6 xl:col-start-1 xl:col-span-3">
                      <CheckBox
                        id="isMarketPlaceDecision"
                        name="isMarketPlaceDecision"
                        type="checkbox"
                        label="Market Place Decision"
                        onChange={handleChange}
                        checked={values.isMarketPlaceDecision}
                        width={"5"}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className=""></div> */}
                  {props.error}
                  {showAddEdit && deleteLink}
                  {props.selectedManagerId ? <div /> : <></>}
                  {HIDE_DISABLE_FLAG && <div />}
                  {showAddEdit && (
                    <div className="md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-span-4 3xl:col-start-1 3xl:col-span-3 sm:flex-col sm:justify-start sm:pr-0 mb-5 md:mb-10 mt-4 ">
                      <Button
                        type="submit"
                        label={addButtonLabel}
                        textsize="base"
                        bordercolor={"primary"}
                      />
                    </div>
                  )}
                  <div className="md:col-start-7 md:col-span-6 lg:col-start-5 lg:col-span-4 3xl:col-start-4 3xl:col-span-3 sm:flex-col sm:justify-start sm:px-0 mb-10 md:mb-0 md:mt-4">
                    <Button
                      type="button"
                      label="Cancel"
                      textsize="base"
                      onClick={goToPrevPage}
                      background={"none"}
                      color={"black"}
                      bordercolor={"primary"}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <AlertMessage
            message={props.alertMessage}
            showAlert={props.showAlert}
            setShowAlert={props.setShowAlert}
          />
        </div>
      </div>
    </section>
  );
};

export default ManagerAddEdit;
